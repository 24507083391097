import { Saga } from 'redux-saga';
import { RoutesConfig } from './router/lib/routes-config';
import { modulesRegistry } from './modules-registry';

export function getRootSagas(): Saga[] {
  let sagas: Saga[] = [];
  for (const key in modulesRegistry) {
    if (modulesRegistry[key]) {
      sagas = sagas.concat(modulesRegistry[key].listenerSagas);
    }
  }
  return sagas;
}

function _getRoutes(): RoutesConfig {
  let routes: RoutesConfig = {};
  for (const key in modulesRegistry) {
    if (modulesRegistry[key]) {
      routes = {
        ...routes,
        ...modulesRegistry[key].routes,
      };
    }
  }
  return {
    ...routes,
  };
}

function getRoutesMemo(): () => RoutesConfig {
  let routes: RoutesConfig | undefined = undefined;

  return (): RoutesConfig => {
    if (routes) {
      return routes;
    } else {
      routes = _getRoutes();
      return routes;
    }
  };
}

export const getRoutes = getRoutesMemo();
