import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonLink, TextInput } from 'sb-ui-components';
import { AuthPageWrapper } from 'src/components/auth-wrapper';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { LOGIN_ROUTE } from 'src/modules/login';
import { SMS_CHALLENGE_AUTH_RESOURCE_NAME } from 'src/modules/sms-challenge-auth/model/sms-challenge-auth';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { isPresent, minLength } from '../../../lib/validation';
import { resetEntityStateAction } from '../../../redux/entity/entity-actions';
import { CONFIRM_PASSWORD_STATE, FORGOT_PASSWORD_RESOURCE_NAME, PASSWORD_MIN_LENGTH } from '../models/forgot-password';
import { resetPasswordAction } from '../redux/forgot-password-actions';
import { ForgotPasswordSmsChallengeModal } from './forgot-password-sms-challenge-modal';
import styles from './forgot-password.module.scss';

const ForgotPasswordResetPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [isSmsChallengeModalOpen, setIsSmsChallengeModalOpen] = useState(false);

  const entityState: EntityState = useSelector((state: AppState) => state.entity);
  const confirmPasswordState: Entity<any> | undefined = entityState?.[CONFIRM_PASSWORD_STATE];
  const isConfirmPasswordSuccess = confirmPasswordState?.data;
  const isConfirmPasswordError = confirmPasswordState?.error;

  const { getValues, handleSubmit, errors, register } = useForm<{ password: string; confirm_password: string }>({
    mode: 'onSubmit',
  });

  const onSubmit = (formValues: { password: string; confirm_password: string }) => {
    // Stores the password in state and triggers the SMS challenge
    dispatch(resetPasswordAction({ password: formValues.password }));
    setIsSmsChallengeModalOpen(true);
  };

  const handleOnClose = () => {
    // Resets the state
    dispatch(resetEntityStateAction({ entityName: FORGOT_PASSWORD_RESOURCE_NAME }));
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME }));
    setIsSmsChallengeModalOpen(false);
  };

  if (isConfirmPasswordSuccess)
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="success"
          title={formatMessage({ id: 'success.passwordReset.title' })}
          message={formatMessage({ id: 'success.passwordReset.message' })}
          cta={
            <ButtonLink
              href={createPathByRouteName(`${[LOGIN_ROUTE]}`)}
              dataCy="cta__back_to_login"
              size="big"
              trailingIcon="ArrowRightRegular"
            >
              {formatMessage({ id: 'button.goToLogin' })}
            </ButtonLink>
          }
        />
      </AuthPageWrapper>
    );

  if (isConfirmPasswordError)
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.general.title' })}
          message={formatMessage({ id: 'error.general.message' })}
          cta={
            <ButtonLink
              href={createPathByRouteName('forgotPassword.flow')}
              dataCy="cta__back_to_login"
              size="big"
              trailingIcon="ArrowRightRegular"
            >
              {formatMessage({ id: 'button.tryAgain' })}
            </ButtonLink>
          }
        />
      </AuthPageWrapper>
    );

  return (
    <AuthPageWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h1>{formatMessage({ id: 'title.setNewPassword' })}</h1>
        <p>{formatMessage({ id: 'message.setPassword' })}</p>
        <TextInput
          id="password"
          type="password"
          label={formatMessage({ id: 'label.password' })}
          register={register}
          validation={{
            validate: {
              isPresent: value => isPresent(value) || formatMessage({ id: 'inputError.required' }),
              minLength: value =>
                minLength(value, PASSWORD_MIN_LENGTH) ||
                formatMessage({ id: 'inputError.minLength' }, { length: PASSWORD_MIN_LENGTH }),
            },
          }}
          errorMessage={errors.password?.message}
        />
        <TextInput
          id="confirm_password"
          type="password"
          label={formatMessage({ id: 'label.confirmPassword' })}
          register={register}
          validation={{
            validate: {
              equal: value => value === getValues('password') || formatMessage({ id: 'inputError.password.match' }),
            },
          }}
          errorMessage={errors.confirm_password?.message}
        />
        <br />
        <Button dataCy="cta__reset_password" className={styles.button} type="submit" size="big">
          {formatMessage({ id: 'button.resetPassword' })}
        </Button>
      </form>

      {/* ToDo: reused component for convenience, replace when we align on an implementation */}
      <ForgotPasswordSmsChallengeModal isOpen={isSmsChallengeModalOpen} onClose={handleOnClose} />
    </AuthPageWrapper>
  );
};

export default ForgotPasswordResetPage;
