import { useSelector } from 'react-redux';
import { ProgressBarLine } from 'sb-ui-components';
import { AppState } from '../types';

export const GlobalProgressBar = () => {
  const { isProgressLoading } = useSelector((state: AppState) => state.progressBar);
  const loginFlowState = useSelector((state: AppState) => state.entity.loginFlow);
  const smsChallengeAmplifyState = useSelector((state: AppState) => state.entity.smsChallengeAmplify);

  const isLoginFlowLoading = loginFlowState?.loading;
  const isSmsChallengeAmplifyLoading = smsChallengeAmplifyState?.loading;

  const showProgressBar = isProgressLoading || isLoginFlowLoading || isSmsChallengeAmplifyLoading;

  if (showProgressBar) return <ProgressBarLine />;

  return null;
};
