import { ComponentType, FunctionComponent, useEffect } from 'react';
import { matchPath, Redirect, Route, useLocation } from 'react-router-dom';
import AppContainer from '../../../containers/app-container';
import authentication from '../../../models/authentication';
import { RouterHookParams } from '../../../types';

interface ProtectedRouteProps {
  loggedIn: boolean;
  path: string;
  skipAuthentication: boolean;
  onEnterRoute: (params: RouterHookParams) => void;
  routeName: string;
  currentComponent: ComponentType<any>;
  exact: boolean;
  bootFinished: boolean;
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = props => {
  const { loggedIn, onEnterRoute, routeName, path, skipAuthentication, currentComponent, bootFinished } = props;

  const location = useLocation();

  // We cannot trigger onEnterRoute() in render of a route because updating the state in render is causing an error
  // "Cannot update a component from inside the function body of a different component."
  useEffect(() => {
    // We are getting params via matchPath function because useParams hook can give correct reult only if used within a route.
    // This is a recommended way of getting params outside of the route:
    // https://github.com/remix-run/react-router/issues/7026#issuecomment-552582981
    const match = matchPath(location.pathname, { exact: true, strict: true, path: path });
    const routerParams: RouterHookParams = {
      queryParams: Object.fromEntries(new URLSearchParams(location.search)),
      pathParams: match!.params,
      routeName,
    };
    if (bootFinished) {
      onEnterRoute(routerParams);
      // Always scroll to the top
      window.scrollTo(0, 0);
    }
  }, [bootFinished, location, onEnterRoute, path, routeName]);
  if (bootFinished) {
    if (loggedIn || skipAuthentication) {
      return <Route exact path={path} render={() => <AppContainer currentComponent={currentComponent} />} />;
    } else {
      // user got here by deep link, but is not logged in yet - update last location to return to original target eventually
      authentication.setLastLocation();
      return <Redirect to="/login" />;
    }
  } else {
    return <></>;
  }
};

export default ProtectedRoute;
