import { ModuleConfig } from 'src/models/module';
import { SMS_CHALLENGE_AUTH_RESOURCE_NAME } from './model/sms-challenge-auth';
import { listenToConfirmSmsChallengeAuth, listenToCreateSmsChallengeAuth } from './sagas';

export const smsChallengeAuthModuleConfig: ModuleConfig = {
  moduleName: SMS_CHALLENGE_AUTH_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToCreateSmsChallengeAuth, listenToConfirmSmsChallengeAuth],
  routes: {},
};
