import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  accountTransferResourceConfig,
  CREATE_INTRA_CUSTOMER_TRANSFER_ENDPOINT,
  TRANSFER_CREATION_RESOURCE_NAME,
} from 'src/modules/transfer/model/transfer';
import {
  CREATE_INTRA_CUSTOMER_TRANSFER_ACTION,
  CreateIntraCustomerTransferAction,
} from 'src/modules/transfer/redux/transfer-actions';
import { fetchEntityErrorAction, fetchEntitySuccessAction } from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import { v4 as uuidv4 } from 'uuid';

export function* listenToCreateIntraCustomerTransfer(): SagaIterator<void> {
  yield takeEvery(CREATE_INTRA_CUSTOMER_TRANSFER_ACTION, handleCreateIntraCustomerTransfer);
}

export function* handleCreateIntraCustomerTransfer(action: CreateIntraCustomerTransferAction): SagaIterator<void> {
  const { formValues, pathParams } = action.payload;
  const { account_id } = pathParams;
  const { amount, description, recipient_account_id } = formValues;

  const requestBody = {
    data: {
      type: 'intra_customer_transfer',
      attributes: {
        reference: uuidv4(),
        recipient_account_id,
        description,
        amount,
      },
    },
  };

  // Create intra customer transfer
  const createIntraCustomerTransferResponse = yield call(resourceRequest, {
    resourceConfig: accountTransferResourceConfig,
    endpoint: CREATE_INTRA_CUSTOMER_TRANSFER_ENDPOINT,
    apiRequestParams: {
      pathParams: { account_id },
      queryParams: {},
    },
    requestBody,
  });

  // Set error state
  if (createIntraCustomerTransferResponse.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: TRANSFER_CREATION_RESOURCE_NAME,
        error: createIntraCustomerTransferResponse.error,
      }),
    );
    return;
  }

  // Set success state
  if (createIntraCustomerTransferResponse.data) {
    yield put(fetchEntitySuccessAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));
  }
}
