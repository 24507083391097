import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DataPresenter,
  formatApiDate,
  formatBalance,
  formatIban,
  Header,
  InlineText,
  ItemGroup,
  TextWithCopyButton,
} from 'sb-ui-components';
import { Entity } from 'src/types';
import { Account } from '../models/account';
import { AccountBalanceModal } from './account-balance-modal';

export interface AccountDetailsProps {
  account: Entity<Account> | undefined;
}

export const AccountDetails: FunctionComponent<AccountDetailsProps> = props => {
  const { account } = props;
  const { formatMessage } = useIntl();
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);

  if (!account) return null;

  const attributes = account.data?.attributes;

  return (
    <>
      <Header>
        <h2>{formatMessage({ id: 'title.details' })}</h2>
      </Header>
      <DataPresenter
        dataCy="details_box__account"
        errorMessage={account.error && formatMessage({ id: 'error.loading' })}
        isLoading={account.loading}
        title={{
          size: 'big',
          text: (
            <>
              <ItemGroup gutter="none">
                {formatBalance(account?.data?.attributes?.balance)}
                {attributes && (
                  <Button
                    appearance="no-background"
                    leadingIcon="CircleInfoSolid"
                    color="neutral"
                    handleClick={() => setIsBalanceModalOpen(true)}
                  />
                )}
              </ItemGroup>
              <InlineText size="xsmall" color="soft" block>
                {formatMessage({ id: 'message.balance' })}
              </InlineText>
            </>
          ),
        }}
        data={[
          ...(attributes?.iban
            ? [
                {
                  label: formatMessage({ id: 'label.iban' }),
                  value: (
                    <TextWithCopyButton textToCopy={attributes?.iban} contentToRender={formatIban(attributes?.iban)} />
                  ),
                },
              ]
            : []),
          {
            label: formatMessage({ id: 'label.bic' }),
            value: attributes?.bic,
          },
          {
            label: formatMessage({ id: 'label.openedAt' }),
            value: formatApiDate(attributes?.opened_at),
          },
        ]}
      />
      {account.data?.attributes && (
        <AccountBalanceModal
          account={account.data}
          isOpen={isBalanceModalOpen}
          onClose={() => setIsBalanceModalOpen(false)}
        />
      )}
    </>
  );
};
