import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BoxWithIcon, Button, ButtonLink, Header, PageSection, PageTitle, SmallTabs } from 'sb-ui-components';
import { PageWrapper } from 'src/components/page-wrapper';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { AccountListItem } from 'src/modules/account/components/account-list-item';
import { Account, ACCOUNT_RESOURCE_NAME } from 'src/modules/account/models/account';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { Entity } from 'src/types';
import {
  IntraTransferFormValues,
  ReferenceAccountPayoutFormValues,
  SepaCreditTransferFormValues,
  TRANSFER_CREATION_RESOURCE_NAME,
  TransferCreationType,
  TransferStep,
} from '../model/transfer';
import {
  createIntraCustomerTransferAction,
  createReferenceAccountPayoutAction,
  createSepaCreditTransferAction,
} from '../redux/transfer-actions';
import { CreateTransferLoaderSkeleton } from './create-transfer-loader-skeleton';
import { TransferSummary } from './create-transfer-summary';
import { IntraTransferForm } from './intra-transfer-form';
import { ReferenceAccountPayoutForm } from './reference-account-payout-form';
import { SepaCreditTransferForm } from './sepa-transfer-form';

export const CreateTransferPage: FunctionComponent = ({ children }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  // States

  const [step, setStep] = useState<TransferStep>('FORM');
  const [selectedTransferType, setSelectedTransferType] = useState<TransferCreationType>('SEPA');

  const account: Entity<Account> = useSelector((state: any) => state.entity[ACCOUNT_RESOURCE_NAME]);
  const transferRequest = useSelector((state: any) => state.entity[TRANSFER_CREATION_RESOURCE_NAME]);

  const [summaryData, setSummaryData] = useState<
    SepaCreditTransferFormValues | IntraTransferFormValues | ReferenceAccountPayoutFormValues | undefined
  >();

  // Handlers

  const onBack = () => {
    setStep('FORM');
    dispatch(resetEntityStateAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));
  };

  const onConfirm = (transferType: TransferCreationType) => {
    if (!account?.data) return;
    switch (transferType) {
      case 'SEPA':
        dispatch(
          createSepaCreditTransferAction({
            formValues: summaryData as SepaCreditTransferFormValues,
            pathParams: { account_id: account.data!.id },
          }),
        );
        break;
      case 'INTRA':
        dispatch(
          createIntraCustomerTransferAction({
            formValues: summaryData as IntraTransferFormValues,
            pathParams: { account_id: account.data!.id },
          }),
        );
        break;
      case 'REFERENCE_ACCOUNT':
        dispatch(
          createReferenceAccountPayoutAction({
            formValues: summaryData as ReferenceAccountPayoutFormValues,
            pathParams: { account_id: account.data!.id },
          }),
        );
        break;
      default:
        break;
    }
  };

  if (transferRequest?.data)
    return (
      <PageWrapper dynamicTopMargin maxWidth="small" title={formatMessage({ id: 'title.transfer.create' })}>
        <SuccessErrorMessage
          type="success"
          title={formatMessage({ id: 'success.transfer.title' })}
          message={selectedTransferType !== 'INTRA' ? formatMessage({ id: 'success.transfer.message' }) : undefined}
          cta={
            <ButtonLink size="big" href={createPathByRouteName('account.index')} trailingIcon="ArrowRightRegular">
              {formatMessage({ id: 'button.goToAccounts' })}
            </ButtonLink>
          }
        />
      </PageWrapper>
    );

  if (transferRequest?.error)
    return (
      <PageWrapper dynamicTopMargin maxWidth="small" title={formatMessage({ id: 'title.transfer.create' })}>
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.general.title' })}
          message={formatMessage({ id: 'error.general.message' })}
          cta={
            <Button size="big" handleClick={onBack}>
              {formatMessage({ id: 'button.ok' })}
            </Button>
          }
        />
      </PageWrapper>
    );

  return (
    <PageWrapper maxWidth="small" title={formatMessage({ id: 'title.transfer.create' })}>
      <Header isPageHeader>
        <PageTitle text={formatMessage({ id: 'title.transfer.create' })} />
      </Header>

      {/* Loading state */}
      {account?.loading && <CreateTransferLoaderSkeleton />}

      {/* If account loading failed */}
      {account?.error && (
        <BoxWithIcon type="warning" icon="ExclamationTriangleLight" iconSize="big" alignItems="baseline">
          {formatMessage({ id: 'error.loading.account' })}
        </BoxWithIcon>
      )}

      {/* After account was loaded */}
      {account?.data && (
        <>
          {step === 'FORM' && (
            <>
              <PageSection>
                <h2>{formatMessage({ id: 'title.transfer.originAccount' })}</h2>
                <AccountListItem account={account.data} displayActions={false} />
              </PageSection>
              <PageSection dataCy="section__recipient">
                <h2>{formatMessage({ id: 'title.transfer.recipient' })}</h2>

                <SmallTabs
                  tabs={[
                    {
                      label: formatMessage({ id: 'title.sepaTransfer' }),
                      content: (
                        <SepaCreditTransferForm
                          account={account.data}
                          setStep={setStep}
                          setSummaryData={setSummaryData as (data: SepaCreditTransferFormValues) => void}
                          summaryData={summaryData as SepaCreditTransferFormValues}
                        />
                      ),
                      action: () => setSelectedTransferType('SEPA'),
                      active: selectedTransferType === 'SEPA',
                    },
                    {
                      label: formatMessage({ id: 'title.ownAccount' }),
                      content: (
                        <IntraTransferForm
                          account={account.data}
                          setStep={setStep}
                          setSummaryData={setSummaryData as (data: IntraTransferFormValues) => void}
                          summaryData={summaryData as IntraTransferFormValues}
                        />
                      ),
                      action: () => setSelectedTransferType('INTRA'),
                      active: selectedTransferType === 'INTRA',
                    },
                    {
                      label: formatMessage({ id: 'title.referenceAccount' }),
                      content: (
                        <ReferenceAccountPayoutForm
                          account={account.data}
                          setStep={setStep}
                          setSummaryData={setSummaryData as (data: ReferenceAccountPayoutFormValues) => void}
                          summaryData={summaryData as ReferenceAccountPayoutFormValues}
                        />
                      ),
                      action: () => setSelectedTransferType('REFERENCE_ACCOUNT'),
                      active: selectedTransferType === 'REFERENCE_ACCOUNT',
                    },
                  ]}
                />
              </PageSection>
            </>
          )}

          {/* Summary */}
          {step === 'SUMMARY' && selectedTransferType && summaryData && account.data && (
            <TransferSummary
              account={account.data}
              summaryData={summaryData}
              isSubmitting={transferRequest?.loading}
              onBack={onBack}
              onConfirm={() => onConfirm(selectedTransferType)}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};
