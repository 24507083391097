import { ListResourceState, PayloadAction } from '../../types';
import { ListResourceName } from './list-reducer';

export const LIST_INITIALIZE_ACTION = 'list/INITIALIZE';
export const LIST_SET_LOADING_ACTION = 'list/SET_LOADING';
export const LIST_CLEAR_ACTION = 'list/CLEAR';
export const LIST_CLEAR_ALL_ACTION = 'list/CLEAR_ALL';
export const LIST_UPDATE_SINGLE_ITEM = 'list/UPDATE_SINGLE_ITEM';

// Set list loading state

export interface SetListLoadingActionPayload {
  resourceName: string;
  isLoading: boolean;
}

export type SetListLoadingAction = PayloadAction<typeof LIST_SET_LOADING_ACTION, SetListLoadingActionPayload>;

export const setListLoadingAction = (payload: SetListLoadingActionPayload): SetListLoadingAction => ({
  type: LIST_SET_LOADING_ACTION,
  payload,
});

// Initialize list

interface IntializeListActionPayload {
  readonly listData: ListResourceState<unknown>;
  readonly resourceName: ListResourceName;
}

export type InitializeListAction = PayloadAction<typeof LIST_INITIALIZE_ACTION, IntializeListActionPayload>;

export function initializeList(payload: IntializeListActionPayload): InitializeListAction {
  return {
    type: LIST_INITIALIZE_ACTION,
    payload,
  };
}

// Clear list
export interface ClearListActionPayload {
  resourceName: string;
}

export type ClearListAction = PayloadAction<typeof LIST_CLEAR_ACTION, ClearListActionPayload>;

export function clearListAction(payload: ClearListActionPayload): ClearListAction {
  return {
    type: LIST_CLEAR_ACTION,
    payload,
  };
}

// Clear all lists

export interface ClearAllListsActionPayload {
  resetFiltersAndPages: boolean;
}

export type ClearAllListsAction = PayloadAction<typeof LIST_CLEAR_ALL_ACTION, ClearAllListsActionPayload>;

export function clearAllListsAction(payload: ClearAllListsActionPayload): ClearAllListsAction {
  return {
    type: LIST_CLEAR_ALL_ACTION,
    payload,
  };
}

// Update particular list item

export interface UpdateListItemActionPayload {
  resourceName: string;
  id: string;
  data: unknown;
}

export type UpdateListItemAction = PayloadAction<typeof LIST_UPDATE_SINGLE_ITEM, UpdateListItemActionPayload>;

export const updateListItemAction = (payload: UpdateListItemActionPayload): UpdateListItemAction => ({
  type: LIST_UPDATE_SINGLE_ITEM,
  payload,
});
