import { PayloadAction } from 'src/types';
import {
  IntraTransferFormValues,
  ReferenceAccountPayoutFormValues,
  SepaCreditTransferFormValues,
} from '../model/transfer';

export const CREATE_SEPA_CREDIT_TRANSFER_ACTION = 'sepaCreditTransfer/CREATE';
export const CREATE_INTRA_CUSTOMER_TRANSFER_ACTION = 'intraCustomerTransfer/CREATE';
export const CREATE_REFERENCE_ACCOUNT_PAYOUT_ACTION = 'referenceAccountPayout/CREATE';

// SEPA

export interface CreateSepaCreditTransferActionPayload {
  formValues: SepaCreditTransferFormValues;
  pathParams: { account_id: string };
}

export type CreateSepaCreditTransferAction = PayloadAction<
  typeof CREATE_SEPA_CREDIT_TRANSFER_ACTION,
  CreateSepaCreditTransferActionPayload
>;

export const createSepaCreditTransferAction = (
  payload: CreateSepaCreditTransferActionPayload,
): CreateSepaCreditTransferAction => ({
  type: CREATE_SEPA_CREDIT_TRANSFER_ACTION,
  payload,
});

// INTRA

export interface CreateIntraCustomerActionPayload {
  formValues: IntraTransferFormValues;
  pathParams: { account_id: string };
}

export type CreateIntraCustomerTransferAction = PayloadAction<
  typeof CREATE_INTRA_CUSTOMER_TRANSFER_ACTION,
  CreateIntraCustomerActionPayload
>;

export const createIntraCustomerTransferAction = (
  payload: CreateIntraCustomerActionPayload,
): CreateIntraCustomerTransferAction => ({
  type: CREATE_INTRA_CUSTOMER_TRANSFER_ACTION,
  payload,
});

// REFERENCE ACCOUNT PAYOUT

export interface CreateReferenceAccountPayoutActionPayload {
  formValues: ReferenceAccountPayoutFormValues;
  pathParams: { account_id: string };
}

export type CreateReferenceAccountPayoutAction = PayloadAction<
  typeof CREATE_REFERENCE_ACCOUNT_PAYOUT_ACTION,
  CreateReferenceAccountPayoutActionPayload
>;

export const createReferenceAccountPayoutAction = (
  payload: CreateReferenceAccountPayoutActionPayload,
): CreateReferenceAccountPayoutAction => ({
  type: CREATE_REFERENCE_ACCOUNT_PAYOUT_ACTION,
  payload,
});
