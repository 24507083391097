import { signOut } from 'aws-amplify/auth';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { LOGIN_ROUTE } from 'src/modules/login';
import { AUTH_LOGOUT_ACTION } from 'src/redux/auth/auth-actions';
import { resetAppState } from 'src/redux/root/root-action';
import { forwardTo } from 'src/sagas/authentication';

/*
 * TODO remove page enter handler when SidebarMenuItem can have a click handler
 * without requiring sub menu items
 */
export function* logoutPageEnter(): SagaIterator<void> {
  yield call(removeTokenAndReturnToLogin);
}

export function* listenToLogoutRequest(): SagaIterator<void> {
  yield takeEvery(AUTH_LOGOUT_ACTION, handleLogoutAction);
}
export function* handleLogoutAction(): SagaIterator<void> {
  yield call(removeTokenAndReturnToLogin);
}

export function* removeTokenAndReturnToLogin(): SagaIterator<void> {
  yield call(signOut);
  yield put(resetAppState());
  yield call(forwardTo, createPathByRouteName(LOGIN_ROUTE));
}
