import { Balance } from 'sb-ui-components';
import { Account } from 'src/modules/account/models/account';
import { ReferenceAccount } from 'src/modules/reference-account/models/reference-account';
import { ResourceConfig } from 'src/types';
import { Enum } from 'typescript-string-enums';

export const transferCreationTypes = Enum('SEPA', 'INTRA', 'REFERENCE_ACCOUNT');
export type TransferCreationType = Enum<typeof transferCreationTypes>;

const transferSteps = Enum('FORM', 'SUMMARY');
export type TransferStep = Enum<typeof transferSteps>;

export interface SepaCreditTransferFormValues {
  amount: Balance;
  description?: string;
  recipient_iban: string;
  recipient_name: string;
}

export interface IntraTransferFormValues {
  recipientAccount: Account;
  amount: Balance;
  description?: string;
  recipient_account_id: string;
}

export interface ReferenceAccountPayoutFormValues {
  amount: Balance;
  description: string;
  end_to_end_id?: string;
  referenceAccount: ReferenceAccount;
  reference?: string;
}

export const TRANSFER_CREATION_RESOURCE_NAME = 'transferCreation';

export const CREATE_SEPA_CREDIT_TRANSFER_ENDPOINT = 'accountSepaCreditTransfer/CREATE';
export const CREATE_INTRA_CUSTOMER_TRANSFER_ENDPOINT = 'intraCustomerTransfer/CREATE';
export const CREATE_REFERENCE_ACCOUNT_PAYOUT_ENDPOINT = 'reference-account-payout/CREATE';

export const accountTransferResourceConfig: ResourceConfig = {
  resourceName: TRANSFER_CREATION_RESOURCE_NAME,
  endpoints: {
    [CREATE_SEPA_CREDIT_TRANSFER_ENDPOINT]: {
      path: '/accounts/{account_id}/sepa-credit-transfers',
      httpMethod: 'post',
    },
    [CREATE_INTRA_CUSTOMER_TRANSFER_ENDPOINT]: {
      path: '/accounts/{account_id}/intra-customer-transfers',
      httpMethod: 'post',
    },
    [CREATE_REFERENCE_ACCOUNT_PAYOUT_ENDPOINT]: {
      path: '/accounts/{account_id}/payouts',
      httpMethod: 'post',
    },
  },
};
