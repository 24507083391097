import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { createSmsChallengeAuthAction } from 'src/modules/sms-challenge-auth/redux/sms-challenge-auth-actions';
import { setAuthStateAction, storePartnerIdAction } from 'src/redux/auth/auth-actions';
import { AppState } from 'src/types';
import { ApiRequestParams } from '../../../lib/api';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  resetEntityStateAction,
} from '../../../redux/entity/entity-actions';
import { resourceRequest } from '../../../sagas/resource-requests';
import {
  credentialResourceConfig,
  CREDENTIALS_CREATE_ENDPOINT,
  CREDENTIALS_RESOURCE_NAME,
} from '../models/credentials-create';
import { CREATE_CREDENTIALS_ACTION, CreateCredentialsAction } from '../redux/credentials-create-actions';

export function* createCredentialsEnter(params: ApiRequestParams) {
  const { token, partner_id } = params.queryParams;
  const isAuthorized = false;
  yield put(setAuthStateAction({ isAuthorized, idToken: String(token) }));
  yield put(storePartnerIdAction({ partnerId: String(partner_id) }));
}

export function* listenToCreateCredentials(): SagaIterator<void> {
  yield takeEvery(CREATE_CREDENTIALS_ACTION, handleCreateCredentials);
}

export function* handleCreateCredentials(action: CreateCredentialsAction): SagaIterator<void> {
  const token = yield select((state: AppState) => state.auth.idToken);
  const partner_id = yield select((state: AppState) => state.auth.partnerId);
  const { username, password } = action.payload;

  // Set loading state
  yield put(fetchEntityInitAction({ entityName: CREDENTIALS_RESOURCE_NAME }));

  // Create credentials
  const createCredentialsResponse = yield call(createCredentials, { username, password, token, partner_id });

  // Error handling
  if (createCredentialsResponse.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: CREDENTIALS_RESOURCE_NAME,
        error: createCredentialsResponse.error,
      }),
    );
    return;
  }

  // Remove loading state
  yield put(
    resetEntityStateAction({
      entityName: CREDENTIALS_RESOURCE_NAME,
    }),
  );

  // Trigger SMS challenge
  const { id: challengeRequestId } = createCredentialsResponse.data;
  yield put(createSmsChallengeAuthAction({ challengeRequestId }));
}

export interface createCredentialsArgs {
  partner_id: string;
  password: string;
  token: string;
  username: string;
}

export function* createCredentials(args: createCredentialsArgs): SagaIterator<void> {
  const { partner_id, password, token, username } = args;
  //Api-Version is required because there is another deprecated version of this endpoint:
  //https://github.solarisbank.de/pages/b2c-banking/adac-design/banking_auth/specs/public/#tag/Credentials/operation/CreateCredentialWithChallenge
  const apiRequestParams: ApiRequestParams = {
    headerParams: { 'Api-Version': '2023-12-01', Authorization: `Bearer ${token}`, 'X-Partner-Id': partner_id },
    pathParams: {},
    queryParams: {},
  };

  const requestBody = {
    data: {
      type: 'credential',
      attributes: {
        username,
        password,
      },
    },
  };

  // ToDo: BO-3502 will implement error handling
  return yield call(resourceRequest, {
    resourceConfig: credentialResourceConfig,
    endpoint: CREDENTIALS_CREATE_ENDPOINT,
    apiRequestParams,
    requestBody,
  });
}
