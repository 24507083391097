import { SagaIterator } from 'redux-saga';
import { fork, put } from 'redux-saga/effects';
import { REQUEST_SUPPORT_RESOURCE_NAME } from 'src/modules/request-support/models/request-support';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { indexPostbox } from '../../../postbox/sagas';

export function* showPageEnterService(): SagaIterator<void> {
  yield fork(indexPostbox);
  yield put(resetEntityStateAction({ entityName: REQUEST_SUPPORT_RESOURCE_NAME }));
}
