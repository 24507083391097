import { Action, combineReducers } from 'redux';
import { DEFAULT_APP_STATE } from 'src/models/default-app-state';
import { modulesRegistry } from 'src/modules-registry';
import { RESET_APP_STATE_ACTION } from 'src/redux/root/root-action';
import { AppState } from 'src/types';
import * as reducers from '../reducers';

export function getReducers() {
  let reducers = {};

  for (const key in modulesRegistry) {
    if (modulesRegistry[key]) {
      const reducer = modulesRegistry[key].redux.reducer;

      if (reducer) {
        reducers = {
          ...reducers,
          ...reducer,
        };
      }
    }
  }
  return reducers;
}

// setup redux reducer
const applicationReducers = {
  ...reducers,
  ...getReducers(),
};

const allReducers = combineReducers<any>(applicationReducers);

export const rootReducer = (state: AppState, action: Action) => {
  // root reducer as wrapper for combined reducer to have a global reset state reducer
  // s. https://www.digitalocean.com/community/tutorials/redux-reset-state-redux
  if (action.type === RESET_APP_STATE_ACTION) {
    // we keep boot and ui state, but reset everything else
    // boot state indicates app is initialized - otherwise a blank screen would be shown
    // ui state holds selected language - we do not want to switch back to default language after logout
    const { boot, ui } = state;
    state = { ...DEFAULT_APP_STATE, boot, ui };
  }

  return allReducers(state, action);
};
