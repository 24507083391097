import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ButtonLink } from 'sb-ui-components';
import { ModalWrapper } from 'src/components/modal-wrapper';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { CREDENTIALS_RESOURCE_NAME } from '../../models/credentials-create';
import styles from './expired-credentials-error.module.scss';

export interface ExpiredCredentialsErrorModalProps {
  isOpen: boolean;
}

export const ExpiredCredentialsErrorModal: FunctionComponent<ExpiredCredentialsErrorModalProps> = props => {
  const { isOpen } = props;
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  return (
    <ModalWrapper
      isOpen={isOpen}
      modalProps={{
        maxWidth: 'small',
        className: styles.modal,
        dataCy: 'modal__sms_challenge',
        align: 'center',
      }}
    >
      <SuccessErrorMessage
        type="error"
        title={formatMessage({ id: 'error.tokenExpired.title' })}
        message={formatMessage({ id: 'error.tokenExpired.message' })}
        cta={
          <ButtonLink
            size="big"
            trailingIcon="ArrowRightRegular"
            action={() => {
              dispatch(resetEntityStateAction({ entityName: CREDENTIALS_RESOURCE_NAME }));
            }}
            dataCy="cta__modal_button_confirm"
            href={createPathByRouteName('emailVerification.verify')}
          >
            {formatMessage({ id: 'button.verifyAgain' })}
          </ButtonLink>
        }
      />
    </ModalWrapper>
  );
};
