export const emailIsValid = (value: string | undefined) =>
  value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value);

export const mobileIsValid = (value: string | undefined) => value && /^\+[1-9]{1}[0-9]{3,14}$/i.test(value);

export const emailValidation = (value: string | undefined) => (emailIsValid(value) ? undefined : 'email not valid!');

export const fourDigitValidation = (value: string | undefined) => value && /^[0-9]{4}$/i.test(value);

export const mobileValidation = (value: string | undefined) => (mobileIsValid(value) ? undefined : 'phone not valid!');

export const isPresent = (value: string) => Boolean(value.trim());

export const noEmail = (value: string) => !value.includes('@');

export const alphanumeric = (value: string) => /^[a-zA-Z0-9]+$/.test(value);

export const minLength = (value: string, length: number) => value.length >= length;

export const maxLength = (value: string, length: number) => value.length <= length;
