import { ThemeName } from 'sb-ui-components';
import { LocaleOption } from 'src/components/localization-wrapper';
import { PayloadAction, SimpleAction } from 'src/types';

export const UI_TOGGLE_ADVANCED_FILTERS_ACTION = 'ui/TOGGLE_ADVANCED_FILTERS';
export const UI_SET_THEME_ACTION = 'ui/SET_THEME';
export const UI_SET_LOCALE_ACTION = 'ui/SET_LOCALE';
export const UI_TOGGLE_SPECIFIC_LOADER_ACTION = 'ui/UI_TOGGLE_SPECIFIC_LOADER';
export const UI_SHOW_COUNTDOWN_ACTION = 'ui/UI_SHOW_COUNTDOWN';
export const UI_MOUSE_CLICK_ACTION = 'ui/UI_MOUSE_CLICK';

// Toggle Theme

export interface UiSetThemeActionPayload {
  theme: ThemeName;
}

export type UiSetThemeAction = PayloadAction<typeof UI_SET_THEME_ACTION, UiSetThemeActionPayload>;

export const uiSetThemeAction = (payload: UiSetThemeActionPayload): UiSetThemeAction => ({
  type: UI_SET_THEME_ACTION,
  payload,
});

// Change Locale

export interface UiSetLocaleActionPayload {
  locale: LocaleOption;
}

export type UiSetLocaleAction = PayloadAction<typeof UI_SET_LOCALE_ACTION, UiSetLocaleActionPayload>;

export const uiSetLocaleAction = (payload: UiSetLocaleActionPayload): UiSetLocaleAction => ({
  type: UI_SET_LOCALE_ACTION,
  payload,
});

export type UiShowCountdownAction = PayloadAction<typeof UI_SHOW_COUNTDOWN_ACTION, boolean>;

export const showCountDown = (payload: boolean): UiShowCountdownAction => ({
  type: UI_SHOW_COUNTDOWN_ACTION,
  payload,
});

/**
 * Redux action that gets dispatched when user clicks a mouse button
 */
export type UiMouseClickAction = SimpleAction<typeof UI_MOUSE_CLICK_ACTION>;

export const mouseClick = (): UiMouseClickAction => ({
  type: UI_MOUSE_CLICK_ACTION,
});
