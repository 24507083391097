import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { resourceIndexAndStoreToState } from 'src/sagas/resource-requests';
import { postboxResourceConfig } from '../../models/postbox';

export function* indexPostbox(): SagaIterator<void> {
  yield call(resourceIndexAndStoreToState, {
    resourceConfig: postboxResourceConfig,
    params: { pathParams: {}, queryParams: {} },
  });
}
