import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useCountdown from 'src/lib/hooks/countdown';
import { formatTime } from 'src/lib/utils';
import { logoutAction } from 'src/redux/auth/auth-actions';
import { AppState } from 'src/types';
import styles from './logout-timer.module.css';

export const LogoutTimer: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const COUNTDOWN_START_SECONDS = 120;

  const showCountdown = useSelector((state: AppState) => state.ui.showCountDown);

  const onTimeout = () => {
    dispatch(logoutAction());
  };

  // Countdown
  const remainingSeconds = useCountdown({
    initialRemainingSeconds: COUNTDOWN_START_SECONDS,
    onTimeout,
    autoStart: showCountdown,
  });

  if (!showCountdown) return null;

  return (
    <div className={styles.main}>
      {formatMessage({ id: 'message.logoutTimer' })} <em>{formatTime(remainingSeconds)}</em>
    </div>
  );
};
