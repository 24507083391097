import { SagaIterator } from 'redux-saga';
import { delay, put, spawn } from 'redux-saga/effects';
import { Notification, notificationTypes } from 'sb-ui-components';
import { v4 } from 'uuid';
import { clearNotificationAction, createNotificationAction } from '../redux/notification/notification-actions';

// Create notification

export function* createNotification(notification: Notification): SagaIterator<void> {
  const { id, type } = notification;

  const delayTime = type === notificationTypes.ERROR ? 5000 : 2000;

  yield put(createNotificationAction({ notification }));
  yield delay(delayTime);
  yield put(clearNotificationAction({ id }));
}

// Spawn success notification

export function* spawnSuccessNotification(topic: string): SagaIterator<void> {
  const notification: Notification = {
    id: v4(),
    type: 'SUCCESS',
    topic,
  };
  yield spawn(createNotification, notification);
}
