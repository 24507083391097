import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveTopHeader, ThemeProvider } from 'sb-ui-components';
import { Footer } from 'src/components/footer';
import { LocaleOption, locales } from 'src/components/localization-wrapper';
import { uiSetLocaleAction } from 'src/redux/ui/ui-actions';
import { AppState } from 'src/types';
import { Enum } from 'typescript-string-enums';
import styles from './auth-wrapper.module.scss';

export const AuthPageWrapper: FunctionComponent = props => {
  const { children } = props;
  const { formatMessage } = useIntl();

  // Locale
  const dispatch = useDispatch();
  const currentLocale = useSelector((state: AppState) => state.ui.locale);
  const handleSetLocale = (newLocale: LocaleOption) => {
    dispatch(uiSetLocaleAction({ locale: newLocale }));
    localStorage.setItem('locale', newLocale);
  };

  return (
    <ThemeProvider theme="light">
      <div className={styles.page}>
        <ResponsiveTopHeader
          theme="light"
          locales={{
            options: Enum.values(locales).map(locale => ({
              label: formatMessage({ id: `locale.${locale}` }),
              value: locale,
            })),
            currentLocale: currentLocale,
            setLocale: (locale: string) => handleSetLocale(locale as LocaleOption),
          }}
        />
        <main className={styles.main}>
          <ThemeProvider theme="dark">{children}</ThemeProvider>
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};
