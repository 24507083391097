import { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Page, PageProps } from 'sb-ui-components';
import { AppState } from 'src/types';

interface PageWrapperProps extends PageProps {
  title: string;
}

export const PageWrapper: FunctionComponent<PageWrapperProps> = props => {
  const { title, children } = props;
  const locale = useSelector((state: AppState) => state.ui.locale);

  return (
    <Page {...props}>
      <Helmet title={title} htmlAttributes={{ lang: locale }} />
      {children}
    </Page>
  );
};
