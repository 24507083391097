import { ResourceConfig } from 'src/types';

export const SMS_CHALLENGE_RESOURCE_NAME = 'smsChallenge';
export const SMS_CHALLENGE_CONFIRMATION_STATE = 'smsChallengeConfirmation';
export const POST_CREATE_SMS_CHALLENGE_ENDPOINT = 'post/triggerSmsChallenge';
export const POST_CONFIRM_CONFIRMATION_VALUE_ENDPOINT = 'post/confirmSmsCode';

export interface SmsChallengeCreation {
  type: string;
  id: string;
  relationships: {
    challenge_request: {
      data: {
        type: string;
        id: string;
      };
    };
  };
  attributes: {
    status: string;
    masked_mobile_number: string;
    confirmation_value_type: string;
  };
}

export const smsChallengeResourceConfig: ResourceConfig = {
  resourceName: SMS_CHALLENGE_RESOURCE_NAME,
  endpoints: {
    [POST_CREATE_SMS_CHALLENGE_ENDPOINT]: {
      path: '/customers/me/challenges',
      httpMethod: 'post',
    },
    [POST_CONFIRM_CONFIRMATION_VALUE_ENDPOINT]: {
      path: '/challenges/{challengeId}/confirmations',
      httpMethod: 'post',
    },
  },
};
