import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { createSmsChallengeAuthAction } from 'src/modules/sms-challenge-auth/redux/sms-challenge-auth-actions';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
  resetEntityStateAction,
} from '../../../../redux/entity/entity-actions';
import { resourceRequest } from '../../../../sagas/resource-requests';
import forgotPasswordResourceConfig, {
  CONFIRM_PASSWORD_STATE,
  CONFIRM_RESET_PASSWORD_ENDPOINT,
  FORGOT_PASSWORD_RESOURCE_NAME,
} from '../../models/forgot-password';
import {
  CONFIRM_RESET_PASSWORD_ACTION,
  ConfirmResetPasswordAction,
  RESET_PASSWORD_ACTION,
  ResetPasswordAction,
} from '../../redux/forgot-password-actions';

// Submit password form

export function* listenToResetPassword(): SagaIterator<void> {
  yield takeEvery(RESET_PASSWORD_ACTION, handleResetPassword);
}

export function* handleResetPassword(action: ResetPasswordAction): SagaIterator<void> {
  const { password } = action.payload;

  // Set password in state
  yield put(fetchEntitySuccessAction({ entityName: FORGOT_PASSWORD_RESOURCE_NAME, data: { password } }));

  // ToDo: Get challengeRequestId from URL params
  const challengeRequestId = '123';

  // Trigger SMS challenge
  yield put(createSmsChallengeAuthAction({ challengeRequestId }));
}

// Confirm password reset with SMS challenge

export function* listenToConfirmResetPassword(): SagaIterator<void> {
  yield takeEvery(CONFIRM_RESET_PASSWORD_ACTION, handleConfirmResetPassword);
}

export function* handleConfirmResetPassword(action: ConfirmResetPasswordAction): SagaIterator<void> {
  const { confirmation_value } = action.payload;

  const challengeId: string = yield select(state => state.entity?.smsChallenge?.data?.id);
  const password: string = yield select(state => state.entity?.forgotPassword?.data?.password);

  // Set loading state
  yield put(fetchEntityInitAction({ entityName: CONFIRM_PASSWORD_STATE }));

  const apiRequestParams = {
    pathParams: {},
    queryParams: {},
  };

  const requestBody = {
    data: {
      type: 'confirm_forgot_password',
      relationships: {
        sms_challenge: {
          data: {
            type: 'sms_challenge',
            id: challengeId,
          },
        },
      },
      attributes: {
        confirmation_value,
        password,
      },
    },
  };

  // Reset password in state
  yield put(resetEntityStateAction({ entityName: FORGOT_PASSWORD_RESOURCE_NAME }));

  const confirmResetPasswordResponse = yield call(resourceRequest, {
    resourceConfig: forgotPasswordResourceConfig,
    endpoint: CONFIRM_RESET_PASSWORD_ENDPOINT,
    apiRequestParams,
    requestBody,
  });

  // If request fails set and return error
  if (confirmResetPasswordResponse.error) {
    yield put(
      fetchEntityErrorAction({ entityName: CONFIRM_PASSWORD_STATE, error: confirmResetPasswordResponse.error }),
    );
    return;
  }

  // Set success state
  yield put(fetchEntitySuccessAction({ entityName: CONFIRM_PASSWORD_STATE }));
}
