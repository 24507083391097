import { FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonLink,
  Col,
  ItemGroup,
  Page,
  PageSection,
  Row,
  SelectInput,
  TextAreaInput,
} from 'sb-ui-components';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import {
  REQUEST_SUPPORT_RESOURCE_NAME,
  requestSupportSubjectOptions,
  RequestSupportSubTopicOptionKeys,
  requestSupportSubTopicOptionsMap,
} from '../../models/request-support';
import { submitRequestSupportTicket, SubmitRequestSupportTicketPayload } from '../../redux/request-support-actions';

const RequestSupportPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const requestSupportEntityState = useAppSelector(state => state.entity[REQUEST_SUPPORT_RESOURCE_NAME]);

  const title = formatMessage({ id: 'title.requestSupport' });

  const { register, handleSubmit, errors, watch } = useForm({ mode: 'onSubmit' });

  const watchSubject: RequestSupportSubTopicOptionKeys = watch('subject', false);

  const showSubTopicOption = requestSupportSubTopicOptionsMap[watchSubject];

  const onFormSubmit = (formData: SubmitRequestSupportTicketPayload) => {
    dispatch(submitRequestSupportTicket(formData));
  };

  const handleCancel = () => {
    const serviceRoute = createPathByRouteName('service.show');

    history.replace(serviceRoute);
  };

  if (requestSupportEntityState?.data) {
    return (
      <Page dynamicTopMargin maxWidth="small">
        <SuccessErrorMessage
          type="success"
          title={formatMessage({ id: 'requestSupport.successMessage' })}
          message={formatMessage({ id: 'requestSupport.successMessageDescription' })}
          cta={
            <ButtonLink size="big" href={createPathByRouteName('service.show')}>
              {formatMessage({ id: 'button.ok' })}
            </ButtonLink>
          }
        />
      </Page>
    );
  }

  return (
    <Page dynamicTopMargin>
      <Helmet title={title} />
      <h1>{title}</h1>

      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Row gutterY="none">
          <Col xs={12}>
            <SelectInput
              id="subject"
              register={register}
              validation={{
                validate: {
                  required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
                },
              }}
              label={formatMessage({ id: 'label.topic' })}
              selectOptions={requestSupportSubjectOptions.map(({ label, value }) => ({
                value: value,
                label: formatMessage({ id: label }),
              }))}
              errorMessage={errors.subject?.message}
            />
          </Col>
          {showSubTopicOption && (
            <Col xs={12}>
              <SelectInput
                id="raw_subject"
                register={register}
                validation={{
                  validate: {
                    required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
                  },
                }}
                label={formatMessage({ id: 'label.subTopic' })}
                selectOptions={requestSupportSubTopicOptionsMap[watchSubject]?.map(({ label, value }) => ({
                  value: value,
                  label: formatMessage({ id: label }),
                }))}
                errorMessage={errors.raw_subject?.message}
              />
            </Col>
          )}
          <Col xs={12}>
            <TextAreaInput
              id="comment"
              register={register}
              label={formatMessage({ id: 'label.description' })}
              hint={formatMessage({ id: 'hint.maxLimit' }, { limit: '1.600' })}
              rows={12}
              validation={{
                validate: {
                  required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
                },
                maxLength: {
                  value: 1600,
                  message: formatMessage({ id: 'requestSupport.descriptionError' }),
                },
              }}
              errorMessage={errors.comment?.message}
            />
            <PageSection>
              <ItemGroup justifyItems="space-between">
                <Button size="big" appearance="stroked" color="neutral" handleClick={handleCancel}>
                  {formatMessage({ id: 'button.cancel' })}
                </Button>
                <Button
                  type="submit"
                  leadingIcon="PaperPlaneSolid"
                  size="big"
                  isLoading={requestSupportEntityState?.loading}
                >
                  {formatMessage({ id: 'button.send' })}
                </Button>
              </ItemGroup>
            </PageSection>
          </Col>
        </Row>
      </form>
    </Page>
  );
};

export default RequestSupportPage;
