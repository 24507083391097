import moment from 'moment';
import { loginAction, LoginParams, LoginResponse, logoutRequest } from '../lib/auth-api';
import { AuthenticationSuccessPayload } from '../types';

export const AUTHENTICATION_FORM_NAME = 'AuthenticationForm';

export const TOKEN_VALID_UNTIL = 'token_valid_until';
export const LAST_LOCATION = 'last_location';
export const EMPLOYEE_EMAIL = 'employee_email';
export const LOGIN_TYPE = 'login_type';
export const LOGGED_IN_AT = 'loggged_in_at';
export const TOKEN_TIMEOUT_SEC = 300; // 5 minutes
export const TOKEN_REFRESHABLE_AFTER_SEC = 180; // 3 minutes

let localStorage: Storage;

export interface AuthenticationFormValues {
  password: string;
  username: string;
}

export interface AuthenticationSessionInfoPayload extends AuthenticationSuccessPayload {
  user: string;
}

export interface AuthenticationLogoutPayload {
  success: boolean;
}

// If we're testing, use a local storage polyfill
if (global.process && process.env.NODE_ENV === 'test') {
  localStorage = require('localStorage');
} else {
  // If not, use the browser one
  localStorage = window.localStorage;
}

const auth = {
  /*
   * @deprecated
   * TODO remove when doing cleanup
   * Logs a user in, returning a promise with `true` when done
   * @param  {string} username The username of the user
   * @param  {string} password The password of the user
   */
  login({ username, password }: LoginParams): Promise<boolean> {
    if (auth.loggedIn()) {
      return Promise.resolve(true);
    }

    const loginParams: LoginParams = { username, password };

    return loginAction(loginParams).then((result: LoginResponse<AuthenticationSuccessPayload>) => {
      if (result.data?.expires_in) {
        localStorage.setItem(
          TOKEN_VALID_UNTIL,
          moment()
            .add(result.data.expires_in, 'seconds')
            .unix()
            .toString(),
        );
        localStorage.setItem(EMPLOYEE_EMAIL, username);
        return Promise.resolve(true);
      }
      return Promise.resolve(false);
    });
  },
  /*
   * Logs the current user out
   */
  logout() {
    return logoutRequest().then(
      (response: AuthenticationLogoutPayload): Promise<AuthenticationLogoutPayload> => {
        const { success } = response;
        if (success) {
          localStorage.removeItem(EMPLOYEE_EMAIL);
          localStorage.removeItem(TOKEN_VALID_UNTIL);
          localStorage.removeItem(LOGIN_TYPE);
          return Promise.resolve(response);
        }
        return Promise.resolve(response);
      },
    );
  },
  /*
   * Checks if a user is logged in
   */
  loggedIn() {
    const validUntil: string | null = localStorage.getItem(TOKEN_VALID_UNTIL);
    if (!validUntil || moment.unix(parseInt(validUntil, 10)).isBefore(moment.now())) {
      return false;
    } else {
      return true;
    }
  },
  setLastLocation() {
    return localStorage.setItem(LAST_LOCATION, window.location.href);
  },
  getLastLocation() {
    return localStorage.getItem(LAST_LOCATION);
  },
  removeLastLocation() {
    return localStorage.removeItem(LAST_LOCATION);
  },
  getEmployeeEmail() {
    return localStorage.getItem(EMPLOYEE_EMAIL);
  },
};

export default auth;
