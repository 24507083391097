import { SagaIterator } from 'redux-saga';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import { fetchEntityInitAction, resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { updateListItemAction } from 'src/redux/list/list-actions';
import { spawnSuccessNotification } from 'src/sagas/notification';
import { resourceRequest } from 'src/sagas/resource-requests';
import { ResourceResponse } from 'src/types';
import {
  ACCOUNT_CARD_RESOURCE_NAME,
  accountCardResourceConfig,
  Card,
  CARD_BLOCK_ENDPOINT,
  CARD_BLOCK_UNBLOCK_STATE,
  CARD_UNBLOCK_ENDPOINT,
} from '../../models/card';
import { BLOCK_CARD_ACTION, BlockCardAction, UNBLOCK_CARD_ACTION, UnblockCardAction } from '../../redux/card-action';

// Block card

export function* listenToBlockCard(): SagaIterator<void> {
  yield takeLatest(BLOCK_CARD_ACTION, handleBlockCard);
}

export function* handleBlockCard(action: BlockCardAction): SagaIterator<void> {
  const { id } = action.payload;
  yield call(blockUnblockCard, id, CARD_BLOCK_ENDPOINT);
}

// Unblock card

export function* listenToUnblockCard(): SagaIterator<void> {
  yield takeLatest(UNBLOCK_CARD_ACTION, handleUnblockCard);
}

export function* handleUnblockCard(action: UnblockCardAction): SagaIterator<void> {
  const { id } = action.payload;
  yield call(blockUnblockCard, id, CARD_UNBLOCK_ENDPOINT);
}

// Perform block or unblock

export function* blockUnblockCard(cardId: string, endpoint: string): SagaIterator<void> {
  // Set loading state
  yield put(fetchEntityInitAction({ entityName: CARD_BLOCK_UNBLOCK_STATE }));
  // Perform request
  const response: ResourceResponse<Card> = yield call(resourceRequest, {
    resourceConfig: accountCardResourceConfig,
    endpoint,
    apiRequestParams: {
      pathParams: { id: cardId },
      queryParams: {},
    },
    spawnErrorNotification: true,
  });

  if (!response.error) {
    // Show success notification
    yield fork(spawnSuccessNotification, `success.${endpoint}`);
    // Update card in the list
    yield put(
      updateListItemAction({
        resourceName: ACCOUNT_CARD_RESOURCE_NAME,
        id: cardId,
        data: response.data,
      }),
    );
  }
  // Clear loading state
  yield put(resetEntityStateAction({ entityName: CARD_BLOCK_UNBLOCK_STATE }));
}
