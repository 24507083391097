import axios, { AxiosResponse } from 'axios';
import {
  PROGRESS_FINISH_LOADING_ACTION,
  PROGRESS_START_LOADING_ACTION,
} from 'src/redux/progress-bar/progress-bar-actions';
import { store } from 'src/redux/store';
import { environmentApiUrl } from '../lib/environment';
import { EMPLOYEE_EMAIL, TOKEN_VALID_UNTIL } from '../models/authentication';
import {
  EndpointConfig,
  Entity,
  ResourceResponse,
  ResourceResponseMeta,
  ServerErrorResponse,
  ServerErrorStatus,
} from '../types';
import { PathParams, QueryParams } from './route-config-helper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const URI = require('urijs');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const URITemplate = require('urijs/src/URITemplate');
export const RECOVERABLE_STATUS_CODES = [501, 401, 403, 400, 409, 412];
export const LOGIN_TIMEOUT_HTTP_CODE = 440;
export interface APIDomainConfig {
  api: string;
}

export const API_CONFIG: APIDomainConfig = {
  api: environmentApiUrl(),
};

export const defaultHeaders = {
  'Content-Type': 'application/json',
};

type DefaultHeaders = typeof defaultHeaders;

interface AuthorizationHeader {
  Authorization?: string;
}

export interface Headers extends DefaultHeaders, AuthorizationHeader {}

export interface ApiRequestParams {
  queryParams: QueryParams;
  pathParams: PathParams;
  headerParams?: Record<string, string>;
}

/*
Exception example:
{
"id":"32b957fb44173ef1b52a731637468ab6ex",
"status":404,
"code":"model_not_found",
"title":"Model Not Found",
"detail":"Couldn't find 'Solaris::Identification' for id '16bb1e948be0f2319583d990dd8a1c57ident'."
}
*/
export interface Exception {
  id?: string;
  status?: number;
  code?: string;
  title: string;
  detail: string;
}

export interface ApiRequest extends ApiRequestParams {
  url: string;
  payload?: {};
  method: 'get' | 'post' | 'patch' | 'delete' | 'put';
}

export function getRequiredPathParamsFromConfig(path: string): string[] {
  const params = path.match(/{.*?}/g);
  if (!params) {
    return [];
  }
  return params.map((value: string) => value.replace(/{|}/g, ''));
}

export function buildPath(configPath: string, pathParams: {}, queryParams: {}): string {
  const uriPath = URITemplate(configPath).expand(pathParams);
  return URI(uriPath)
    .query(queryParams)
    .toString();
}

export function buildRequestConfig(
  enpointConfig: EndpointConfig,
  params: ApiRequestParams = { queryParams: {}, pathParams: {}, headerParams: {} },
  payload?: {},
): ApiRequest {
  const config = API_CONFIG;
  const pathParams = params.pathParams || {};
  const queryParams = params.queryParams || {};
  const headerParams = params.headerParams || {};
  const path = buildPath(enpointConfig.path, pathParams, queryParams);
  const domainURL = config.api;
  const url = domainURL + path;
  const apiRequest: ApiRequest = {
    url,
    payload,
    queryParams,
    pathParams,
    headerParams,
    ...{ method: enpointConfig.httpMethod },
  };

  return apiRequest;
}

export function buildHeaders(headerParams: {} | undefined) {
  const headers: Headers = {
    ...defaultHeaders,
    ...headerParams,
  };
  return headers;
}

/*
  bakes a ResourceResponse out of an AxiosResponse object
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processResponse(
  response: AxiosResponse<Entity<unknown>>,
  params: ApiRequestParams,
): ResourceResponse<unknown> {
  const meta: ResourceResponseMeta = {
    total: response.headers && response.headers.total ? +response.headers.total : 0,
    queryParams: params.queryParams || {},
    pathParams: params.pathParams || {},
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resourceReponse: ResourceResponse<unknown> = {
    // data.data looks ugly, but it is what we get
    // 1. data comes from AxiosResponse
    // 2. data is data wrapper from Middle Layer
    // 3. exception: content-type 'application/octet-stream' for binaryRequest
    data: response.headers['content-type'] === 'application/octet-stream' ? response.data : response.data.data,
    meta,
    status: response.status,
  };
  return resourceReponse;
}

export function request(params: ApiRequest): Promise<ResourceResponse<any>> {
  const { url, method, payload, headerParams } = params;
  const headers = buildHeaders(headerParams);
  const options = {
    url,
    headers,
    method,
    // axios removes content-type from request when no data is provided!
    // this would result in http415 on middle layer
    // mitigation: send empty data object instead.
    // s. https://github.com/axios/axios/issues/86
    ...{ data: payload || {} },
  };
  return genericRequest(params, options, { logErrors: true });
}

const apiClient = axios.create({
  headers: defaultHeaders,
});

export function genericRequest(
  params: ApiRequest,
  options: {},
  loggingOptions: { logErrors: boolean },
): Promise<ResourceResponse<any>> {
  const { queryParams, pathParams } = params;
  return new Promise(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function(resolve: any, reject: any) {
      store.dispatch({ type: PROGRESS_START_LOADING_ACTION });

      apiClient
        .request(options)
        .then((response: AxiosResponse<Entity<unknown>>) => {
          resolve(processResponse(response, { queryParams, pathParams }));

          store.dispatch({ type: PROGRESS_FINISH_LOADING_ACTION });
        })
        .catch((errorResponse: { response: AxiosResponse } & { message: string; stack: string }) => {
          // axios rejects the promise on all HTTP statuses not being between 200 and 300
          // we can define the valid statuses with validateStatus parameter
          // see https://github.com/mzabriskie/axios#user-content-request-config

          const { response, message } = errorResponse;
          let error: ServerErrorResponse;
          if (response) {
            if (response.status === LOGIN_TIMEOUT_HTTP_CODE) {
              localStorage.removeItem(TOKEN_VALID_UNTIL);
              localStorage.removeItem(EMPLOYEE_EMAIL);
              window.location.reload();
            }
            error = {
              apiErrors: response.data?.errors,
              message: response.statusText,
              status: response.status as ServerErrorStatus,
            };
          } else {
            error = { message, status: 404 };
          }

          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const resourceResponse: ResourceResponse<{}> = {
            meta: {
              queryParams,
              pathParams,
            },
            data: {},
            error,
          };

          reject(resourceResponse);

          store.dispatch({ type: PROGRESS_FINISH_LOADING_ACTION });
        });
    },
  );
}

export function binaryRequest(params: ApiRequest): Promise<ResourceResponse<ArrayBuffer>> {
  const { url, method, payload, headerParams } = params;
  const headers = buildHeaders(headerParams);
  const options = {
    url,
    headers,
    responseType: 'stream',
    method,
    ...{ data: payload },
  };
  return genericRequest(params, options, { logErrors: true });
}
