import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { mouseClick } from 'src/redux/ui/ui-actions';
import { LocalizationWrapper } from './components/localization-wrapper';
import { GlobalProgressBar } from './components/progress-bar';
import { boot } from './redux/boot/boot-actions';
import { sagaMiddleware, store } from './redux/store';
import RoutesContainer from './router/containers/router-container';
import appSagas from './sagas/index';
import { getRootSagas, getRoutes } from './config';
// Global CSS must be imported always before routes so it's loaded before components CSS
// (otherwise precedence issues will happen and the world will collapse)
import './css/global.css';

Amplify.configure({
  Auth: {
    Cognito: {
      /**
       * Either we run our app locally with yarn start, then
       * the env variables are read from .env.development and node
       * server uses REACT_APP_xxx. (process.env.REACT_APP_COGNITO_POOL_ID)
       * Or we are serving the files with Sinatra server (production mode)
       * Then the values are injected in index.html as script blog setting
       * the variables accordingly. (process.env.pool_id)
       */
      userPoolId: process.env.REACT_APP_COGNITO_POOL_ID || (globalThis as any).config.pool_id || '',
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || (globalThis as any).config.client_id || '',
    },
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ secure: true, sameSite: 'strict' }));

sagaMiddleware.run(appSagas, getRootSagas());

const allRoutes = getRoutes();

store.dispatch(boot());

/**
 * global event listener for mouse clicks to dispatch corresponding redux action.
 * used to capture interactions and reset the inactivity timer
 */
document.addEventListener('click', () => store.dispatch(mouseClick()));

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <LocalizationWrapper>
      <RoutesContainer routes={allRoutes} />
      <GlobalProgressBar />
    </LocalizationWrapper>
  </Provider>,
);
