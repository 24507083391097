import { Notification } from 'sb-ui-components';
import { PayloadAction } from 'src/types';

export const NOTIFICATION_CREATE_ACTION = 'notification/CREATE';
export const NOTIFICATION_CLEAR_ACTION = 'notification/CLEAR';

// Create

export interface CreateNotificationActionPayload {
  notification: Notification;
}

export type CreateNotificationAction = PayloadAction<
  typeof NOTIFICATION_CREATE_ACTION,
  CreateNotificationActionPayload
>;

export function createNotificationAction(payload: CreateNotificationActionPayload): CreateNotificationAction {
  return {
    type: NOTIFICATION_CREATE_ACTION,
    payload,
  };
}

// Clear

export interface ClearNotificationActionPayload {
  id: string;
}

export type ClearNotificationAction = PayloadAction<typeof NOTIFICATION_CLEAR_ACTION, ClearNotificationActionPayload>;

export function clearNotificationAction(payload: ClearNotificationActionPayload): ClearNotificationAction {
  return {
    type: NOTIFICATION_CLEAR_ACTION,
    payload,
  };
}
