import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import authentication from 'src/models/authentication';
import { checkLoginStatus } from 'src/modules/login/sagas';
import { defaultHomeRoute } from 'src/router/lib/routes-config';
import { forwardTo, replaceAndRemoveLocation } from 'src/sagas/authentication';
import { BOOT_INITIAL_LOAD_ACTION, bootFinished } from './../redux/boot/boot-actions';

export function* boot(): SagaIterator<void> {
  yield takeEvery(BOOT_INITIAL_LOAD_ACTION, handleBootAction);
}

export function* handleBootAction(): SagaIterator<void> {
  // check & refresh auth session
  yield call(checkLoginStatus);
  const lastLocation = authentication.getLastLocation();
  const currentLocation = window.location.pathname;
  const isOnLoginOrRootPath = currentLocation === '/' || currentLocation === '/login';

  if (lastLocation && isOnLoginOrRootPath) {
    // send user back to last location
    replaceAndRemoveLocation(lastLocation);
  } else if (isOnLoginOrRootPath) {
    // user is not logged in so send him to welcome page
    forwardTo(defaultHomeRoute);
  }
  yield put(bootFinished());
}
