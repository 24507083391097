import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, InlineText, ModalProps, PageSection } from 'sb-ui-components';
import { AuthPageWrapper } from 'src/components/auth-wrapper';
import { SuccessErrorMessage, SuccessErrorMessageProps } from 'src/components/success-error-message';
import { SmsChallengeAuthModal } from 'src/modules/sms-challenge-auth/components';
import {
  SMS_CHALLENGE_AUTH_CONFIRMATION_STATE,
  SMS_CHALLENGE_AUTH_RESOURCE_NAME,
} from 'src/modules/sms-challenge-auth/model/sms-challenge-auth';
import { createSmsChallengeAuthAction } from 'src/modules/sms-challenge-auth/redux/sms-challenge-auth-actions';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';

export interface VerificationProps extends ModalProps {
  successMessage: SuccessErrorMessageProps['message'];
  isOpen: boolean;
  onClose?: () => void;
}

export const PhoneVerificationPage: FunctionComponent<VerificationProps> = () => {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const challengeRequestId = String(useSelector((state: AppState) => state.auth.challengeRequestId));

  // Hooks to open and close the sms challenge modal

  const entityState: EntityState = useSelector((state: AppState) => state.entity);

  const smsChallengeAuthState: Entity<any> | undefined = entityState?.[SMS_CHALLENGE_AUTH_RESOURCE_NAME];
  const isSmsChallangeCreationTriggered = Boolean(smsChallengeAuthState);

  const smsChallengeAuthConfirmationState: Entity<any> | undefined =
    entityState?.[SMS_CHALLENGE_AUTH_CONFIRMATION_STATE];

  const isChallengeConfirmationSuccess = Boolean(smsChallengeAuthConfirmationState?.data);

  // Actions

  const onVerifyButtonClick = () => {
    dispatch(createSmsChallengeAuthAction({ challengeRequestId }));
  };

  if (isChallengeConfirmationSuccess) {
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="success"
          title={formatMessage({ id: 'success.phoneVerification.title' })}
          message={formatMessage({ id: 'success.phoneNumberVerification.message' })}
        />
      </AuthPageWrapper>
    );
  }

  return (
    <AuthPageWrapper>
      <h1>{formatMessage({ id: 'title.phoneNumberVerification' })}</h1>
      <PageSection>
        <InlineText size="small">{formatMessage({ id: 'label.phoneNumberVerification' })}</InlineText>
      </PageSection>
      <Button
        size="big"
        trailingIcon="ArrowRightRegular"
        type="button"
        dataCy="cta__verifyPhoneNumber"
        handleClick={onVerifyButtonClick}
      >
        {formatMessage({ id: 'button.phoneNumberVerification' })}
      </Button>

      <SmsChallengeAuthModal isOpen={isSmsChallangeCreationTriggered} />
    </AuthPageWrapper>
  );
};
