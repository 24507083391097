import { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import { Modal, ModalProps } from 'sb-ui-components';
import { APP_LAYOUT_ROOT_WRAPPER_ID } from './app/app';

export interface ModalWrapperProps {
  modalProps: ModalProps;
  isOpen: boolean;
}

export const ModalWrapper: FunctionComponent<ModalWrapperProps> = props => {
  const { isOpen, modalProps, children } = props;

  return isOpen
    ? createPortal(<Modal {...modalProps}>{children}</Modal>, document.getElementById(APP_LAYOUT_ROOT_WRAPPER_ID)!)
    : null;
};
