import { indexCustomerMobileNumber } from '../customer-mobile-number/sagas/customer-mobile-number-index';
import { ModuleConfig } from './../../models/module';
import CustomerShowPage from './components/customer-show-page';
import { CUSTOMER_RESOURCE_NAME } from './models/customer';

export const customerModuleConfig: ModuleConfig = {
  moduleName: CUSTOMER_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    'customer.show': {
      path: '/profile',
      component: CustomerShowPage,
      saga: indexCustomerMobileNumber,
    },
  },
};
