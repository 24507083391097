import { ResourceConfig } from 'src/types';

export const REQUEST_SUPPORT_RESOURCE_NAME = 'requestSupport';
export const CREATE_REQUEST_SUPPORT_ENDPOINT = 'create';

export interface RequestSupportTicket {
  subject: string;
  created_at: string;
  description: string;
  id: number;
  status: string;
  submitter_id: number;
}

export const requestSupportSubjectOptions = [
  {
    label: 'requestSupport.subject.accountManagement',
    value: 'Account Management',
  },
  {
    label: 'requestSupport.subject.cardManagement',
    value: 'Card Management',
  },
  {
    label: 'requestSupport.subject.paymentTransferWithdrawals',
    value: 'Payments, Transfers and Withdrawals',
  },
  {
    label: 'requestSupport.subject.personalDetails',
    value: 'Personal Details',
  },
  {
    label: 'requestSupport.subject.statements',
    value: 'Statements',
  },
  {
    label: 'requestSupport.subject.seizures',
    value: 'Seizures',
  },
  {
    label: 'requestSupport.other',
    value: 'Other',
  },
];

export const requestSupportSubTopicOptionsMap = {
  'Account Management': [
    {
      label: 'requestSupport.subTopic.accountManagement.accountClosure',
      value: 'Account Closure',
    },
    {
      label: 'requestSupport.subTopic.accountManagement.changeOfLinkedAccount',
      value: 'Change of Linked Account',
    },
    {
      label: 'requestSupport.other',
      value: 'Other',
    },
  ],
  'Card Management': [
    {
      label: 'requestSupport.subTopic.cardManagement.chargeback',
      value: 'Chargeback',
    },
    {
      label: 'requestSupport.subTopic.cardManagement.cardBlock',
      value: 'Card Block',
    },
    {
      label: 'requestSupport.subTopic.cardManagement.cardIssueOrDecline',
      value: 'Card Issue/Decline',
    },
    {
      label: 'requestSupport.other',
      value: 'Other',
    },
  ],
  'Payments, Transfers and Withdrawals': [
    {
      label: 'requestSupport.subTopic.paymentTransferWithdrawals.payoutRequest',
      value: 'Payout Request',
    },
    {
      label: 'requestSupport.subTopic.paymentTransferWithdrawals.transactionInvestigation',
      value: 'Transaction Investigation',
    },
    {
      label: 'requestSupport.subTopic.paymentTransferWithdrawals.sddRecall',
      value: 'SDD Recall',
    },
    {
      label: 'requestSupport.other',
      value: 'Other',
    },
  ],
  Statements: [
    {
      label: 'requestSupport.subTopic.statements.accountConfirmation',
      value: 'Account Confirmation',
    },
    {
      label: 'requestSupport.subTopic.statements.paymentConfirmation',
      value: 'Payment Confirmation',
    },
    {
      label: 'requestSupport.subTopic.statements.balanceConfirmation',
      value: 'Balance Confirmation',
    },
    {
      label: 'requestSupport.subTopic.statements.bankStatement',
      value: 'Bank Statement',
    },
    {
      label: 'requestSupport.other',
      value: 'Other',
    },
  ],
};

export type RequestSupportSubTopicOptionKeys = keyof typeof requestSupportSubTopicOptionsMap;

export const requestSupportResourceConfig: ResourceConfig = {
  resourceName: REQUEST_SUPPORT_RESOURCE_NAME,
  endpoints: {
    [CREATE_REQUEST_SUPPORT_ENDPOINT]: {
      path: '/zendesk/create-request',
      httpMethod: 'post',
    },
  },
};
