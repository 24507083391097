import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Badge,
  DataList,
  displayValueOrDefault,
  formatIban,
  Header,
  InlineText,
  PageSection,
  TextWithCopyButton,
} from 'sb-ui-components';
import { ListResourceState } from 'src/types';
import {
  REFERENCE_ACCOUNT_RESOURCE_NAME,
  ReferenceAccount,
  referenceAccountStatusColorMap,
} from '../models/reference-account';

export const ReferenceAccountList: FunctionComponent = () => {
  const referenceAccounts: ListResourceState<ReferenceAccount> = useSelector(
    (state: any) => state.list[REFERENCE_ACCOUNT_RESOURCE_NAME],
  );

  const { formatMessage } = useIntl();

  if (!referenceAccounts) return null;

  return (
    <PageSection>
      <Header dataCy="header">
        <h2>{formatMessage({ id: 'title.referenceAccounts' })}</h2>
      </Header>
      <DataList
        list={referenceAccounts.list}
        errorMessage={referenceAccounts.error && formatMessage({ id: 'error.loading' })}
        isLoading={referenceAccounts.isLoading}
        noValuePlaceHolder={formatMessage({ id: 'message.noReferenceAccountsFound' })}
        leadingContent={(item: ReferenceAccount) => (
          <InlineText nowrap type="div">
            <TextWithCopyButton contentToRender={formatIban(item.attributes.iban)} textToCopy={item.attributes?.iban} />
          </InlineText>
        )}
        leadingSubline={(item: ReferenceAccount) => (
          <InlineText nowrap type="div" size="xsmall" color="soft">
            {`${formatMessage({ id: `label.mandateNumber` })} ${displayValueOrDefault(item.attributes.mandate_number)}`}
          </InlineText>
        )}
        trailingContent={(item: ReferenceAccount) => (
          <div>
            <Badge
              size="small"
              message={formatMessage({
                id: `status.${item.attributes?.status}`,
                defaultMessage: item.attributes?.status,
              })}
              color={referenceAccountStatusColorMap[item.attributes.status]}
            />
          </div>
        )}
      />
    </PageSection>
  );
};
