import { Balance } from 'sb-ui-components';
import { Enum } from 'typescript-string-enums';
import { ResourceConfig } from '../../../types';

export const accountReservationStatuses = Enum('OPEN', 'RESOLVED');
export type AccountReservationStatus = Enum<typeof accountReservationStatuses>;

export const accountReservationTypes = Enum(
  'CARD_AUTHORIZATION',
  'SEIZURE',
  'SEPA_CREDIT_TRANSFER',
  'SEPA_INSTANT_CREDIT_TRANSFER',
  'OTHER',
);
export type AccountReservationType = Enum<typeof accountReservationTypes>;

export const accountReservationCategories = Enum(
  'OTHER',
  'MISC_SERVICES',
  'AUTHORITIES',
  'MOBILITY',
  'HOTEL',
  'MEDICAL',
  'TRAVEL_AGENCY',
  'PERSONAL_SERVICES',
  'SHOPPING',
  'GROCERY_STORE',
  'FUEL_STATION',
  'RESTAURANT',
  'PAYMENT',
  'ENTERTAINMENT',
  'AUTOMOBILE_ASSOCIATION',
  'AIR_TRAVEL',
  'CAR_RENTAL',
  'CAR_AND_ACCESSORIES',
);
export type AccountReservationCategory = Enum<typeof accountReservationCategories>;

//  ToDo: This interface is in WIP state. The endpoints are not final yet.

export interface AccountReservationAttributes {
  readonly status: AccountReservationStatus;
  readonly reservation_type: AccountReservationType;
  readonly amount: Balance;
  readonly original_amount: Balance;
  readonly fx_rate: number;
  readonly created_at: string;
  readonly resolved_at: string;
  readonly transacted_at: string;
  readonly note: string;
  readonly category: AccountReservationCategory;
  readonly counterparty_name: string;
}

export interface AccountReservation {
  readonly id: string;
  readonly type: 'reservation';
  readonly relationships?: any;
  readonly attributes: AccountReservationAttributes;
}

export const ACCOUNT_RESERVATION_RESOURCE_NAME = 'account-reservation';

export const accountReservationResourceConfig: ResourceConfig = {
  resourceName: ACCOUNT_RESERVATION_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/accounts/{accountId}/reservations',
      httpMethod: 'get',
    },
  },
};
