import { PayloadAction } from 'src/types';

export const CREATE_SMS_CHALLENGE = 'smsChallenge/CREATE';
export const CONFIRM_SMS_CHALLENGE = 'smsChallenge/CONFIRM';

// Create SMS challenge

export interface CreateSmsChallengeActionPayload {
  challengeRequestId: string;
  customerId: string;
}

export type CreateSmsChallengeAction = PayloadAction<typeof CREATE_SMS_CHALLENGE, CreateSmsChallengeActionPayload>;

export const createSmsChallengeAction = (payload: CreateSmsChallengeActionPayload): CreateSmsChallengeAction => ({
  type: CREATE_SMS_CHALLENGE,
  payload,
});

// Confirm SMS challenge

export interface ConfirmSmsChallengeActionPayload {
  formValues: { confirmation_value: string };
  pathParams: { challengeId: string };
}

export type ConfirmSmsChallengeAction = PayloadAction<typeof CONFIRM_SMS_CHALLENGE, ConfirmSmsChallengeActionPayload>;

export const confirmSmsChallengeAction = (payload: ConfirmSmsChallengeActionPayload): ConfirmSmsChallengeAction => ({
  type: CONFIRM_SMS_CHALLENGE,
  payload,
});
