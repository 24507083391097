import { Notification } from 'sb-ui-components';
import {
  ClearNotificationAction,
  CreateNotificationAction,
  NOTIFICATION_CLEAR_ACTION,
  NOTIFICATION_CREATE_ACTION,
} from './notification-actions';

export type NotificationState = Notification[];
export type NotificationAction = CreateNotificationAction | ClearNotificationAction;

export const DEFAULT_NOTIFICATION_STATE: NotificationState = [];

export const notifications = (
  state: NotificationState = DEFAULT_NOTIFICATION_STATE,
  action: NotificationAction,
): NotificationState => {
  switch (action.type) {
    case NOTIFICATION_CREATE_ACTION: {
      const {
        payload: { notification },
      } = action;

      return state.concat([notification as Notification]);
    }
    case NOTIFICATION_CLEAR_ACTION: {
      const {
        payload: { id },
      } = action;

      return state.filter(notification => notification.id !== id);
    }
    default:
      return state;
  }
};
