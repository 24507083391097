import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DataPresenter,
  formatBalance,
  InlineText,
  ItemGroup,
  ModalProps,
  ModalSection,
} from 'sb-ui-components';
import { ModalWrapper } from 'src/components/modal-wrapper';
import { Account } from '../models/account';

export interface AccountBalanceModalProps extends ModalProps {
  isOpen: boolean;
  account: Account;
}

export const AccountBalanceModal: FunctionComponent<AccountBalanceModalProps> = props => {
  const { account, isOpen, onClose } = props;
  const { formatMessage } = useIntl();

  return (
    <ModalWrapper
      isOpen={isOpen}
      modalProps={{ maxWidth: 'small', headerTitle: formatMessage({ id: 'title.accountBalance' }), onClose }}
    >
      <DataPresenter
        data={[
          {
            value: (
              <ItemGroup justifyItems="space-between" alignItems="baseline">
                <div>
                  <InlineText size="medium">{formatMessage({ id: 'label.availableBalance' })}</InlineText>
                  <InlineText type="div" size="xsmall" color="soft">
                    {formatMessage({ id: 'message.inclOverdraftCredit' })}
                  </InlineText>
                </div>
                <InlineText size="medium" type="div" weight="medium">
                  {formatBalance(account?.attributes?.available_balance)}
                </InlineText>
              </ItemGroup>
            ),
          },
          {
            value: (
              <ItemGroup justifyItems="space-between" alignItems="baseline">
                <InlineText size="medium">{formatMessage({ id: 'label.reservedBalance' })}</InlineText>
                <InlineText size="medium" type="em">
                  {formatBalance(account?.attributes?.reserved_balance)}
                </InlineText>
              </ItemGroup>
            ),
          },
        ]}
      />
      <ModalSection>
        <ItemGroup justifyItems="center">
          <Button size="big" handleClick={onClose}>
            &nbsp;{formatMessage({ id: 'button.close' })}&nbsp;
          </Button>
        </ItemGroup>
      </ModalSection>
    </ModalWrapper>
  );
};
