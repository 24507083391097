import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { customerMobileNumberResourceConfig } from 'src/modules/customer-mobile-number/models/customer-mobile-number';
import { resourceIndexAndStoreToState } from 'src/sagas/resource-requests';

export function* indexCustomerMobileNumber(): SagaIterator<void> {
  yield call(resourceIndexAndStoreToState, {
    resourceConfig: customerMobileNumberResourceConfig,
    params: {
      pathParams: {},
      queryParams: {},
    },
  });
}
