import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonLink, TextInput } from 'sb-ui-components';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { LOGIN_ROUTE } from 'src/modules/login';
import { SmsChallengeAuthModal } from 'src/modules/sms-challenge-auth/components';
import {
  SMS_CHALLENGE_AUTH_CONFIRMATION_STATE,
  SMS_CHALLENGE_AUTH_RESOURCE_NAME,
} from 'src/modules/sms-challenge-auth/model/sms-challenge-auth';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { AuthPageWrapper } from '../../../components/auth-wrapper';
import { alphanumeric, isPresent, maxLength, minLength, noEmail } from '../../../lib/validation';
import { resetEntityStateAction } from '../../../redux/entity/entity-actions';
import { CredentialCreateFormValues, CREDENTIALS_RESOURCE_NAME } from '../models/credentials-create';
import { createCredentialsAction } from '../redux/credentials-create-actions';
import styles from 'src/components/auth-wrapper/auth-wrapper.module.scss';

const CredentialCreatePage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const entityState: EntityState = useSelector((state: AppState) => state.entity);

  // Create credentials state
  const createCredentialsState: Entity<any> | undefined = entityState?.[CREDENTIALS_RESOURCE_NAME];
  const isCreateCredentialsPending = createCredentialsState?.loading;
  const createCredentialsError = createCredentialsState?.error;

  // Token expired check
  const isTokenExpired = [401, 403].includes(createCredentialsError?.status || 0);

  // Challenge creation state
  const smsChallengeAuthState: Entity<any> | undefined = entityState?.[SMS_CHALLENGE_AUTH_RESOURCE_NAME];
  const isSmsChallangeCreationTriggered = Boolean(smsChallengeAuthState);

  // Challenge confirmation state
  const smsChallengeAuthConfirmationState: Entity<any> | undefined =
    entityState?.[SMS_CHALLENGE_AUTH_CONFIRMATION_STATE];
  const isChallengeConfirmationSuccess = Boolean(smsChallengeAuthConfirmationState?.data);

  // form
  const { getValues, handleSubmit, errors, register } = useForm<CredentialCreateFormValues>({ mode: 'onSubmit' });

  const onSubmit = (formValues: CredentialCreateFormValues) => {
    const { username, password } = formValues;
    dispatch(createCredentialsAction({ username, password }));
  };

  if (isChallengeConfirmationSuccess) {
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="success"
          title={formatMessage({ id: 'success.credentials.title' })}
          message={formatMessage({ id: 'success.credentials.message' })}
          cta={
            <ButtonLink
              size="big"
              trailingIcon="ArrowRightRegular"
              href={createPathByRouteName(LOGIN_ROUTE)}
              data-cy="cta__login"
            >
              {formatMessage({ id: 'button.goToLogin' })}
            </ButtonLink>
          }
        />
      </AuthPageWrapper>
    );
  }

  if (isTokenExpired) {
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.tokenExpired.title' })}
          message={formatMessage({ id: 'error.tokenExpired.message' })}
          cta={
            <ButtonLink
              size="big"
              trailingIcon="ArrowRightRegular"
              action={() => {
                dispatch(resetEntityStateAction({ entityName: CREDENTIALS_RESOURCE_NAME }));
              }}
              dataCy="cta__modal_button_confirm"
              href={createPathByRouteName('emailVerification.verify')}
            >
              {formatMessage({ id: 'button.verifyAgain' })}
            </ButtonLink>
          }
        />
      </AuthPageWrapper>
    );
  }

  if (Boolean(createCredentialsError)) {
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.general.title' })}
          message={formatMessage({ id: 'error.general.message' })}
          cta={
            <Button
              size="big"
              handleClick={() => dispatch(resetEntityStateAction({ entityName: CREDENTIALS_RESOURCE_NAME }))}
            >
              {formatMessage({ id: 'button.tryAgain' })}
            </Button>
          }
        />
      </AuthPageWrapper>
    );
  }

  return (
    <AuthPageWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h1>{formatMessage({ id: 'title.setCredentials' })}</h1>
        <p>{formatMessage({ id: 'message.setCredentials' })}</p>
        <TextInput
          id="username"
          label={formatMessage({ id: 'label.username' })}
          register={register}
          disabled={isCreateCredentialsPending}
          validation={{
            validate: {
              isPresent: value => isPresent(value) || formatMessage({ id: 'inputError.required' }),
              noEmail: value => noEmail(value) || formatMessage({ id: 'inputError.emailNotAllowed' }),
              alphanumeric: value => alphanumeric(value) || formatMessage({ id: 'inputError.alphanumeric' }),
              minLength: value => minLength(value, 6) || formatMessage({ id: 'inputError.minLength' }, { length: 6 }),
              maxLength: value =>
                maxLength(value, 128) || formatMessage({ id: 'inputError.maxLength' }, { length: 128 }),
            },
          }}
          errorMessage={errors.username?.message}
        />
        <TextInput
          id="password"
          type="password"
          label={formatMessage({ id: 'label.password' })}
          register={register}
          disabled={isCreateCredentialsPending}
          validation={{
            minLength: { value: 8, message: formatMessage({ id: 'inputError.password.length' }) },
            validate: { required: value => Boolean(value) || formatMessage({ id: 'inputError.password.required' }) },
          }}
          errorMessage={errors.password?.message}
        />
        <TextInput
          id="confirm_password"
          type="password"
          label={formatMessage({ id: 'label.confirmPassword' })}
          register={register}
          disabled={isCreateCredentialsPending}
          validation={{
            validate: {
              equal: value => value === getValues('password') || formatMessage({ id: 'inputError.password.match' }),
            },
          }}
          errorMessage={errors.confirm_password?.message}
        />
        <br />
        <Button
          dataCy="cta__create_account"
          className={styles.button}
          type="submit"
          size="big"
          isLoading={isCreateCredentialsPending}
        >
          {formatMessage({ id: 'button.createAccount' })}
        </Button>
      </form>

      {/* SMS 2FA modal */}
      <SmsChallengeAuthModal isOpen={isSmsChallangeCreationTriggered} />
    </AuthPageWrapper>
  );
};

export default CredentialCreatePage;
