import { Message, useForm, Validate, ValidationRule } from 'react-hook-form';
import { Action, AnyAction } from 'redux';
import { PathParams, QueryParams } from './lib/route-config-helper';
import { AuthState } from './redux/auth/auth-reducer';
import { BootState } from './redux/boot/boot-reducer';
import { EntityState } from './redux/entity/entity-reducer';
import { ListState } from './redux/list/list-reducer';
import { NotificationState } from './redux/notification/notification-reducer';
import { ProgressBarState } from './redux/progress-bar/progress-bar-reducer';
import { UiState } from './redux/ui/ui-reducer';
export interface AppState {
  ui: UiState;
  auth: AuthState;
  boot: BootState;
  entity: EntityState;
  list: ListState;
  notifications: NotificationState;
  progressBar: ProgressBarState;
}

export interface ResourceResponseMeta {
  total?: number;
  queryParams: {};
  pathParams: {};
}

// Error types
export type APIErrorStatus = 400 | 401 | 403 | 404 | 405 | 409 | 422;

export type ServerErrorStatus = APIErrorStatus | 500 | 501 | 503;

export interface ResourceError {
  code: string;
  detail: string;
  id: string;
  status: APIErrorStatus;
  title: string;
  source?: {
    field: string;
    message: string;
  };
}

export interface ServerErrorResponse {
  apiErrors?: ResourceError[];
  message?: string;
  status: ServerErrorStatus;
}

export interface ResourceResponse<Data> {
  data?: Data;
  meta: ResourceResponseMeta;
  error?: ServerErrorResponse;
  status?: number;
}

export interface AuthenticationErrorPayload {
  error: string;
  error_description: string;
}
export interface AuthenticationSuccessPayload {
  access_token: string;
  expires_in: number;
}

export type NullableString = string | null;
export type NullableBoolean = boolean | null;

export enum AuthenticationMessageStatus {
  PRISTINE,
  LOGGED_OUT,
  WRONG_CREDENTIALS,
  LOGGED_OUT_FOR_INACTIVITY,
  NETWORK_INVALID,
  GENERIC_ERROR,
}

export interface ListResourceState<Entity> {
  list?: Entity[];
  total?: number;
  queryParams?: Record<string, string | number | boolean>;
  pathParams?: Record<string, string | number>;
  isLoading?: boolean;
  error?: ServerErrorResponse;
}

export interface RouterHookParams {
  queryParams: QueryParams;
  pathParams: PathParams;
  routeName?: string;
}

export interface ListElementUiState {
  readonly selected?: boolean;
  readonly expanded?: boolean;
  readonly checked?: boolean;
}

export type HttpMethod = 'get' | 'patch' | 'post' | 'delete' | 'put';

export interface EndpointConfig {
  path: string;
  httpMethod: HttpMethod;
  requiredAction?: string; // make it optional - we probably do not need it in solaris-go anyway
}
export interface ResourceConfig {
  resourceName: string;
  endpoints: Record<string, EndpointConfig>;
}

export type ValidationType = Partial<{
  required: Message | ValidationRule<boolean>;
  min: ValidationRule<number | string>;
  max: ValidationRule<number | string>;
  maxLength: ValidationRule<number | string>;
  minLength: ValidationRule<number | string>;
  pattern: ValidationRule<RegExp>;
  validate: Validate | Record<string, Validate>;
}>;

export type RegisterType = ReturnType<typeof useForm>['register'];

export type Entity<EntityType> = {
  loading: boolean;
  id?: string; // ToDo: only relevant for the mock server
  error?: ServerErrorResponse;
  data?: EntityType;
};

export class SimpleAction<T extends string> implements Action<T> {
  readonly type: T;
}

export class PayloadAction<T extends string, P> implements AnyAction {
  readonly type: T;
  readonly payload: P;
}
