import { ListResourceState } from '../../types';
import {
  ClearAllListsAction,
  ClearListAction,
  InitializeListAction,
  LIST_CLEAR_ACTION,
  LIST_CLEAR_ALL_ACTION,
  LIST_INITIALIZE_ACTION,
  LIST_SET_LOADING_ACTION,
  LIST_UPDATE_SINGLE_ITEM,
  SetListLoadingAction,
  UpdateListItemAction,
} from './list-actions';

export type ListState = { [resourceName: string]: ListResourceState<unknown> };

export type ListResourceName = keyof ListState;

// Let's keep the initial state empty
export const DEFAULT_LIST_STATE = {};

export const list = (
  state: ListState = DEFAULT_LIST_STATE,
  action: SetListLoadingAction | InitializeListAction | ClearListAction | ClearAllListsAction | UpdateListItemAction,
): ListState => {
  switch (action.type) {
    case LIST_SET_LOADING_ACTION: {
      const { resourceName, isLoading } = action.payload!;
      return {
        ...state,
        [resourceName]: {
          isLoading,
        },
      };
    }
    case LIST_INITIALIZE_ACTION: {
      if (!action.payload) {
        return state;
      }

      const { resourceName, listData } = action.payload;

      return {
        ...state,
        [resourceName]: listData,
      };
    }
    case LIST_CLEAR_ACTION: {
      const { [action.payload!.resourceName]: remove, ...res } = state;
      return {
        ...res,
      };
    }
    case LIST_CLEAR_ALL_ACTION: {
      if (action.payload!.resetFiltersAndPages) {
        return DEFAULT_LIST_STATE;
      } else {
        Object.keys(state).forEach(key => {
          state[key].list = undefined;
          state[key].total = undefined;
        });
        return state;
      }
    }
    case LIST_UPDATE_SINGLE_ITEM: {
      const { payload } = action;
      const { resourceName, id, data } = payload;
      const list = state[resourceName];
      if (!list || !data) return state;
      // Update only the item with the given id and only the fields given by data
      // All other fields and all other list items should remain the same
      return {
        ...state,
        [resourceName]: {
          ...list,
          list: list.list?.map((item: any) => (item.id === id ? { ...item, ...data, id } : item)),
        },
      };
    }
    default:
      return state;
  }
};
