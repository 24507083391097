import { PayloadAction } from 'src/types';

export const INDEX_REFERENCE_ACCOUNT_LIST_ACTION = 'referenceAccount/INDEX';

export interface IndexReferenceAccountListActionPayload {
  accountId: string;
}

export type IndexReferenceAccountListAction = PayloadAction<
  typeof INDEX_REFERENCE_ACCOUNT_LIST_ACTION,
  IndexReferenceAccountListActionPayload
>;

export const indexReferenceAccountListAction = (
  payload: IndexReferenceAccountListActionPayload,
): IndexReferenceAccountListAction => ({
  type: INDEX_REFERENCE_ACCOUNT_LIST_ACTION,
  payload,
});
