import { ModuleConfig } from './../../models/module';
import LoginPage from './components/login-page';
import { LogoutPage } from './components/logout-page';
import { LOGIN_RESOURCE_NAME } from './models/login';
import {
  listenToConfirmCognitoSmsChallenge,
  listenToLoginWithAmplifySubmit,
  listenToLogoutRequest,
  loginPageEnter,
  logoutPageEnter,
} from './sagas';

export const LOGIN_ROUTE = 'authenticateWithAmplify.loginForm';
export const LOGOUT_ROUTE = 'authenticateWithAmplify.logout';

export const loginModuleConfig: ModuleConfig = {
  moduleName: LOGIN_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToLoginWithAmplifySubmit, listenToConfirmCognitoSmsChallenge, listenToLogoutRequest],
  routes: {
    [LOGIN_ROUTE]: {
      path: '/login',
      component: LoginPage,
      skipAuthentication: true,
      saga: loginPageEnter,
    },

    [LOGOUT_ROUTE]: {
      path: '/logout',
      component: LogoutPage,
      skipAuthentication: true,
      /*
       * TODO remove page enter handler when SidebarMenuItem can have a click handler
       * without requiring sub menu items
       */
      saga: logoutPageEnter,
    },
  },
};
