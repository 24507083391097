import { FunctionComponent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, ItemGroup, PageTitle, Switch } from 'sb-ui-components';
import { PageWrapper } from 'src/components/page-wrapper';
import { ListResourceState } from 'src/types';
import { Customer3dsListItem } from '../../customer-3ds/components/customer-3ds-list-item/customer-3ds-list-item';
import { CUSTOMER_3DS_RESOURCE_NAME, Customer3ds } from '../../customer-3ds/models/customer-3ds';
import { AccountList } from './account-list';

const AccountListPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  const customer3ds: ListResourceState<Customer3ds> = useSelector(
    (state: any) => state.list[CUSTOMER_3DS_RESOURCE_NAME],
  );

  const [isOnlyActiveAccounts, setIsOnlyActiveAccounts] = useState<boolean>(true);

  const title = formatMessage({ id: 'title.accounts' });

  return (
    <PageWrapper title={title}>
      {customer3ds?.list && customer3ds.list.length > 0 && (
        <>
          <ItemGroup fullwidth>
            {customer3ds.list.map((item: Customer3ds) => (
              <Customer3dsListItem key={item.id} customer3ds={item} />
            ))}
          </ItemGroup>
          <br />
          <br />
        </>
      )}
      <Header>
        <PageTitle text={title} />
        <Switch
          dataCy="cta_active_accounts_switch"
          name="only-active-accounts"
          size="small"
          onChange={() => setIsOnlyActiveAccounts(!isOnlyActiveAccounts)}
          label={{
            content: <FormattedMessage id="label.showOnlyActiveAccounts" />,
            position: 'before',
          }}
          checked={isOnlyActiveAccounts}
        />
      </Header>
      <AccountList showOnlyActiveAccounts={isOnlyActiveAccounts} />
    </PageWrapper>
  );
};

export default AccountListPage;
