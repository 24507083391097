import { ResourceConfig } from 'src/types';
import { Enum } from 'typescript-string-enums';

export interface CardEncryptionKey {
  id: string;
  type: 'encryption_key';
  attributes: {
    purpose: 'PIN_CHANGE';
    jwk: {
      kty: 'RSA'; // The key type
      use?: 'enc'; // The public key use
      alg?: 'RS256'; // The algorithm to use for encryption
      kid?: string; // The key ID
      n: string; // Modulus (for RSA keys)
      e: string; // Exponent (for RSA keys)
    };
  };
}

export interface CardChangePinFormValues {
  pin: string;
  confirmed_pin: string;
}

export const cardPinErrorMessages = Enum('THREE_EQUAL_DIGITS_IN_ROW', 'SEQUENTIAL_DIGITS', 'CONFIRMATION_MISSMATCH');
export type CardPinErrorMessage = Enum<typeof cardPinErrorMessages>;

// Endpoints
export const CARD_GET_ENCRYPTION_KEY_ENDPOINT = 'card/get/encryptionKey';
export const CARD_POST_CHANGE_PIN_ENDPOINT = 'card/post/pin';

// Resource name
export const CARD_CHANGE_PIN_RESOURCE_NAME = 'accountCardChangePin';

export const cardChangePinResourceConfig: ResourceConfig = {
  resourceName: CARD_CHANGE_PIN_RESOURCE_NAME,
  endpoints: {
    [CARD_GET_ENCRYPTION_KEY_ENDPOINT]: {
      path: '/cards/{cardId}/encryption-keys',
      httpMethod: 'get',
    },
    [CARD_POST_CHANGE_PIN_ENDPOINT]: {
      path: '/cards/{cardId}/pin-change-requests',
      httpMethod: 'post',
    },
  },
};
