import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BalanceCell, DataList, EN_DASH, formatApiDate, InlineText } from 'sb-ui-components';
import { ListResourceState } from '../../../types';
import { ACCOUNT_RESERVATION_RESOURCE_NAME, AccountReservation } from '../models/account-reservation';

const AccountReservationList: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  const accountReservations: ListResourceState<AccountReservation> = useSelector(
    (state: any) => state.list[ACCOUNT_RESERVATION_RESOURCE_NAME],
  );

  if (!accountReservations?.list?.length) return null;

  return (
    <DataList
      list={accountReservations.list}
      noValuePlaceHolder={formatMessage({ id: 'message.noTransactionsFound' })}
      leadingContent={(item: AccountReservation) => (
        <InlineText nowrap type="div">
          {item.attributes.counterparty_name}
        </InlineText>
      )}
      leadingSubline={(item: AccountReservation) => (
        <InlineText nowrap type="div" size="xsmall" color="soft">
          {formatApiDate(item.attributes.created_at)}
          {` ${EN_DASH} `}
          <em>{formatMessage({ id: 'label.reserved' })}</em>
        </InlineText>
      )}
      trailingContent={(item: AccountReservation) => <BalanceCell data={item.attributes.amount} swapColorCoding />}
      leadingIcon={() => 'ClockLight'}
    />
  );
};

export default AccountReservationList;
