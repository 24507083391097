export const DOWNLOAD_POSTBOX_ACTION = 'postbox/DOWNLOAD';

export interface DownloadPostboxPayload {
  readonly fileName: string;
  readonly id: string;
}

export interface DownloadPostboxActionPayload {
  readonly type: typeof DOWNLOAD_POSTBOX_ACTION;
  readonly payload: DownloadPostboxPayload;
}

export function downloadPostboxAction(payload: DownloadPostboxPayload): DownloadPostboxActionPayload {
  return {
    type: DOWNLOAD_POSTBOX_ACTION,
    payload,
  };
}
