import { put } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { setAuthStateAction, storeAuthSmsChallengeRequestIdAction } from 'src/redux/auth/auth-actions';

export function* showPageEnterPhoneVerification(params: ApiRequestParams) {
  const { token, challenge_request_id } = params.queryParams;
  const isAuthorized = false;
  yield put(setAuthStateAction({ isAuthorized, idToken: String(token) }));
  yield put(storeAuthSmsChallengeRequestIdAction({ challengeRequestId: String(challenge_request_id) }));
}
