import {
  PROGRESS_FINISH_LOADING_ACTION,
  PROGRESS_START_LOADING_ACTION,
  ProgressFinishAction,
  ProgressStartAction,
} from './progress-bar-actions';

export interface ProgressBarState {
  isProgressLoading: boolean;
}

export const DEFAULT_PROGRESSBAR_STATE: ProgressBarState = {
  isProgressLoading: false,
};

export const progressBar = (
  state: ProgressBarState = DEFAULT_PROGRESSBAR_STATE,
  action: ProgressStartAction | ProgressFinishAction,
) => {
  switch (action.type) {
    case PROGRESS_START_LOADING_ACTION:
      state.isProgressLoading = true;
      return { ...state };
    case PROGRESS_FINISH_LOADING_ACTION:
      state.isProgressLoading = false;
      return { ...state };
    default:
      return state;
  }
};
