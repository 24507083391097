import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Customer } from 'src/modules/customer/models/customer';
import { clearListAction } from 'src/redux/list/list-actions';
import { AppState } from 'src/types';
import { resourceIndexAndStoreToState } from '../../../../sagas/resource-requests';
import { CUSTOMER_3DS_RESOURCE_NAME, customer3dsResourceConfig } from '../../models/customer-3ds';
import { FETCH_CUSTOMER_3DS_ACTION } from '../../redux/customer-3ds-actions';

export function* indexCustomer3dsList(): SagaIterator<void> {
  yield put(clearListAction({ resourceName: CUSTOMER_3DS_RESOURCE_NAME }));
  const customerData: Customer = yield select((state: AppState) => state.entity?.customer?.data);

  yield call(resourceIndexAndStoreToState, {
    resourceConfig: customer3dsResourceConfig,
    params: {
      pathParams: { customerId: customerData.id },
      queryParams: {},
    },
  });
}

export function* listenToFetchCustomer3ds(): SagaIterator<void> {
  yield takeEvery(FETCH_CUSTOMER_3DS_ACTION, handleFetchCustomer3ds);
}

export function* handleFetchCustomer3ds(): SagaIterator<void> {
  yield call(indexCustomer3dsList);
}
