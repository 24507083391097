import { ResourceConfig } from '../../../types';

export const LOGIN_RESOURCE_NAME = 'loginFlow';

export const LOGIN_ENDPOINT_REQUEST_TOKEN = 'requestToken';
export const LOGIN_ENDPOINT_OAUTH_INFO = 'tokenInfo';
export const LOGIN_ENDPOINT_INIT = 'login';
export const LOGOUT_ENDPOINT = 'logout';

export interface Token {
  expires_at: number;
}

export interface TokenInfo {
  user_id: string;
}
export interface RequestLoginUrlSuccessPayload {
  oauth_authorization_url: string;
}

export interface LoginFormValues {
  password: string;
  username: string;
}

const loginResourceConfig: ResourceConfig = {
  resourceName: LOGIN_RESOURCE_NAME,
  endpoints: {
    [LOGIN_ENDPOINT_REQUEST_TOKEN]: {
      path: '/auth/iam-request-token',
      httpMethod: 'post',
    },
    [LOGIN_ENDPOINT_INIT]: {
      path: '/auth/login',
      httpMethod: 'post',
    },
    [LOGOUT_ENDPOINT]: {
      path: '/auth/logout',
      httpMethod: 'post',
    },
    [LOGIN_ENDPOINT_OAUTH_INFO]: {
      path: '/auth/oauth2/info',
      httpMethod: 'get',
    },
  },
};

export default loginResourceConfig;
