import { ResourceConfig } from '../../../types';

export const FORGOT_PASSWORD_RESOURCE_NAME = 'forgotPassword';
export const REQUEST_PASSWORD_RESET_STATE = 'requestPasswordReset';
export const CONFIRM_PASSWORD_STATE = 'confirmPassword';

export const FORGOT_PASSWORD_ENDPOINT = 'forgotPassword/REQUEST';
export const CONFIRM_RESET_PASSWORD_ENDPOINT = 'forgotPassword/CONFIRM';

export const PASSWORD_MIN_LENGTH = 8;

const forgotPasswordResourceConfig: ResourceConfig = {
  resourceName: FORGOT_PASSWORD_RESOURCE_NAME,
  endpoints: {
    [FORGOT_PASSWORD_ENDPOINT]: {
      path: '/auth/forgot-password',
      httpMethod: 'post',
    },
    [CONFIRM_RESET_PASSWORD_ENDPOINT]: {
      path: '/auth/confirm-forgot-password',
      httpMethod: 'post',
    },
  },
};

export default forgotPasswordResourceConfig;
