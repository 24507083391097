import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, ItemGroup, TextInput, TextWithIcon } from 'sb-ui-components';
import { AuthPageWrapper } from 'src/components/auth-wrapper';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { LOGIN_ROUTE } from 'src/modules/login';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { isPresent, noEmail } from '../../../lib/validation';
import { REQUEST_PASSWORD_RESET_STATE } from '../models/forgot-password';
import { forgotPasswordRequestAction } from '../redux/forgot-password-actions';
import styles from './forgot-password.module.scss';

const ForgotPasswordPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { handleSubmit, errors, register } = useForm<{ username: string }>({ mode: 'onSubmit' });

  const entityState: EntityState = useSelector((state: AppState) => state.entity);
  const requestPasswordResetState: Entity<any> | undefined = entityState?.[REQUEST_PASSWORD_RESET_STATE];
  const isRequestPasswordResetLoading = requestPasswordResetState?.loading;
  const isRequestPasswordResetSuccess = requestPasswordResetState?.data;
  const isRequestPasswordResetError = requestPasswordResetState?.error;

  const onSubmit = (formValues: { username: string }) => {
    dispatch(forgotPasswordRequestAction(formValues));
  };

  if (isRequestPasswordResetSuccess)
    return (
      <AuthPageWrapper>
        <h1>{formatMessage({ id: 'title.checkEmails' })}</h1>
        <p>{formatMessage({ id: 'message.forgotPasswordRequested' })}</p>
      </AuthPageWrapper>
    );

  if (isRequestPasswordResetError) {
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.general.title' })}
          message={formatMessage({ id: 'error.general.message' })}
          cta={
            <Button
              handleClick={() => dispatch(resetEntityStateAction({ entityName: REQUEST_PASSWORD_RESET_STATE }))}
              dataCy="cta__back_to_login"
              size="big"
              trailingIcon="ArrowRightRegular"
            >
              {formatMessage({ id: 'button.tryAgain' })}
            </Button>
          }
        />
      </AuthPageWrapper>
    );
  }

  return (
    <AuthPageWrapper>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h1>{formatMessage({ id: 'title.resetPassword' })}</h1>
        <p>{formatMessage({ id: 'message.forgotPassword' })}</p>
        <TextInput
          id="username"
          label={formatMessage({ id: 'label.username' })}
          register={register}
          validation={{
            validate: {
              isPresent: value => isPresent(value) || formatMessage({ id: 'inputError.required' }),
              noEmail: value => noEmail(value) || formatMessage({ id: 'inputError.notEmail' }),
            },
          }}
          errorMessage={errors.username?.message}
          disabled={isRequestPasswordResetLoading}
        />
        <br />
        <ItemGroup fullwidth gutterY="large" alignItems="start">
          <Button
            dataCy="cta__request_password_reset"
            type="submit"
            size="big"
            className={styles.submitButton}
            isLoading={isRequestPasswordResetLoading}
          >
            {formatMessage({ id: 'button.requestPasswordReset' })}
          </Button>
          <Link to={createPathByRouteName(`${[LOGIN_ROUTE]}`)} data-cy="cta__back_to_login" className={styles.textLink}>
            <TextWithIcon icon="ArrowLeftRegular" gutter="none" text={formatMessage({ id: 'button.backToLogin' })} />
          </Link>
        </ItemGroup>
      </form>
    </AuthPageWrapper>
  );
};

export default ForgotPasswordPage;
