import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  resetEntityStateAction,
} from '../../../../redux/entity/entity-actions';
import { resourceRequest } from '../../../../sagas/resource-requests';
import { createSmsChallengeAction } from '../../../sms-challenge/redux/sms-challenge-actions';
import {
  CUSTOMER_3DS_APPROVE_ENDPOINT,
  CUSTOMER_3DS_APPROVE_STATE,
  customer3dsResourceConfig,
  NotificationAction,
} from '../../models/customer-3ds';
import { APPROVE_CUSTOMER_3DS_ACTION, ApproveCustomer3dsAction } from '../../redux/customer-3ds-actions';

export function* listenToApproveCustomer3ds(): SagaIterator<void> {
  yield takeEvery(APPROVE_CUSTOMER_3DS_ACTION, handleApproveCustomer3ds);
}

export function* approveCustomer3ds(notificationId: string): SagaIterator<void> {
  const requestBody: NotificationAction = {
    data: {
      type: 'notification_action',
      attributes: {
        action: 'APPROVE_3DS',
      },
    },
  };
  return yield call(resourceRequest, {
    resourceConfig: customer3dsResourceConfig,
    endpoint: CUSTOMER_3DS_APPROVE_ENDPOINT,
    apiRequestParams: {
      pathParams: { notificationId },
      queryParams: {},
    },
    requestBody,
  });
}

export function* handleApproveCustomer3ds(action: ApproveCustomer3dsAction): SagaIterator<void> {
  const { notificationId } = action.payload;
  const entityName = `${CUSTOMER_3DS_APPROVE_STATE}_${notificationId}`;

  // Set loading state
  yield put(fetchEntityInitAction({ entityName }));

  // Approve customer 3DS notification
  const approveCustomer3dsResponse = yield call(approveCustomer3ds, notificationId);

  // If request fails set error state and return from this saga
  if (approveCustomer3dsResponse.error) {
    yield put(
      fetchEntityErrorAction({
        entityName,
        error: approveCustomer3dsResponse.error,
      }),
    );
    return;
  }

  // Remove loading state
  yield put(
    resetEntityStateAction({
      entityName,
    }),
  );

  // Trigger SMS challenge
  const customerId = yield select(state => state.entity?.customer?.data?.id);
  const { id: challengeRequestId } = approveCustomer3dsResponse.data;
  yield put(createSmsChallengeAction({ challengeRequestId, customerId }));
}
