import { ModuleConfig } from '../../models/module';
import EmailVerification from './components/email-verification-page';
import { EMAIL_VERIFICATION_RESOURCE_NAME } from './models/email-verification';
import { listenToEmailVerification } from './sagas/email-verification';

export const emailVerificationConfig: ModuleConfig = {
  moduleName: EMAIL_VERIFICATION_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToEmailVerification],
  routes: {
    'emailVerification.verify': {
      path: '/verify-email',
      component: EmailVerification,
      skipAuthentication: true,
    },
  },
};
