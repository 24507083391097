import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import {
  EMAIL_VERIFICATION_ENDPOINT,
  EMAIL_VERIFICATION_RESOURCE_NAME,
  emailVerificationResourceConfig,
} from '../models/email-verification';
import { EMAIL_VERIFICATION_REQUEST_ACTION, EmailVerificationRequestAction } from '../redux/email-verification-actions';

export function* listenToEmailVerification(): SagaIterator<void> {
  yield takeEvery(EMAIL_VERIFICATION_REQUEST_ACTION, handleEmailVerification);
}

export function* handleEmailVerification(action: EmailVerificationRequestAction): SagaIterator<void> {
  const { formValues } = action.payload;
  const { iban, birth_date, mobile_number } = formValues;

  yield put(fetchEntityInitAction({ entityName: EMAIL_VERIFICATION_RESOURCE_NAME }));

  // Email verification request
  const emailVerificationResponse = yield call(resourceRequest, {
    resourceConfig: emailVerificationResourceConfig,
    endpoint: EMAIL_VERIFICATION_ENDPOINT,
    apiRequestParams: {
      pathParams: {},
      queryParams: {},
    },
    requestBody: {
      data: {
        type: 'email_verification',
        attributes: {
          iban,
          birth_date,
          mobile_number,
        },
      },
    },
  });

  // Error
  if (emailVerificationResponse.error) {
    yield put(
      fetchEntityErrorAction({ entityName: EMAIL_VERIFICATION_RESOURCE_NAME, error: emailVerificationResponse.error }),
    );
    return;
  }

  // Success
  yield put(
    fetchEntitySuccessAction({ entityName: EMAIL_VERIFICATION_RESOURCE_NAME, data: emailVerificationResponse.data }),
  );
}

export interface CreateEmailVerificationArgs {
  iban: string;
  birth_date: string;
  mobile_number: string;
}
