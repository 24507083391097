import { PayloadAction } from 'src/types';

export const LOGIN_WITH_AMPLIFY_REQUEST_ACTION = 'auth/LOGIN_AMPLIFY_REQUEST';
export const LOGIN_CONFIRM_SMS_CHALLENGE_ACTION = 'auth/CONFIRM_AMPLIFY_SMS_CHALLENGE';

export interface LoginWithAmplifyPayload {
  readonly username: string;
  readonly password: string;
}

export type LoginWithAmplifyRequestAction = PayloadAction<
  typeof LOGIN_WITH_AMPLIFY_REQUEST_ACTION,
  LoginWithAmplifyPayload
>;

export function loginWithAmplify(payload: LoginWithAmplifyPayload): LoginWithAmplifyRequestAction {
  return {
    type: LOGIN_WITH_AMPLIFY_REQUEST_ACTION,
    payload,
  };
}

export interface ConfirmSmsChallengePayload {
  readonly challenge: string;
}

export type ConfirmSmsChallengeAction = PayloadAction<
  typeof LOGIN_CONFIRM_SMS_CHALLENGE_ACTION,
  ConfirmSmsChallengePayload
>;
export function confirmCognitoSmsChallenge(payload: ConfirmSmsChallengePayload): ConfirmSmsChallengeAction {
  return {
    type: LOGIN_CONFIRM_SMS_CHALLENGE_ACTION,
    payload,
  };
}
