import { CARD_BLOCK_ENDPOINT, CARD_UNBLOCK_ENDPOINT } from "src/modules/card/models/card";

export const en: Record<string, string> = {

  // Account block states
  'accountBlockState.BLOCK': 'Blocked',
  'accountBlockState.CREDIT_BLOCK': 'Credit blocked',
  'accountBlockState.DEBIT_BLOCK': 'Debit blocked',

  // Account types (WIP)
  'accountType.CREDITCARD_PERSONAL': 'Credit Card Personal',
  'accountType.CHECKING_BUSINESS':' Checking Business',
  'accountType.CHECKING_PERSONAL':'Checking Personal',
  'accountType.CHECKING_SOLE_PROPRIETOR': 'Checking Sole Proprietor',
  'accountType.CHECKING_PERSONAL_OVERDRAFT':'Checking Personal Overdraft',
  'accountType.BASIC_ACCOUNT':'Basic Account',
  'accountType.WALLET_PERSONAL':'Wallet Personal',
  'accountType.WALLET_BUSINESS':'Wallet Business',
  'accountType.EMONEY_PREPAID':'Emoney Prepaid',
  'accountType.WALLET_INSTITUTIONAL':'Wallet Institutional',
  'accountType.DECOUPLED_CARD_PERSONAL':'Decoupled Card Personal',
  'accountType.DECOUPLED_CARD_BUSINESS':'Decoupled Card Business',
  'accountType.WALLET_PERSONAL_PAYMENT_ACC':'Wallet Personal Payment',
  'accountType.CREDITCARD_BUSINESS':'Credit Card Business',
  'accountType.CREDITCARD_FREELANCER':'Credit Card Freelancer',
  'accountType.CHECKING_SUBACCOUNT': 'Subaccount',
  'accountType.CALL_MONEY': 'Call Money',
  'accountType.CURRENCY_CUSTOMER_ACCOUNT': 'Currency Customer Account',
  'accountType.SAVINGS_PERSONAL' : 'Savings Personal',
  'accountType.SAVINGS_BUSINESS' : 'Savings Business',
  'accountType.SAVINGS_SOLE_PROPRIETOR': 'Savings Sole Proprietor',

  // Account Reservation Types
  'reservationType.CARD_AUTHORIZATION': 'Card authorization',
  'reservationType.SEIZURE': 'Seizure',
  'reservationType.SEPA_CREDIT_TRANSFER': 'SEPA credit transfer',
  'reservationType.SEPA_INSTANT_CREDIT_TRANSFER': 'SEPA instant credit transfer',
  'reservationType.OTHER': 'Other',

  // Button labels
  'button.actions': 'Actions',
  'button.approve': 'Approve',
  'button.back': 'Back',
  'button.backToLogin': 'Back to login',
  'button.betweenMyAccounts': 'Between my accounts',
  'button.block': 'Block',
  'button.cancel': 'Cancel',
  'button.changePin': 'Change PIN',
  'button.close': 'Close',
  'button.confirm': 'Confirm',
  'button.createAccount': 'Create account',
  'button.decline': 'Decline',
  'button.details': 'Details',
  'button.forgotPassword': 'Forgot password?',
  'button.goToAccounts': 'Go to accounts',
  'button.goToLogin': 'Go to login',
  'button.login': 'Sign in',
  'button.makeTransfer': 'Make transfer',
  'button.next': 'Next',
  'button.ok': 'OK',
  'button.phoneNumberVerification': 'Start verification',
  'button.requestHelp': 'Request help',
  'button.requestPasswordReset': 'Request password reset',
  'button.resendCode': 'Resend code',
  'button.resetPassword': 'Reset password',
  'button.restart': 'Restart',
  'button.send': 'Send',
  'button.sepaTransfer': 'SEPA transfer',
  'button.signOut': 'Sign out',
  'button.toReferenceAccount': 'To reference account',
  'button.transfer': 'Transfer',
  'button.transferNow': 'Transfer now',
  'button.tryAgain': 'Try again',
  'button.unblock': 'Unblock',
  'button.verify': 'Verify',
  'button.verifyAgain': 'Verify again',
  'button.viewMyAccounts': 'View my accounts',

  // Card types
  'cardType.MASTERCARD_BUSINESS_DEBIT': 'Mastercard Debit',
  'cardType.MASTERCARD_CREDIT': 'Mastercard Credit',
  'cardType.MASTERCARD_DEBIT': 'Mastercard Debit',
  'cardType.VIRTUAL_MASTERCARD_BUSINESS_DEBIT': 'Mastercard Debit (virtual)',
  'cardType.VIRTUAL_MASTERCARD_DEBIT': 'Mastercard Debit (virtual)',
  'cardType.VIRTUAL_MASTERCARD_FREELANCE_DEBIT': 'Mastercard Debit (virtual)',
  'cardType.VIRTUAL_VISA_BUSINESS_CREDIT': 'VISA Credit (virtual)',
  'cardType.VIRTUAL_VISA_BUSINESS_DEBIT': 'VISA Debit (virtual)',
  'cardType.VIRTUAL_VISA_CREDIT': 'VISA Credit (virtual)',
  'cardType.VIRTUAL_VISA_DEBIT': 'VISA Debit (virtual)',
  'cardType.VIRTUAL_VISA_FREELANCE_DEBIT': 'VISA Debit (virtual)',
  'cardType.VISA_BUSINESS_CREDIT': 'VISA Credit',
  'cardType.VISA_BUSINESS_DEBIT': 'VISA Debit',
  'cardType.VISA_CREDIT': 'VISA Credit',
  'cardType.VISA_DEBIT': 'VISA Debit',

  // Error messages
  'error.general.message': 'We apologize for the inconvenience. Our team is working on fixing this issue. Please try again in a few minutes.',
  'error.general.title': 'We’re Sorry, something went wrong.',
  'error.loading.account': 'Account failed to load. Please try again later.',
  'error.loading': 'Loading failed. Please try again later.',
  'error.LOGIN_INCORRECT_CREDENTIALS_ERROR': 'Wrong username or password',
  'error.LOGIN_INCORRECT_2FA_ERROR.title': 'Invalid code.',
  'error.LOGIN_INCORRECT_2FA_ERROR.message': 'Please close this dialog and sign in again.',
  'error.tokenExpired.message': 'For security reasons we limit the time frame after your identity verification in which you can create your login information. This time has expired. To continue, please repeat the verification step.',
  'error.tokenExpired.title': 'Time expired',
  
  // Form input errors
  'inputError.alphanumeric': 'Please avoid special characters and umlauts.',
  'inputError.amount.zero': 'Amount must be at least 0.01.',
  'inputError.amount.tooHigh': 'You have only {amount} available.',
  'inputError.emailNotAllowed': 'An email is not allowed as username.',
  'inputError.invalidIban': 'Please enter a valid IBAN.',
  'inputError.invalidFourDigit': 'Please enter a valid last 4 digits of the mobile number.',
  'inputError.maxLength': 'Must not exceed {length} characters.',
  'inputError.minLength': 'Must be at least {length} characters long.',
  'inputError.notEmail': 'We are looking for your username, not your email.',
  'inputError.password.match': 'Passwords do not match. Please try again.',
  'inputError.password.length': 'Password must be at least 8 characters long.',
  'inputError.password.required': 'Please enter a valid password.',
  'inputError.pin.THREE_EQUAL_DIGITS_IN_ROW': '3 equal digits in a row are too easy to guess. Please choose another PIN.',
  'inputError.pin.SEQUENTIAL_DIGITS': 'Sequential digits are too easy to guess. Please choose another PIN.',
  'inputError.pin.CONFIRMATION_MISSMATCH': 'Seems like you have entered a different PIN in the confirmation field. Please try again.',
  'inputError.required': 'This field is required.',
  'inputError.recipient.required': 'Please select a recipient account.',
  'inputError.smsChallenge': 'Invalid code. Please try again.',

  // Hint messages
  'hint.maxLimit': '(max. {limit} characters)',
  'hint.noChallengeReceivedYet': 'No code received?',

  // Labels
  'label.accountHolder': 'Account holder',
  'label.address':  'Address',
  'label.amount': 'Amount',
  'label.availableBalance': 'Available Balance',
  'label.balance': 'Balance',
  'label.bic': 'BIC',
  'label.birthDate': 'Birth date',
  'label.bookedBalance': 'Booked balance',
  'label.cardHolder': 'Card holder',
  'label.cardNo': 'Card no.',
  'label.confirmPassword': 'Confirm password',
  'label.description': 'Description',
  'label.email': 'Email',
  'label.expDate': 'Exp. date',
  'label.iban': 'IBAN',
  'label.inflow':'Inflow',
  'label.lostOrStolen': 'Lost or stolen',
  'label.fourDigit': 'Last 4 digits of registered mobile number',
  'label.mandateNumber':'Mandate no.',
  'label.mobileNumber':'Mobile number',
  'label.name': 'Name',
  'label.openedAt': 'Opened at',
  'label.outflow':'Outflow',
  'label.password':  'Password',
  'label.phone':  'Phone',
  'label.phoneNumberVerification':'Please have your phone ready. In the next step we will ask you to enter a code, which we will send you via SMS.',
  'label.placeOfBirth':  'Place of birth',
  'label.poolId':  'Pool ID',
  'label.protectedAmount': 'Protected amount',
  'label.recipient': 'Recipient',
  'label.reportingReason': 'Reporting reason',
  'label.reservedBalance': 'Reserved Balance',
  'label.reserved': 'Reserved',
  'label.seizedAmount': 'Seized amount',
  'label.showOnlyActiveAccounts': 'Show only active accounts',
  'label.subTopic': 'Subtopic',
  'label.taxCountry': 'Tax country',
  'label.taxId': 'Tax ID',
  'label.topic': 'Topic',
  'label.username': 'Username',
  
  // Locales
  'locale.en': 'English (EN)',
  'locale.de': 'German (DE)',

  // Messages
  'message.3ds.check': 'Please check your pending payment within the time frame:',
  'message.3ds.hint': 'After the time has expired, the transfer will be rejected.',
  'message.amountAvailable': '{amount} available',
  'message.balance': 'This balance does not include reservations and overdraft.',
  'message.dearCustomer': 'Dear customer',
  'message.forgotPassword': 'Please enter the username used for registration. You will shortly receive an email to reset your password.',
  'message.forgotPasswordRequested': 'We have sent you an email with instructions to reset your password. Also check your spam folder.',
  'message.inclOverdraftCredit': 'Incl. overdraft and credit limit',
  'message.loadingDocument': 'Loading Document …',
  'message.logoutTimer': 'Time until logout: ',
  'message.noAccountsFound': 'No accounts found.',
  'message.noDocumentsFound': 'No documents found.',
  'message.noReferenceAccountsFound': 'No reference accounts found.',
  'message.noTransactionsFound': 'No transactions found.',
  'message.pin.new': 'Please enter a new PIN.',
  'message.pin.confirm': 'Please confirm new PIN.',
  'message.setCredentials': 'Please enter a unique username and password to complete your registration. The password should be at least 8 characters long.',
  'message.setPassword': 'Please enter a password for your account. Your password should be at least 8 characters long.',
  'message.signedIn': 'You are already signed in.',
  'message.smsChallenge': 'Please enter the code we’ve sent you via SMS to your registered phone.',
  'message.smsChallenge.loading': 'Sending SMS …',
  'message.verifyEmail': 'Please provide the information requested below. Once verified, you can create a username and password to access your Solaris account. Please note: Your account may be issued under our partner\'s brand.',
  'message.welcomePageMessage1': 'SolarisGo gives you the opportunity to access your funds and continue using certain banking features.',
  'message.welcomePageMessage2': 'Credentials to SolarisGo are issued by Solaris in a fallback scenario. Please note that Solaris does not issue credentials upon individual request.',

  // Request support
  'requestSupport.descriptionError': 'The maximum character limit of 1.600 has been exceeded. Please shorten your message.',
  'requestSupport.successMessage': 'We received your request.',
  'requestSupport.successMessageDescription': 'We will take care of your request as quickly as possible and contact you via email at the address known to us.',
  'requestSupport.subject.accountManagement': 'Account Management',
  'requestSupport.subject.cardManagement': 'Card Management',
  'requestSupport.subject.paymentTransferWithdrawals': 'Payments, Transfers & Withdrawals',
  'requestSupport.subject.personalDetails': 'Personal Details',
  'requestSupport.subject.statements': 'Statements',
  'requestSupport.subject.seizures': 'Seizures',
  'requestSupport.other': 'Other',
  'requestSupport.subTopic.accountManagement.accountClosure': 'Account Closure',
  'requestSupport.subTopic.accountManagement.changeOfLinkedAccount': 'Change of Linked Account',
  'requestSupport.subTopic.cardManagement.chargeback': 'Chargeback',
  'requestSupport.subTopic.cardManagement.cardBlock': 'Card Block',
  'requestSupport.subTopic.cardManagement.cardIssueOrDecline': 'Card Issue/Decline',
  'requestSupport.subTopic.paymentTransferWithdrawals.payoutRequest': 'Payout Request',
  'requestSupport.subTopic.paymentTransferWithdrawals.transactionInvestigation': 'Transaction Investigation',
  'requestSupport.subTopic.paymentTransferWithdrawals.sddRecall': 'SDD Recall',
  'requestSupport.subTopic.statements.accountConfirmation': 'Account Confirmation',
  'requestSupport.subTopic.statements.paymentConfirmation': 'Payment Confirmation',
  'requestSupport.subTopic.statements.balanceConfirmation': 'Balance Confirmation',
  'requestSupport.subTopic.statements.bankStatement': 'Bank Statement',

  // Statuses
  'status.ACTIVE': 'Active',
  'status.ARCHIVED': 'Closed', // This is on purpose to simplify it for the customer
  'status.BLOCKED': 'Blocked',
  'status.BLOCKED_BY_SOLARIS': 'Blocked',
  'status.CLOSED_BY_SOLARIS': 'Closed',
  'status.INACTIVE': 'Inactive',
  'status.OPEN': 'Open',
  'status.PENDING': 'Pending',
  'status.PROCESSING': 'Processing',
  'status.REPORTED': 'Reported',
  'status.RESOLVED': 'Resolved',

  // Success messages
  'success.3ds.title': 'Payment successfully confirmed.',
  'success.3ds.message': 'You can now return to your merchant.',
  [`success.${CARD_BLOCK_ENDPOINT}`]: 'Card blocked successfully.',
  [`success.${CARD_UNBLOCK_ENDPOINT}`]: 'Card unblocked successfully.',
  'success.credentials.message': 'You can now log in to your account using your username and password.',
  'success.credentials.title': 'Credentials successfully created.',
  'success.emailVerification.message': 'Thank you for providing the information. After successful verification you will receive a message with the next steps to your registered email address.',
  'success.passwordReset.message': 'You can now sign in with your new password.',
  'success.passwordReset.title': 'Password changed successfully.',
  'success.phoneVerification.title':'Verification successful.',
  'success.phoneNumberVerification.message':'We have sent a message to your registered email address. Please use the link provided there to set up your username and password.',
  'success.pinChange': 'Your PIN was changed successfully.',
  'success.transfer.message': 'It may take up to 2 business days for the amount to be credited to the recipient’s account.',
  'success.transfer.title': 'We have received your transfer order.',

  // Titles
  'title.account': 'Account',
  'title.accounts': 'Accounts',
  'title.accountBalance': 'Account balance',
  'title.cards': 'Cards',
  'title.changeCardPin': 'Change card PIN',
  'title.checkEmails': 'Please check your emails.',
  'title.contact': 'Contact',
  'title.customerSupport': 'Customer support',
  'title.details': 'Details',
  'title.enterSmsCode': 'Enter SMS code',
  'title.imprint': 'Imprint',
  'title.information': 'Information',
  'title.login': 'Login',
  'title.logout': 'You will be logged out …',
  'title.ownAccount': 'Own account',
  'title.personalData': 'Personal Data',
  'title.phoneNumberVerification': 'Verify your phone number',
  'title.privacyPolicy': 'Privacy policy',
  'title.profile': 'Profile',
  'title.postbox': 'Postbox',
  'title.referenceAccount': 'Reference Account',
  'title.referenceAccounts':'Reference accounts',
  'title.requestSupport': 'Request support',
  'title.resetPassword': 'Reset password',
  'title.service': 'Service',
  'title.seizureInformation': 'Seizure information',
  'title.sepaTransfer': 'SEPA Transfer',
  'title.setCredentials': 'Set credentials',
  'title.setNewPassword': 'Set new password',
  'title.signOut': 'Sign out',
  'title.solarisgo': 'Solaris Go',
  'title.summary': 'Summary',
  'title.taxInformation': 'Tax information',
  'title.termsOfUse': 'Terms of use',
  'title.transactions': 'Transactions',
  'title.transfer.confirm': 'Confirm transfer',
  'title.transfer.create': 'New transfer',
  'title.transfer.originAccount': 'Account to be charged',
  'title.transfer.recipient': 'Recipient',
  'title.verifyIdentity': 'Start your verification',
  'title.welcome': 'Welcome',

  // Transactions
  'transactionCategory.AIR_TRAVEL': 'Air travel',
  'transactionCategory.AUTHORITIES': 'Authorities',
  'transactionCategory.AUTOMOBILE_ASSOCIATION': 'Automobile association',
  'transactionCategory.CAR_AND_ACCESSORIES': 'Car and accessories',
  'transactionCategory.CAR_RENTAL': 'Car rental',
  'transactionCategory.ENTERTAINMENT': 'Entertaiment',
  'transactionCategory.FUEL_STATION': 'Fuel station',
  'transactionCategory.GROCERY_STORE': 'Grocery store',
  'transactionCategory.HOTEL': 'Hotel',
  'transactionCategory.MEDICAL': 'Medical',
  'transactionCategory.MISC_SERVICES': 'Other services',
  'transactionCategory.MOBILITY': 'Mobility',
  'transactionCategory.OTHER': 'Other',
  'transactionCategory.PAYMENT': 'Payment',
  'transactionCategory.PERSONAL_SERVICES': 'Personal services',
  'transactionCategory.RESTAURANT': 'Restaurant',
  'transactionCategory.SHOPPING': 'Shopping',
  'transactionCategory.TRAVEL_AGENCY': 'Travel agency',

  // Urls
  'url.customerSupport': 'https://support.solarisgroup.com/hc/en-us',
  'url.imprint': 'https://www.solarisgroup.com/en/imprint/',
  'url.termsOfUse': 'https://www.solarisgroup.com/en/terms-of-use/',

  // Service and ZD
  'service.contactSupport': 'In case you have any questions, please contact our support team. We will respond via email as soon as possible.',

  // Misc
  'copyright': '© Solaris SE. All rights reserved.',
  'loading': 'Loading …',
};
