import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { isDevelopment, isStaging, isTesting } from 'src/lib/environment';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import {
  POST_CREATE_SMS_CHALLENGE_ENDPOINT,
  SMS_CHALLENGE_RESOURCE_NAME,
  smsChallengeResourceConfig,
} from '../../model/sms-challenge';
import {
  CREATE_SMS_CHALLENGE,
  CreateSmsChallengeAction,
  CreateSmsChallengeActionPayload,
} from '../../redux/sms-challenge-actions';

export function* listenToCreateSmsChallenge(): SagaIterator<void> {
  yield takeLatest(CREATE_SMS_CHALLENGE, handleCreateSmsChallenge);
}

export function* handleCreateSmsChallenge(action: CreateSmsChallengeAction): SagaIterator<void> {
  // Set loading state
  yield put(
    fetchEntityInitAction({
      entityName: SMS_CHALLENGE_RESOURCE_NAME,
    }),
  );

  // Create challenge
  const response = yield call(createSmsChallenge, action.payload);

  // Set error state
  if (response.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: SMS_CHALLENGE_RESOURCE_NAME,
        error: response.error,
      }),
    );
    return;
  }

  // Set success state
  yield put(
    fetchEntitySuccessAction({
      entityName: SMS_CHALLENGE_RESOURCE_NAME,
      data: response.data,
    }),
  );
}

export function* createSmsChallenge(args: CreateSmsChallengeActionPayload): SagaIterator<void> {
  const { customerId, challengeRequestId } = args;
  const attributes = isDevelopment() || isTesting() || isStaging() ? { delivery_method: 'static' } : undefined;
  const requestBody = {
    data: {
      type: 'sms_challenge',
      attributes, // ToDo: Check if this is correct, it's not in the actual API specs
      relationships: {
        challenge_request: {
          data: {
            type: 'challenge_request',
            id: challengeRequestId,
          },
        },
      },
    },
  };

  return yield call(resourceRequest, {
    resourceConfig: smsChallengeResourceConfig,
    endpoint: POST_CREATE_SMS_CHALLENGE_ENDPOINT,
    apiRequestParams: {
      pathParams: { customerId },
      queryParams: {},
    },
    requestBody,
  });
}
