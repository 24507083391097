import { generatePath } from 'react-router-dom';
import invariant from 'invariant';
import { getRoutes } from '../config';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const URI = require('urijs');
// eslint-disable-next-line @typescript-eslint/no-var-requires

export type PathParams = Record<string, string>;
export type QueryParams = Record<string, string | number | string[]>;

export interface RouteParams {
  queryParams?: QueryParams;
  pathParams?: PathParams;
}

export function createPathByRouteName(name: string, routerParams?: RouteParams): string {
  const routesConfig = getRoutes();
  invariant(routesConfig[name], `No path specified for route name ${name}.`);

  const pathTemplate = routesConfig[name]!.path;
  const pathParams = routerParams ? routerParams.pathParams : {};
  const queryParams = routerParams ? routerParams.queryParams : {};

  // check for path variables that are required by the template but not provided in pathParams
  try {
    const generatedPath = generatePath(pathTemplate, pathParams);

    // Adding undefined queryParams as search returns undefined url
    return queryParams
      ? URI(generatedPath)
          .query(queryParams)
          .toString()
      : generatedPath;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(
      `Error: The path template ${pathTemplate} is missing the required path parameters in ${JSON.stringify(
        pathParams,
      )}`,
    );
    return '';
  }
}

export function getTemplateVariables(pathTemplate: string): string[] | undefined {
  const matches = pathTemplate.match(/\{(.*?)\}/g);
  if (!matches) {
    return;
  }
  return matches.map(pathVar => pathVar.replace('}', '').replace('{', ''));
}

export function hasRequiredPathParams(template: string, pathParams: PathParams | undefined): boolean {
  const templateVars = getTemplateVariables(template);
  if (!templateVars) {
    return true;
  }
  const missingPathParams = templateVars.filter(templateVar => !!pathParams && !pathParams.hasOwnProperty(templateVar));

  if (missingPathParams.length > 0) {
    // tslint:disable-next-line:max-line-length
    console.warn(
      `Missing variables. The provided template "${template}" misses these variables: ${missingPathParams.join(', ')}.`,
    );
    return false;
  }
  return true;
}
