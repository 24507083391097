import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CodeInput, InlineText, ItemGroup, ModalProps, ModalSection, SolarisSpinner } from 'sb-ui-components';
import { ModalWrapper } from 'src/components/modal-wrapper';
import { SuccessErrorMessage, SuccessErrorMessageProps } from 'src/components/success-error-message';
import useCountdown from 'src/lib/hooks/countdown';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { AppState, Entity } from 'src/types';
import {
  SMS_CHALLENGE_CONFIRMATION_STATE,
  SMS_CHALLENGE_RESOURCE_NAME,
  SmsChallengeCreation,
} from '../model/sms-challenge';
import {
  confirmSmsChallengeAction,
  ConfirmSmsChallengeActionPayload,
  createSmsChallengeAction,
} from '../redux/sms-challenge-actions';
import styles from './sms-challenge.module.scss';

export interface SmsChallengeModalProps extends ModalProps {
  successMessage: {
    title: SuccessErrorMessageProps['title'];
    message?: SuccessErrorMessageProps['message'];
    cta?: SuccessErrorMessageProps['cta'];
  };
  isOpen: boolean;
  onClose?: () => void;
}

export const SmsChallengeModal: FunctionComponent<SmsChallengeModalProps> = props => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { control, handleSubmit, setValue } = useForm<ConfirmSmsChallengeActionPayload['formValues']>({
    mode: 'onChange',
  });
  const { successMessage, isOpen, onClose } = props;
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const entityState = useSelector((state: AppState) => state.entity);
  const customerId = entityState?.customer?.data?.id;

  // Challenge creation state
  const smsChallengeState: Entity<SmsChallengeCreation> | undefined = entityState?.[SMS_CHALLENGE_RESOURCE_NAME];
  const isChallengeCreationPending = smsChallengeState?.loading;
  const isChallengeCreationError = Boolean(smsChallengeState?.error);
  const challengeRequestId = smsChallengeState?.data?.relationships.challenge_request.data.id;
  const challengeId = smsChallengeState?.data?.id;

  // Challenge confirmation state
  const smsChallengeConfirmationState = entityState?.[SMS_CHALLENGE_CONFIRMATION_STATE];
  const isChallengeConfirmationPending = smsChallengeConfirmationState?.loading;
  const isChallengeConfimationSuccess = smsChallengeConfirmationState?.data?.attributes?.status === 'COMPLETED';
  const isChallengeConfimationError = Boolean(smsChallengeConfirmationState?.error);

  const [showResendSmsButton, setShowResendSmsButton] = useState<boolean>(false);

  const remainingSeconds = useCountdown({
    initialRemainingSeconds: 5,
    onTimeout: () => {
      // we are enabling the resend button when modal is open
      // end disable it again as soon as modal closes
      setShowResendSmsButton(isOpen && !isChallengeCreationPending);
    },
    // we want the countdown to start automatically as soon as the modal is open and
    // creating the challenge finished
    autoStart: isOpen && !isChallengeCreationPending,
  });

  const renderRemainingSeconds = () => {
    if (remainingSeconds > 0) {
      return `(${remainingSeconds})`;
    } else {
      return null;
    }
  };

  const onResend = (e: MouseEvent) => {
    e.preventDefault(); // Prevents submitting the form
    setIsFormValid(false);
    // Reset state
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_CONFIRMATION_STATE }));
    // Trigger sms challenge
    challengeRequestId && dispatch(createSmsChallengeAction({ challengeRequestId, customerId }));
  };

  const onFormSubmit = (formValues: ConfirmSmsChallengeActionPayload['formValues']) => {
    challengeId &&
      dispatch(
        confirmSmsChallengeAction({
          formValues,
          pathParams: { challengeId },
        }),
      );
  };

  const handleOnClose = () => {
    onClose && onClose();
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_RESOURCE_NAME }));
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_CONFIRMATION_STATE }));
  };

  if (isChallengeCreationError) {
    return (
      <ModalWrapper
        isOpen={isOpen}
        modalProps={{
          maxWidth: 'xsmall',
          className: styles.modal,
          dataCy: 'modal__sms_challenge',
        }}
      >
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.general.title' })}
          message={formatMessage({ id: 'error.general.message' })}
          cta={
            <Button size="big" handleClick={handleOnClose}>
              {formatMessage({ id: 'button.ok' })}
            </Button>
          }
        />
      </ModalWrapper>
    );
  }

  if (isChallengeConfimationSuccess) {
    return (
      <ModalWrapper
        isOpen={isOpen}
        modalProps={{
          maxWidth: 'xsmall',
          className: styles.modal,
          dataCy: 'modal__sms_challenge',
        }}
      >
        <SuccessErrorMessage
          type="success"
          title={successMessage.title}
          message={successMessage.message}
          cta={
            successMessage.cta || (
              <Button size="big" handleClick={handleOnClose}>
                {formatMessage({ id: 'button.ok' })}
              </Button>
            )
          }
        />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      modalProps={{
        maxWidth: 'xsmall',
        headerTitle: !isChallengeConfimationSuccess ? formatMessage({ id: 'title.enterSmsCode' }) : undefined,
        className: styles.modal,
        dataCy: 'modal__sms_challenge',
      }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalSection>
          <ItemGroup justifyItems="center" gutterY="large" fullwidth>
            {isChallengeCreationPending ? (
              <>
                <br />
                <SolarisSpinner size="large" />
                <p>{formatMessage({ id: 'message.smsChallenge.loading' })}</p>
                <br />
              </>
            ) : (
              <>
                <label>
                  <InlineText size="small">{formatMessage({ id: 'message.smsChallenge' })}</InlineText>
                </label>
                <CodeInput
                  id="confirmation_value"
                  length={6}
                  control={control}
                  autoFocus={true}
                  setValue={setValue}
                  onComplete={() => setIsFormValid(true)}
                  dataCy="confirmation_value_input"
                  isInvalid={isChallengeConfimationError}
                  errorMessage={
                    isChallengeConfimationError ? formatMessage({ id: 'inputError.smsChallenge' }) : undefined
                  }
                />
              </>
            )}
          </ItemGroup>
        </ModalSection>

        <ModalSection>
          {!isChallengeCreationPending && challengeRequestId && (
            <ItemGroup justifyItems="center" alignItems="baseline" gutter="none">
              <InlineText size="small">{formatMessage({ id: 'hint.noChallengeReceivedYet' })}</InlineText>
              <Button
                type="button"
                appearance="no-background"
                dataCy="cta__resend"
                handleClick={onResend}
                disabled={!showResendSmsButton}
                className={styles.resendButton}
              >
                {formatMessage({ id: 'button.resendCode' })} {renderRemainingSeconds()}
              </Button>
            </ItemGroup>
          )}
        </ModalSection>

        <ModalSection>
          <ItemGroup justifyItems="space-between">
            <Button size="big" appearance="stroked" color="neutral" handleClick={handleOnClose}>
              {formatMessage({ id: 'button.cancel' })}
            </Button>
            <Button
              size="big"
              type="submit"
              disabled={!isFormValid || isChallengeConfirmationPending}
              isLoading={isChallengeConfirmationPending}
              dataCy="cta__submit_confirmation_value"
            >
              {formatMessage({ id: 'button.confirm' })}
            </Button>
          </ItemGroup>
        </ModalSection>
      </form>
    </ModalWrapper>
  );
};
