import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BalanceCell, Box, Button, HorizontalDivider, InlineText, ItemGroup } from 'sb-ui-components';
import useCountdown from 'src/lib/hooks/countdown';
import { formatTime } from 'src/lib/utils';
import { SmsChallengeModal } from 'src/modules/sms-challenge/components';
import { SMS_CHALLENGE_RESOURCE_NAME } from 'src/modules/sms-challenge/model/sms-challenge';
import { Entity } from 'src/types';
import { CUSTOMER_3DS_APPROVE_STATE, CUSTOMER_3DS_DECLINE_STATE, Customer3ds } from '../../models/customer-3ds';
import {
  approveCustomer3dsAction,
  declineCustomer3dsAction,
  fetchCustomer3dsAction,
} from '../../redux/customer-3ds-actions';
import styles from './customer-3ds-list-item.module.scss';

export const Customer3dsListItem: FunctionComponent<{ customer3ds: Customer3ds | undefined }> = props => {
  const { customer3ds } = props;
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  // Fetch the customer 3ds notification loading state
  const isApproveLoading: Entity<unknown> = useSelector(
    (state: any) => state.entity[`${CUSTOMER_3DS_APPROVE_STATE}_${customer3ds?.id}`]?.loading,
  );
  const isDeclineLoading: Entity<unknown> = useSelector(
    (state: any) => state.entity[`${CUSTOMER_3DS_DECLINE_STATE}_${customer3ds?.id}`]?.loading,
  );
  // Hooks to open and close the sms challenge modal
  const isSmsChallangeCreated = Boolean(useSelector((state: any) => state.entity?.[SMS_CHALLENGE_RESOURCE_NAME]));

  // Actions
  const handleReloadNotifications = () => {
    dispatch(fetchCustomer3dsAction());
  };

  const approveCustomer3ds = () => {
    dispatch(approveCustomer3dsAction({ notificationId: customer3ds!.id }));
  };

  const declineCustomer3ds = () => {
    dispatch(declineCustomer3dsAction({ notificationId: customer3ds!.id }));
  };

  // Countdown
  const initialRemainingSeconds: number =
    (customer3ds && Math.floor((Date.parse(customer3ds?.attributes.expires_at) - Date.now()) / 1000)) || 0;

  const remainingSeconds = useCountdown({ initialRemainingSeconds, onTimeout: handleReloadNotifications });

  if (!props.customer3ds) return null;

  return (
    <>
      <Box key={customer3ds?.id} dataCy="customer3dsItem">
        <ItemGroup justifyItems="space-between" alignItems="baseline" nowrap>
          <p className={styles.message}>
            <em>{formatMessage({ id: 'message.3ds.check' })}</em>
            <br />
            <InlineText type="small" size="xsmall" color="soft" className={styles.hint}>
              {formatMessage({ id: 'message.3ds.hint' })}
            </InlineText>
          </p>
          <InlineText size="medium" color="orange" className={styles.counter}>
            <em>{remainingSeconds && formatTime(remainingSeconds)}</em>
          </InlineText>
        </ItemGroup>

        <HorizontalDivider weight="light" />

        <ItemGroup justifyItems="space-between">
          <InlineText>{customer3ds?.attributes.merchant_name}</InlineText>
          <BalanceCell bold data={customer3ds?.attributes.amount!} swapColorCoding />
        </ItemGroup>

        <HorizontalDivider weight="light" />

        <ItemGroup justifyItems="right">
          <Button
            appearance="stroked"
            leadingIcon="TimesRegular"
            handleClick={declineCustomer3ds}
            disabled={Boolean(isApproveLoading)}
            isLoading={Boolean(isDeclineLoading)}
          >
            {formatMessage({ id: 'button.decline' })}
          </Button>
          <Button
            leadingIcon="CheckRegular"
            handleClick={approveCustomer3ds}
            disabled={Boolean(isDeclineLoading)}
            isLoading={Boolean(isApproveLoading)}
          >
            {formatMessage({ id: 'button.approve' })}
          </Button>
        </ItemGroup>
      </Box>
      <SmsChallengeModal
        successMessage={{
          title: formatMessage({ id: 'success.3ds.title' }),
          message: formatMessage({ id: 'success.3ds.message' }),
        }}
        isOpen={Boolean(isSmsChallangeCreated)}
        onClose={handleReloadNotifications}
      />
    </>
  );
};
