import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { isDevelopment, isStaging, isTesting } from 'src/lib/environment';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import { AppState } from 'src/types';
import {
  POST_CREATE_SMS_CHALLENGE_AUTH_ENDPOINT,
  SMS_CHALLENGE_AUTH_RESOURCE_NAME,
  smsChallengeAuthResourceConfig,
} from '../../model/sms-challenge-auth';
import {
  CREATE_SMS_CHALLENGE_AUTH,
  CreateSmsChallengeAuthAction,
  CreateSmsChallengeAuthActionPayload,
} from '../../redux/sms-challenge-auth-actions';

export function* listenToCreateSmsChallengeAuth(): SagaIterator<void> {
  yield takeLatest(CREATE_SMS_CHALLENGE_AUTH, handleCreateSmsChallengeAuth);
}

export function* handleCreateSmsChallengeAuth(action: CreateSmsChallengeAuthAction): SagaIterator<void> {
  // Set loading state
  yield put(
    fetchEntityInitAction({
      entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME,
    }),
  );

  // Create challenge
  const response = yield call(createSmsChallengeAuth, action.payload);

  // Set error state
  if (response.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME,
        error: response.error,
      }),
    );
    return;
  }

  // Set success state
  yield put(
    fetchEntitySuccessAction({
      entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME,
      data: response.data,
    }),
  );
}

export function* createSmsChallengeAuth(args: CreateSmsChallengeAuthActionPayload): SagaIterator<void> {
  const token = yield select((state: AppState) => state.auth.idToken);
  const { challengeRequestId } = args;
  const apiRequestParams: ApiRequestParams = {
    headerParams: { Authorization: `Bearer ${token}` },
    pathParams: {},
    queryParams: {},
  };
  const attributes = isDevelopment() || isTesting() || isStaging() ? { delivery_method: 'static' } : undefined;
  const requestBody = {
    data: {
      type: 'sms_challenge',
      attributes,
      relationships: {
        challenge_request: {
          data: {
            type: 'challenge_request',
            id: challengeRequestId,
          },
        },
      },
    },
  };
  return yield call(resourceRequest, {
    resourceConfig: smsChallengeAuthResourceConfig,
    endpoint: POST_CREATE_SMS_CHALLENGE_AUTH_ENDPOINT,
    apiRequestParams,
    requestBody,
  });
}
