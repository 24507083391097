import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import {
  POST_CONFIRM_CONFIRMATION_VALUE_ENDPOINT,
  SMS_CHALLENGE_CONFIRMATION_STATE,
  smsChallengeResourceConfig,
} from '../../model/sms-challenge';
import {
  CONFIRM_SMS_CHALLENGE,
  ConfirmSmsChallengeAction,
  ConfirmSmsChallengeActionPayload,
} from '../../redux/sms-challenge-actions';

export function* listenToConfirmSmsChallenge(): SagaIterator<void> {
  yield takeLatest(CONFIRM_SMS_CHALLENGE, handleConfirmSmsChallenge);
}

export function* handleConfirmSmsChallenge(action: ConfirmSmsChallengeAction): SagaIterator<void> {
  // Set loading state
  yield put(fetchEntityInitAction({ entityName: SMS_CHALLENGE_CONFIRMATION_STATE }));

  // Confirm challenge
  const response = yield call(confirmSmsChallenge, action.payload);
  if (response.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: SMS_CHALLENGE_CONFIRMATION_STATE,
        error: response.error,
      }),
    );
    return;
  }

  // Remove loading state
  yield put(
    fetchEntitySuccessAction({
      entityName: SMS_CHALLENGE_CONFIRMATION_STATE,
      data: response.data,
    }),
  );
}

export function* confirmSmsChallenge(args: ConfirmSmsChallengeActionPayload): SagaIterator<void> {
  const { formValues, pathParams } = args;
  const requestBody = {
    data: {
      type: 'challenge_confirmation',
      attributes: formValues,
    },
  };

  return yield call(resourceRequest, {
    resourceConfig: smsChallengeResourceConfig,
    endpoint: POST_CONFIRM_CONFIRMATION_VALUE_ENDPOINT,
    apiRequestParams: {
      pathParams,
      queryParams: {},
    },
    requestBody,
  });
}
