import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { ButtonLink } from 'sb-ui-components';
import { AuthPageWrapper } from 'src/components/auth-wrapper';
import { createPathByRouteName } from '../../../lib/route-config-helper';
import { LOGIN_ROUTE } from '../../login';

const WelcomePage: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <AuthPageWrapper>
      <h1>{formatMessage({ id: 'message.dearCustomer' })},</h1>
      <p>{formatMessage({ id: 'message.welcomePageMessage1' })}</p>
      <p>{formatMessage({ id: 'message.welcomePageMessage2' })}</p>
      <br />
      <ButtonLink href={createPathByRouteName(`${[LOGIN_ROUTE]}`)} size="big" trailingIcon="ArrowRightRegular">
        {formatMessage({ id: 'button.goToLogin' })}
      </ButtonLink>
    </AuthPageWrapper>
  );
};

export default WelcomePage;
