import { CardPinErrorMessage } from '../models/card-change-pin-flow-model';

export interface ValidatePinOutput {
  isValid: boolean;
  errorMessage?: CardPinErrorMessage;
}

export const validatePin = (value: string): ValidatePinOutput => {
  // Test for 3 equal digits in a row
  if (/(\d)\1{2}/.test(value)) return { isValid: false, errorMessage: 'THREE_EQUAL_DIGITS_IN_ROW' };

  let output: ValidatePinOutput = { isValid: true };
  // Test for sequential ascending order
  for (let i = 0; i < value.length - 1; i++) {
    const current = Number(value[i]);
    const next = Number(value[i + 1]);

    if (current === next - 1) {
      output = { isValid: false, errorMessage: 'SEQUENTIAL_DIGITS' };
    } else {
      output = { isValid: true };
      break;
    }
  }
  // If it has sequential ascending order, we can return false here already,
  // No need to enter the second loop.
  if (output.isValid === false) return output;

  // Test for sequential descending order
  for (let i = 0; i < value.length - 1; i++) {
    const current = Number(value[i]);
    const next = Number(value[i + 1]);

    if (current === next + 1) {
      output = { isValid: false, errorMessage: 'SEQUENTIAL_DIGITS' };
    } else {
      output = { isValid: true };
      break;
    }
  }
  return output;
};
