import { FunctionComponent } from 'react';
import { ModalSection, SvgIcon } from 'sb-ui-components';
import { Enum } from 'typescript-string-enums';
import styles from './success-error-message.module.scss';

export const messageTypes = Enum('success', 'error');
export type MessageType = keyof typeof messageTypes;

export interface SuccessErrorMessageProps {
  type: MessageType;
  title: string;
  message?: string | JSX.Element;
  cta?: JSX.Element;
}

export const SuccessErrorMessage: FunctionComponent<SuccessErrorMessageProps> = props => {
  const { type, title, message, cta } = props;

  return (
    <div className={styles.base} data-cy={`${type}_message`}>
      {type === 'success' && <SvgIcon type="CircleCheckLight" size="huge" color="green" className={styles.icon} />}
      {type === 'error' && <SvgIcon type="ExclamationTriangleLight" size="huge" color="red" className={styles.icon} />}
      <h2>{title}</h2>
      {message && (
        <p className={styles.successMessage} data-cy="success_message">
          {message}
        </p>
      )}
      <ModalSection className={styles.cta}>{cta}</ModalSection>
    </div>
  );
};
