import { isSuccessfulResponse } from 'src/sagas/resource-requests';
import { ListResourceState, ResourceResponse } from '../types';

export function listResourcePayloadToListResourceState<Entity>(
  response: ResourceResponse<Entity[]>,
): ListResourceState<Entity> {
  // response meta defaults are granted by request
  return {
    ...(isSuccessfulResponse(response) ? { list: response.data } : { error: response.error }),
    total: response.meta.total,
    queryParams: response.meta.queryParams,
    pathParams: response.meta.pathParams,
  };
}

export function filterFormToFilterQueryParams(filterForm: Object): {} {
  if (!filterForm) {
    return {};
  }
  return Object.keys(filterForm).reduce((prev, filterKey: keyof typeof filterForm) => {
    const key = filterKey
      .split(':')
      .map(keyPart => `[${keyPart}]`)
      .join('');
    return { ...prev, [`filter${key}`]: filterForm[filterKey] };
  }, {});
}
