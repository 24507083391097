import { FunctionComponent } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppState } from 'src/types';
import { Enum } from 'typescript-string-enums';
import * as messages from '../messages';

export const locales = Enum('en', 'de');
export type LocaleOption = Enum<typeof locales>;

export const defaultLocale = localStorage.getItem('locale') || 'en';

export const LocalizationWrapper: FunctionComponent = props => {
  const locale = useSelector((state: AppState) => state.ui.locale);

  return (
    <IntlProvider locale={locale} defaultLocale={defaultLocale} messages={messages[locale]}>
      {props.children}
    </IntlProvider>
  );
};
