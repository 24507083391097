import { ModuleConfig } from 'src/models/module';
import { CUSTOMER_3DS_RESOURCE_NAME } from './models/customer-3ds';
import { listenToApproveCustomer3ds } from './sagas/approve/customer-3ds-approve';
import { listenToDeclineCustomer3ds } from './sagas/decline/customer-3ds-decline';
import { listenToFetchCustomer3ds } from './sagas/index/customer-3ds-index';

export const customer3dsModuleConfig: ModuleConfig = {
  moduleName: CUSTOMER_3DS_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToFetchCustomer3ds, listenToApproveCustomer3ds, listenToDeclineCustomer3ds],
  routes: {},
};
