import { ModuleConfig } from 'src/models/module';
import { ACCOUNT_CARD_RESOURCE_NAME } from './models/card';
import { listenToBlockCard, listenToUnblockCard } from './sagas';

export const cardModuleConfig: ModuleConfig = {
  moduleName: ACCOUNT_CARD_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToBlockCard, listenToUnblockCard],
  routes: {},
};
