import { SagaIterator } from 'redux-saga';
import { call, select, spawn } from 'redux-saga/effects';
import { Notification } from 'sb-ui-components';
import { ApiRequest, ApiRequestParams, binaryRequest, buildRequestConfig, request } from 'src/lib/api';
import { createNotification } from 'src/sagas/notification';
import { AppState, ResourceConfig, ResourceResponse } from 'src/types';
import { v4 } from 'uuid';

const defaultApiRequestParams: ApiRequestParams = { queryParams: {}, pathParams: {} };

export function isSuccessfulResponse(response: ResourceResponse<unknown>): boolean {
  return !response.error;
}

export function* resourceRequest(args: {
  resourceConfig: ResourceConfig;
  endpoint: string;
  apiRequestParams: ApiRequestParams;
  requestBody?: {};
  spawnErrorNotification?: boolean;
  requestSaga?: (params: ApiRequest) => void;
}): SagaIterator<ResourceResponse<any>> {
  // TODO this needs to get removed when we authenticate with identity-broker
  // The token then will be passed automatically via http-only cookie (which amplify does not support yet)
  const token = yield select((state: AppState) => state.auth.idToken);
  //
  const { resourceConfig, endpoint, apiRequestParams, requestBody, spawnErrorNotification, requestSaga } = args;
  apiRequestParams.headerParams = apiRequestParams.headerParams
    ? { Authorization: `Bearer ${token}`, ...apiRequestParams.headerParams }
    : { Authorization: `Bearer ${token}` };

  const endpointConfig = resourceConfig.endpoints[endpoint];

  if (!endpointConfig) {
    console.error(`The endpoint ${endpoint} does not exist on the model resource configuration`);
  }

  const apiRequestConfig: ApiRequest = buildRequestConfig(endpointConfig, apiRequestParams, requestBody);
  try {
    return yield call(requestSaga ? requestSaga : request, apiRequestConfig);
  } catch (resourceResponse) {
    if (spawnErrorNotification) {
      const notification: Notification = {
        id: v4(),
        type: 'ERROR',
        topic: 'error.general.title',
        title: 'error.general.message',
      };
      yield spawn(createNotification, notification);
    }
    return resourceResponse;
  }
}

export function* binaryResourceRequest(
  model: ResourceConfig,
  endpoint: string,
  apiRequestParams: ApiRequestParams = defaultApiRequestParams,
  payload?: {},
): SagaIterator<any> {
  return yield call(resourceRequest, {
    resourceConfig: model,
    endpoint,
    apiRequestParams,
    requestBody: payload,
    spawnErrorNotification: true,
    requestSaga: binaryRequest,
  });
}
