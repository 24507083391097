import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Box, DataPresenter, EN_DASH, formatApiDate, formatName, Header } from 'sb-ui-components';
import { Customer } from 'src/modules/customer/models/customer';
import { Entity } from 'src/types';

export interface CustomerDetailsProps {
  customer: Entity<Customer>;
}

export const CustomerDetails: FunctionComponent<CustomerDetailsProps> = props => {
  const { customer } = props;
  const { formatMessage } = useIntl();

  const error = customer?.error;
  const attributes = customer?.data?.attributes;
  const { first_name, last_name, salutation, birth_date, birth_city, birth_country } = attributes || {};
  return (
    <>
      {error ? (
        <Box>
          {error.status} {EN_DASH} {error.message}
        </Box>
      ) : (
        <>
          <Header>
            <h2>{formatMessage({ id: 'title.personalData' })}</h2>
          </Header>
          <DataPresenter
            dataCy="customerPersonData"
            isLoading={customer?.loading}
            data={[
              {
                label: formatMessage({ id: 'label.name' }),
                value: first_name && last_name && formatName(first_name, last_name, undefined, salutation),
              },
              {
                label: formatMessage({ id: 'label.birthDate' }),
                value: formatApiDate(birth_date),
              },
              {
                label: formatMessage({ id: 'label.placeOfBirth' }),
                value: birth_city && birth_country && `${birth_city}, ${birth_country}`,
              },
            ]}
          />
        </>
      )}
    </>
  );
};
