import { PayloadAction } from '../../../types';

export const CREATE_CREDENTIALS_ACTION = 'credentials/CREATE';

export interface CreateCredentialsPayload {
  username: string;
  password: string;
}

export type CreateCredentialsAction = PayloadAction<typeof CREATE_CREDENTIALS_ACTION, CreateCredentialsPayload>;

export function createCredentialsAction(payload: CreateCredentialsPayload): CreateCredentialsAction {
  return {
    type: CREATE_CREDENTIALS_ACTION,
    payload,
  };
}
