import { ModuleConfig } from 'src/models/module';
import { SMS_CHALLENGE_RESOURCE_NAME } from './model/sms-challenge';
import { listenToConfirmSmsChallenge, listenToCreateSmsChallenge } from './sagas';

export const smsChallengeModuleConfig: ModuleConfig = {
  moduleName: SMS_CHALLENGE_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToCreateSmsChallenge, listenToConfirmSmsChallenge],
  routes: {},
};
