import { ModuleConfig } from './../../models/module';
import { REFERENCE_ACCOUNT_RESOURCE_NAME } from './models/reference-account';
import { listenToIndexReferenceAccountList } from './sagas/reference-account-index';

export const referenceAccountModuleConfig: ModuleConfig = {
  moduleName: REFERENCE_ACCOUNT_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToIndexReferenceAccountList],
  routes: {},
};
