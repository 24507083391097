import { ModuleConfig } from 'src/models/module';
import { showPageEnterAccountTransfer } from 'src/modules/transfer/sagas/transfer';
import { CreateTransferPage } from './components/create-transfer-page';
import { TRANSFER_CREATION_RESOURCE_NAME } from './model/transfer';
import {
  listenToCreateIntraCustomerTransfer,
  listenToCreateReferenceAccountPayout,
  listenToCreateSepaCreditTransfer,
} from './sagas';

export const accountTransferResourceConfig: ModuleConfig = {
  moduleName: TRANSFER_CREATION_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [
    listenToCreateSepaCreditTransfer,
    listenToCreateIntraCustomerTransfer,
    listenToCreateReferenceAccountPayout,
  ],
  routes: {
    'sepaCreditTransfer.create': {
      path: '/accounts/:id/transfer',
      component: CreateTransferPage,
      saga: showPageEnterAccountTransfer,
    },
  },
};
