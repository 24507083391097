import { ModuleConfig } from 'src/models/module';
import { showPageEnterPhoneVerification } from 'src/modules/phone-verification/sagas/phone-verification-page';
import { PhoneVerificationPage } from './component/phone-verification-page';

export const PHONE_VERIFICATION_RESOURCE_NAME = 'phoneVerification';
export const PHONE_VERIFICATION_ENDPOINT = 'phoneVerification/VERIFY';

export const phoneVerificationConfig: ModuleConfig = {
  moduleName: '',
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    'phone.verification': {
      path: '/phone-verification',
      component: PhoneVerificationPage,
      skipAuthentication: true,
      saga: showPageEnterPhoneVerification,
    },
  },
};
