import { ModuleConfig } from '../../models/module';
import { POSTBOX_RESOURCE_NAME } from './models/postbox';
import { listenToDownloadPostbox } from './sagas/download/postbox-download';

export const postboxModuleConfig: ModuleConfig = {
  moduleName: POSTBOX_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToDownloadPostbox],
  routes: {},
};
