import { ModuleConfig } from '../../models/module';
import ServiceShowPage from './components/service-show-page';
import { SERVICE_RESOURCE_NAME } from './models/service';
import { showPageEnterService } from './sagas/show/service-show';

export const serviceModuleConfig: ModuleConfig = {
  moduleName: SERVICE_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    'service.show': {
      path: '/service',
      component: ServiceShowPage,
      saga: showPageEnterService,
    },
  },
};
