import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, ButtonLink, ItemGroup, PageSection } from 'sb-ui-components';

export const CreateTransferFormButtons: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  return (
    <PageSection>
      <ItemGroup justifyItems="space-between">
        <ButtonLink size="big" appearance="stroked" color="neutral" href={'/'} action={() => history.goBack()}>
          {formatMessage({ id: 'button.cancel' })}
        </ButtonLink>
        <Button type="submit" size="big" trailingIcon="ArrowRightRegular">
          {formatMessage({ id: 'button.next' })}
        </Button>
      </ItemGroup>
    </PageSection>
  );
};
