import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DataList, formatIban, InlineText, RadioInput } from 'sb-ui-components';
import { Account, ACCOUNT_RESOURCE_NAME } from 'src/modules/account/models/account';
import { indexAccountListAction } from 'src/modules/account/redux/account-actions';
import { ListResourceState } from 'src/types';
import { IntraTransferFormValues, TransferStep } from '../model/transfer';
import { CreateTransferFormButtons } from './create-transfer-form-buttons';
import { TransferDetailsFormSection } from './transfer-details-form-section';

export interface IntraTransferFormProps {
  account: Account;
  setStep: (step: TransferStep) => void;
  setSummaryData: (data: IntraTransferFormValues) => void;
  summaryData?: IntraTransferFormValues;
}

export const IntraTransferForm: FunctionComponent<IntraTransferFormProps> = props => {
  const { setStep, summaryData, setSummaryData, account } = props;
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const { register, control, handleSubmit, errors, reset } = useForm<IntraTransferFormValues>({
    mode: 'onSubmit',
  });

  const accountList: ListResourceState<Account> = useSelector((state: any) => state.list[ACCOUNT_RESOURCE_NAME]);
  // Filter out the current account from the list
  const getSelactableAccounts = () => accountList?.list?.filter((item: Account) => item.id !== account.id);

  const defaultIsRecipientPresent =
    summaryData?.recipient_account_id === undefined ? undefined : Boolean(summaryData?.recipient_account_id);
  const [isRecipentFieldValid, setIsRecipentFieldValid] = useState<Boolean | undefined>(defaultIsRecipientPresent);

  const onSubmit = (formValues: IntraTransferFormValues) => {
    if (isRecipentFieldValid) {
      setStep('SUMMARY');
      const recipientAccount = accountList?.list!.find((item: Account) => item.id === formValues.recipient_account_id);
      recipientAccount && setSummaryData({ ...formValues, recipientAccount });
    } else {
      setIsRecipentFieldValid(false);
      setSummaryData(formValues);
    }
  };

  // We only load the customers accounts if they haven't been fetched already
  useEffect(() => {
    if (!accountList) {
      dispatch(indexAccountListAction());
    }
  }, [dispatch, accountList]);

  useEffect(() => {
    if (summaryData) {
      reset(summaryData);
    }
  }, [reset, summaryData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {accountList && (
        <>
          <DataList
            list={getSelactableAccounts()}
            errorMessage={accountList.error && formatMessage({ id: 'error.loading' })}
            isLoading={accountList.isLoading}
            noValuePlaceHolder={formatMessage({ id: 'error.loading' })}
            leadingIcon={() => 'BuildingColumnsLight'}
            leadingContent={(item: Account) => (
              <InlineText nowrap type="div">
                {formatMessage({ id: `accountType.${item.attributes.account_type}` })}
              </InlineText>
            )}
            leadingSubline={(item: Account) => (
              <InlineText nowrap size="small" color="soft">
                <span id={`iban-${item.id}`}>{formatIban(item.attributes.iban)}</span>
              </InlineText>
            )}
            trailingContent={(item: Account) => (
              <RadioInput
                id={`${item.id}`}
                register={register}
                name="recipient_account_id"
                label={''}
                labelledBy={`iban-${item.id}`}
                onChange={() => setIsRecipentFieldValid(true)}
              />
            )}
          />
          {isRecipentFieldValid === false && (
            <InlineText color="red" size="xsmall">
              {formatMessage({ id: 'inputError.recipient.required' })}
            </InlineText>
          )}
        </>
      )}

      <TransferDetailsFormSection
        control={control}
        register={register}
        errors={errors}
        balance={account.attributes.balance}
      />

      <CreateTransferFormButtons />
    </form>
  );
};
