import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box, InlineText, ItemGroup, TextWithIcon } from 'sb-ui-components';
import { ListResourceState } from 'src/types';
import { Account, ACCOUNT_RESOURCE_NAME } from '../models/account';
import { AccountListItem } from './account-list-item';
import { AccountListItemLoader } from './account-list-item-loader';

export interface AccountListProps {
  showOnlyActiveAccounts: boolean;
}

export const AccountList: FunctionComponent<AccountListProps> = props => {
  const { formatMessage } = useIntl();
  const { showOnlyActiveAccounts } = props;
  const accountList: ListResourceState<Account> = useSelector((state: any) => state.list[ACCOUNT_RESOURCE_NAME]);

  if (accountList?.isLoading) return <AccountListItemLoader />;

  if (!accountList) return null;
  const { error, list } = accountList;

  if (error)
    return (
      <Box>
        <TextWithIcon
          icon="ExclamationTriangleLight"
          text={formatMessage({ id: 'error.loading' })}
          color="red-strong"
          verticalAlign="baseline"
          nowrap
        />
      </Box>
    );

  if (!list?.length)
    return (
      <Box>
        <InlineText color="muted">{formatMessage({ id: 'message.noAccountsFound' })}</InlineText>
      </Box>
    );

  const accountsToDisplay = showOnlyActiveAccounts ? list.filter(item => item.attributes.status !== 'INACTIVE') : list;

  return (
    <ItemGroup fullwidth>
      {accountsToDisplay?.map((item: Account) => (
        <AccountListItem key={item.id} account={item} />
      ))}
    </ItemGroup>
  );
};
