import { PayloadAction, ServerErrorResponse } from 'src/types';

export const FETCH_ENTITY_INIT_ACTION = 'fetchEntity/INIT';

// Init action to set the loading state of the entity

export interface FetchEntityInitActionPayload {
  entityName: string;
}

export type FetchEntityInitAction = PayloadAction<typeof FETCH_ENTITY_INIT_ACTION, FetchEntityInitActionPayload>;

export const fetchEntityInitAction = (payload: FetchEntityInitActionPayload): FetchEntityInitAction => ({
  type: FETCH_ENTITY_INIT_ACTION,
  payload,
});

// Success action to set the entity data

export const FETCH_ENTITY_SUCCESS_ACTION = 'fetchEntity/SUCCESS';

export interface FetchEntitySuccessActionPayload {
  entityName: string;
  data?: any;
}

export type FetchEntitySuccessAction = PayloadAction<
  typeof FETCH_ENTITY_SUCCESS_ACTION,
  FetchEntitySuccessActionPayload
>;

export const fetchEntitySuccessAction = (payload: FetchEntitySuccessActionPayload): FetchEntitySuccessAction => ({
  type: FETCH_ENTITY_SUCCESS_ACTION,
  payload,
});

// Error action to set the error state

export const FETCH_ENTITY_ERROR_ACTION = 'fetchEntity/ERROR';

export interface FetchEntityErrorActionPayload {
  entityName: string;
  error: ServerErrorResponse;
}

export type FetchEntityErrorAction = PayloadAction<typeof FETCH_ENTITY_ERROR_ACTION, FetchEntityErrorActionPayload>;

export const fetchEntityErrorAction = (payload: FetchEntityErrorActionPayload): FetchEntityErrorAction => ({
  type: FETCH_ENTITY_ERROR_ACTION,
  payload,
});

// Clear entity state

export const RESET_ENTITY_STATE_ACTION = 'resetState/ENTITY';

export interface ResetEntityStateActionPayload {
  entityName: string;
}

export type ResetEntityStateAction = PayloadAction<typeof RESET_ENTITY_STATE_ACTION, ResetEntityStateActionPayload>;

export const resetEntityStateAction = (payload: ResetEntityStateActionPayload): ResetEntityStateAction => ({
  type: RESET_ENTITY_STATE_ACTION,
  payload,
});
