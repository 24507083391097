import axios, { AxiosError, AxiosResponse } from 'axios';
import { AuthenticationLogoutPayload } from '../models/authentication';
import { AuthenticationErrorPayload, AuthenticationSuccessPayload } from '../types';

export interface LoginParams {
  username: string;
  password: string;
}

export interface LoginResponse<T> {
  data: T;
  status: number;
}
/*
 * @deprecated
 * TODO remove when doing Login cleanup
 */
export function loginAction({ username, password }: LoginParams) {
  return new Promise(
    (
      resolve: (payload: LoginResponse<AuthenticationSuccessPayload>) => void,
      reject: (payload: LoginResponse<AuthenticationErrorPayload>) => void,
    ) => {
      axios
        .request({
          url: '/login',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          method: 'post',
          data: {
            username,
            password,
            grant_type: 'password',
          },
        })
        .then((response: AxiosResponse<AuthenticationSuccessPayload>) => {
          const { data, status } = response;
          return resolve({ data, status });
        })
        .catch((error: AxiosError<AuthenticationErrorPayload>) => {
          const { data, status } = error.response!;
          return reject({ data, status });
        });
    },
  );
}

export function logoutRequest() {
  return new Promise(
    (
      resolve: (payload: AuthenticationLogoutPayload) => void,
      reject: (payload: AuthenticationErrorPayload) => void,
    ) => {
      axios
        .request({
          url: '/logout',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          method: 'delete',
        })
        .then((response: AxiosResponse<AuthenticationLogoutPayload>) => {
          const data: AuthenticationLogoutPayload = response.data;
          return resolve(data);
        })
        .catch((response: AxiosResponse<AuthenticationErrorPayload>) => {
          const data: AuthenticationErrorPayload = response.data;
          return reject(data);
        });
    },
  );
}
