import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CodeInput, InlineText, ItemGroup, ModalSection } from 'sb-ui-components';
import { ModalWrapper } from 'src/components/modal-wrapper';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { LOGIN_RESOURCE_NAME } from 'src/modules/login/models/login';
import { confirmCognitoSmsChallenge } from 'src/modules/login/redux/login-actions';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { SMS_CHALLENGE_AMPLIFY_RESOURCE_NAME } from '../model/sms-challenge';
import styles from './sms-challenge-amplify-modal.module.scss';

export interface SmsChallengeAmplifyModalProps {
  isOpen: boolean;
}

export const SmsChallengeAmplifyModal: FunctionComponent<SmsChallengeAmplifyModalProps> = props => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { control, handleSubmit, setValue } = useForm<{ confirmation_value: string }>({
    mode: 'onChange',
  });
  const { isOpen } = props;
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const entityState: EntityState = useSelector((state: AppState) => state.entity);

  // SMS chalenge confirmation state
  const smsChallenge: Entity<any> | undefined = entityState?.smsChallengeAmplify;
  const isChallengeConfirmationPending = smsChallenge?.loading;
  const isSuccess = smsChallenge?.data?.attributes?.status === 'COMPLETED';
  const smsChallangeCreationError = Boolean(smsChallenge?.error);

  const onFormSubmit = (formValues: { confirmation_value: string }) => {
    const { confirmation_value } = formValues;
    dispatch(confirmCognitoSmsChallenge({ challenge: confirmation_value }));
  };

  const resetState = () => {
    // We have to reset the state to start from the beginning when user opens the modal again
    dispatch(resetEntityStateAction({ entityName: LOGIN_RESOURCE_NAME }));
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_AMPLIFY_RESOURCE_NAME }));
  };

  if (isSuccess) {
    resetState();
    return null;
  }

  if (smsChallangeCreationError) {
    return (
      <ModalWrapper
        isOpen={isOpen}
        modalProps={{
          maxWidth: 'xsmall',
          className: styles.modal,
          dataCy: 'modal__sms_challenge',
          align: 'center',
        }}
      >
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.LOGIN_INCORRECT_2FA_ERROR.title' })}
          message={formatMessage({ id: 'error.LOGIN_INCORRECT_2FA_ERROR.message' })}
          cta={
            <Button size="big" type="button" dataCy="cta__resend" handleClick={resetState}>
              {formatMessage({ id: 'button.close' })}
            </Button>
          }
        />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      modalProps={{
        maxWidth: 'xsmall',
        headerTitle: !isSuccess ? formatMessage({ id: 'title.enterSmsCode' }) : undefined,
        className: styles.modal,
        dataCy: 'modal__sms_challenge',
      }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalSection className={styles.content}>
          <ItemGroup justifyItems="center" gutterY="large" fullwidth>
            <>
              <label>
                <InlineText size="small">{formatMessage({ id: 'message.smsChallenge' })}</InlineText>
              </label>
              <CodeInput
                id="confirmation_value"
                length={6}
                control={control}
                autoFocus={true}
                setValue={setValue}
                onComplete={() => setIsFormValid(true)}
                dataCy="confirmation_value_input"
                disabled={isChallengeConfirmationPending}
                errorMessage={smsChallangeCreationError ? formatMessage({ id: 'inputError.smsChallenge' }) : undefined}
              />
            </>
          </ItemGroup>
        </ModalSection>

        <ModalSection>
          <ItemGroup justifyItems={smsChallangeCreationError ? 'center' : 'space-between'}>
            <>
              <Button size="big" appearance="stroked" color="neutral" handleClick={resetState}>
                {formatMessage({ id: 'button.cancel' })}
              </Button>
              <Button
                size="big"
                type="submit"
                disabled={!isFormValid || isChallengeConfirmationPending}
                dataCy="cta__submit_confirmation_value"
                isLoading={isChallengeConfirmationPending}
              >
                {formatMessage({ id: 'button.confirm' })}
              </Button>
            </>
          </ItemGroup>
        </ModalSection>
      </form>
    </ModalWrapper>
  );
};
