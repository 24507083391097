import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DataList, formatApiDate, Header, InlineText, PageSection, SvgIcon } from 'sb-ui-components';
import { ListResourceState } from 'src/types';
import { Postbox, POSTBOX_RESOURCE_NAME } from '../../models/postbox';
import { downloadPostboxAction } from '../../redux/postbox-actions';

export const PostboxList: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const title = formatMessage({ id: 'title.postbox' });
  const postbox: ListResourceState<Postbox> = useSelector((state: any) => state.list[POSTBOX_RESOURCE_NAME]);

  const handleDownloadClick = (event: MouseEvent, item: Postbox) => {
    dispatch(downloadPostboxAction({ id: item.id, fileName: item.attributes.name }));
  };

  if (!postbox) return null;

  return (
    <PageSection dataCy="section__postbox">
      <Header>
        <h2>{title}</h2>
      </Header>
      <DataList
        noValuePlaceHolder={formatMessage({ id: 'message.noDocumentsFound' })}
        errorMessage={postbox.error && formatMessage({ id: 'error.loading' })}
        isLoading={postbox.isLoading}
        list={postbox.list}
        leadingIcon={() => 'FilePdfLight'}
        leadingContent={(item: Postbox) => (
          <InlineText nowrap type="div">
            {item.attributes.name}
          </InlineText>
        )}
        leadingSubline={(item: Postbox) => (
          <InlineText nowrap type="div" size="xsmall" color="soft">
            {formatApiDate(item.attributes.created_at)}
          </InlineText>
        )}
        trailingContent={(item: Postbox) => (
          <Button
            color="neutral"
            handleClick={event => handleDownloadClick(event, item)}
            appearance="no-background"
            size="big"
          >
            <SvgIcon type="DownloadSolid" />
          </Button>
        )}
      />
    </PageSection>
  );
};
