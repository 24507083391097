import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Page, ResponsiveTopHeader, ThemeName, themeNames, ThemeProvider } from 'sb-ui-components';
import { Enum } from 'typescript-string-enums';
import { Footer } from '../../../components/footer';
import { LocaleOption, locales } from '../../../components/localization-wrapper';
import { uiSetLocaleAction, uiSetThemeAction } from '../../../redux/ui/ui-actions';
import { AppState } from '../../../types';
import PrivacyPolicyDE from './locales/privacy-policy-de';
import PrivacyPolicyEN from './locales/privacy-policy-en';
import styles from './privacy-policy-page.module.scss';

const PrivacyPolicyPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const currentLocale = useSelector((state: AppState) => state.ui.locale);
  const handleSetLocale = (newLocale: LocaleOption) => {
    dispatch(uiSetLocaleAction({ locale: newLocale }));
    localStorage.setItem('locale', newLocale);
  };

  // Theme
  const defaultOsTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? themeNames.dark : themeNames.light;
  const currentTheme = (localStorage.getItem('theme') as ThemeName) || defaultOsTheme;
  const handleSetTheme = () => {
    const newTheme = currentTheme === themeNames.dark ? themeNames.light : themeNames.dark;
    dispatch(uiSetThemeAction({ theme: newTheme }));
    localStorage.setItem('theme', newTheme);
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <ResponsiveTopHeader
        theme={currentTheme}
        setTheme={handleSetTheme}
        locales={{
          options: Enum.values(locales).map(locale => ({
            label: formatMessage({ id: `locale.${locale}` }),
            value: locale,
          })),
          currentLocale: currentLocale,
          setLocale: (locale: string) => handleSetLocale(locale as LocaleOption),
        }}
      />
      <Page className={styles.main} maxWidth="medium">
        {currentLocale === 'en' && <PrivacyPolicyEN />}
        {currentLocale === 'de' && <PrivacyPolicyDE />}
      </Page>
      <Footer />
    </ThemeProvider>
  );
};

export default PrivacyPolicyPage;
