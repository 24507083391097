const SENTRY_TESTING_DSN = 'https://7c7723099db4489380b414fb48cf1ffb@sentry.solaris.tools/98';
const SENTRY_STAGING_DSN = 'https://5624a20e849e41aeb7c7b7f1b4a29165@sentry.solaris.tools/65';
const SENTRY_SANDBOX_DSN = 'https://0e6e19be55a04f438efa08b75eb301ba@sentry.solaris.tools/64';
const SENTRY_PRODUCTION_DSN = 'https://c3ec35c77ec941d28b279031e21e98ee@sentry.solaris.tools/63';

export function environmentApiUrl() {
  return `${window.location.origin}/solarisgo-bff/api`;
}

export function getSentryDsn() {
  switch (window.location.host) {
    case 'webui.testing.backoffice-default.aws.solarisbank.io':
      return SENTRY_TESTING_DSN;
    case 'webui.staging.backoffice-default.aws.solarisbank.io':
      return SENTRY_STAGING_DSN;
    case 'webui-ng.solaris-sandbox.de':
      return SENTRY_SANDBOX_DSN;
    case 'webui-ng.solarisbank.de':
      return SENTRY_PRODUCTION_DSN;
    default:
      return '';
  }
}

export function isDevelopment() {
  return window.location.host === 'solarisgo.local:3000';
}
export function isTesting() {
  return window.location.host === 'solarisgo.solaris-testing.com';
}
export function isStaging() {
  return window.location.host === 'solarisgo.solaris-staging.com';
}
export function isSandbox() {
  return window.location.host === 'solarisgo.solaris-sandbox.com';
}
export function isProduction() {
  return window.location.host === 'solarisgo.solarisgroup.com';
}
