import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { ResourceResponse } from 'src/types';
import { resourceRequest } from '../../../../sagas/resource-requests';
import forgotPasswordResourceConfig, {
  FORGOT_PASSWORD_ENDPOINT,
  REQUEST_PASSWORD_RESET_STATE,
} from '../../models/forgot-password';
import { FORGOT_PASSWORD_REQUEST_ACTION, ForgotPasswordRequestAction } from '../../redux/forgot-password-actions';

export function* listenToForgotPassword(): SagaIterator<void> {
  yield takeEvery(FORGOT_PASSWORD_REQUEST_ACTION, handleForgotPassword);
}

export function* handleForgotPassword(action: ForgotPasswordRequestAction): SagaIterator<ResourceResponse<any> | void> {
  const { username } = action.payload;

  // Set loading state
  yield put(fetchEntityInitAction({ entityName: REQUEST_PASSWORD_RESET_STATE }));

  const response: ResourceResponse<any> = yield call(resourceRequest, {
    resourceConfig: forgotPasswordResourceConfig,
    endpoint: FORGOT_PASSWORD_ENDPOINT,
    apiRequestParams: { pathParams: {}, queryParams: {} },
    requestBody: {
      data: {
        type: 'forgot_password',
        attributes: {
          username,
        },
      },
    },
  });

  if (response?.error) {
    yield put(fetchEntityErrorAction({ entityName: REQUEST_PASSWORD_RESET_STATE, error: response.error }));
    return;
  }

  yield put(fetchEntitySuccessAction({ entityName: REQUEST_PASSWORD_RESET_STATE, data: response?.data }));
  return response;
}
