import { BadgeColor, Balance } from 'sb-ui-components';
import { Enum } from 'typescript-string-enums';
import { NullableString, ResourceConfig } from '../../../types';

//  ToDo: This interface is in WIP state. The endpoints are not final yet.

export interface AccountAttributes {
  readonly iban: string;
  readonly bic: NullableString;
  readonly status: AccountStatus;
  readonly account_type: string; // ToDo: Still in WIP state
  readonly block_state: AccountBlockState;
  readonly balance: Balance; // Booked balance
  readonly available_balance: Balance; // Includes the credit line
  readonly reserved_balance: Balance; // Including all reservations
  readonly seizure_protection: SeizureProtection;
  readonly opened_at: string;
}

export interface SeizureProtection {
  readonly current_blocked_amount: Balance;
  readonly protected_amount: Balance;
}

export interface Account {
  readonly type: string;
  readonly id: string;
  readonly attributes: AccountAttributes;
  readonly relationships?: any; // ToDo: still in WIP state
}

// Account block states

export const accountBlockStates = Enum('BLOCK', 'CREDIT_BLOCK', 'DEBIT_BLOCK', 'NO_BLOCK');
export type AccountBlockState = Enum<typeof accountBlockStates>;
export const accountBlockStateColorMap: Record<AccountBlockState, BadgeColor> = {
  BLOCK: 'red',
  CREDIT_BLOCK: 'red',
  DEBIT_BLOCK: 'red',
  NO_BLOCK: 'green',
};

// Account statuses

export const accountStatuses = Enum('ACTIVE', 'INACTIVE');
export type AccountStatus = Enum<typeof accountStatuses>;
export const accountStatusColorMap: Record<AccountStatus, BadgeColor> = {
  ACTIVE: 'green',
  INACTIVE: 'neutral',
};

// Resource config

export const ACCOUNT_RESOURCE_NAME = 'account';

export const ACCOUNT_INDEX_REQ_ACTION = 'solaris_service_account#all';
export const ACCOUNT_SHOW_REQ_ACTION = 'solaris_service_account#find';
export const ACCOUNT_BALANCE_REQ_ACTION = 'solaris_service_account#balance';

export const accountResourceConfig: ResourceConfig = {
  resourceName: ACCOUNT_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/customers/me/accounts',
      httpMethod: 'get',
    },
    show: {
      path: '/accounts/{id}',
      httpMethod: 'get',
    },
  },
};
