import { CARD_BLOCK_ENDPOINT, CARD_UNBLOCK_ENDPOINT } from 'src/modules/card/models/card';

export const de: Record<string, string> = {
  // Account block states
  'accountBlockState.BLOCK': 'Gesperrt',
  'accountBlockState.CREDIT_BLOCK': 'Einzahlungen gesperrt',
  'accountBlockState.DEBIT_BLOCK': 'Auszahlungen gesperrt',

  // Account types (WIP)
  'accountType.CREDITCARD_PERSONAL': 'Credit Card Personal',
  'accountType.CHECKING_BUSINESS': ' Checking Business',
  'accountType.CHECKING_PERSONAL': 'Checking Personal',
  'accountType.CHECKING_SOLE_PROPRIETOR': 'Checking Sole Proprietor',
  'accountType.CHECKING_PERSONAL_OVERDRAFT': 'Checking Personal Overdraft',
  'accountType.BASIC_ACCOUNT': 'Basic Account',
  'accountType.WALLET_PERSONAL': 'Wallet Personal',
  'accountType.WALLET_BUSINESS': 'Wallet Business',
  'accountType.EMONEY_PREPAID': 'Emoney Prepaid',
  'accountType.WALLET_INSTITUTIONAL': 'Wallet Institutional',
  'accountType.DECOUPLED_CARD_PERSONAL': 'Decoupled Card Personal',
  'accountType.DECOUPLED_CARD_BUSINESS': 'Decoupled Card Business',
  'accountType.WALLET_PERSONAL_PAYMENT_ACC': 'Wallet Personal Payment',
  'accountType.CREDITCARD_BUSINESS': 'Credit Card Business',
  'accountType.CREDITCARD_FREELANCER': 'Credit Card Freelancer',
  'accountType.CHECKING_SUBACCOUNT': 'Checking Subaccount',
  'accountType.CALL_MONEY': 'Call Money',
  'accountType.CURRENCY_CUSTOMER_ACCOUNT': 'Currency Customer Account',
  'accountType.SAVINGS_PERSONAL': 'Savings Personal',
  'accountType.SAVINGS_BUSINESS': 'Savings Business',
  'accountType.SAVINGS_SOLE_PROPRIETOR': 'Savings Sole Proprietor',

  // Button labels
  'button.actions': 'Aktionen',
  'button.approve': 'Bestätigen',
  'button.back': 'Zurück',
  'button.backToLogin': 'Zurück zur Anmeldung',
  'button.betweenMyAccounts': 'Zwischen eigenen Konten',
  'button.block': 'Sperren',
  'button.cancel': 'Abbrechen',
  'button.changePin': 'PIN ändern',
  'button.close': 'Schließen',
  'button.confirm': 'Bestätigen',
  'button.createAccount': 'Konto erstellen',
  'button.decline': 'Ablehnen',
  'button.details': 'Details',
  'button.forgotPassword': 'Passwort vergessen?',
  'button.goToAccounts': 'Go to accounts',
  'button.goToLogin': 'Zur Anmeldung',
  'button.login': 'Anmelden',
  'button.makeTransfer': 'Überweisen',
  'button.next': 'Weiter',
  'button.ok': 'OK',
  'button.phoneNumberVerification': 'Telefonnummer bestätigen',
  'button.requestHelp': 'Hilfe anfordern',
  'button.requestPasswordReset': 'Passwort zurücksetzen',
  'button.resendCode': 'Neuen Code anfordern',
  'button.resetPassword': 'Passwort zurücksetzen',
  'button.restart': 'Neustart',
  'button.send': 'Senden',
  'button.sepaTransfer': 'SEPA Überweisung',
  'button.signOut': 'Abmelden',
  'button.toReferenceAccount': 'Auf Referenzkonto',
  'button.transfer': 'Überweisen',
  'button.transferNow': 'Jetzt überweisen',
  'button.tryAgain': 'Erneut versuchen',
  'button.unblock': 'Entsperren',
  'button.verify': 'Verifizieren',
  'button.verifyAgain': 'Erneut verifizieren',
  'button.viewMyAccounts': 'Meine Konten anzeigen',

  // Card types
  'cardType.MASTERCARD_BUSINESS_DEBIT': 'Mastercard Debit',
  'cardType.MASTERCARD_CREDIT': 'Mastercard Debit',
  'cardType.MASTERCARD_DEBIT': 'Mastercard Debit',
  'cardType.VIRTUAL_MASTERCARD_BUSINESS_DEBIT': 'Mastercard Debit (virtuell)',
  'cardType.VIRTUAL_MASTERCARD_DEBIT': 'Mastercard Debit (virtuell)',
  'cardType.VIRTUAL_MASTERCARD_FREELANCE_DEBIT': 'Mastercard Debit (virtuell)',
  'cardType.VIRTUAL_VISA_BUSINESS_CREDIT': 'VISA Credit (virtuell)',
  'cardType.VIRTUAL_VISA_BUSINESS_DEBIT': 'VISA Debit (virtuell)',
  'cardType.VIRTUAL_VISA_CREDIT': 'VISA Credit (virtuell)',
  'cardType.VIRTUAL_VISA_DEBIT': 'VISA debit (virtuell)',
  'cardType.VIRTUAL_VISA_FREELANCE_DEBIT': 'VISA Debit (virtuell)',
  'cardType.VISA_BUSINESS_CREDIT': 'VISA Credit',
  'cardType.VISA_BUSINESS_DEBIT': 'VISA Debit',
  'cardType.VISA_CREDIT': 'VISA Credit',
  'cardType.VISA_DEBIT': 'VISA Debit',

  // Hint messages
  'hint.maxLimit': '(max. {limit} Zeichen)',
  'hint.noChallengeReceivedYet': 'Keinen Code erhalten?',

  // Error messages
  'error.general.message':
    'Wir entschuldigen uns für die Unannehmlichkeiten. Unser Team arbeitet an der Behebung des Problems. Bitte versuchen Sie es in ein paar Minuten noch einmal.',
  'error.general.title': 'Es tut uns leid, etwas ist schiefgelaufen.',
  'error.loading.account':
    'Ihre Kontoinformationen konnten nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
  'error.loading': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.',
  'error.LOGIN_INCORRECT_CREDENTIALS_ERROR': 'Name oder Passwort falsch',
  'error.LOGIN_INCORRECT_2FA_ERROR.message': 'Bitte schließen Sie den Dialog und melden Sie sich erneut an.',
  'error.LOGIN_INCORRECT_2FA_ERROR.title': 'Ungültiger Code.',
  'error.tokenExpired.title': 'Zeit abgelaufen',
  'error.tokenExpired.message':
    'Aus Sicherheitsgründen beschränken wir den Zeitrahmen nach Ihrer Identitätsprüfung, in dem Sie Ihre Anmeldedaten erstellen können. Diese Zeit ist abgelaufen. Um fortzufahren, wiederholen Sie bitte den Verifizierungsschritt.',

  // Input errors
  'inputError.alphanumeric': 'Bitte vermeiden Sie Sonderzeichen und Umlaute.',
  'inputError.amount.zero': 'Der Betrag muss mindestens 0.01 betragen.',
  'inputError.amount.tooHigh': 'Ihnen stehen nur {amount} zur Verfügung.',
  'inputError.emailNotAllowed': 'Eine E-Mail-Adresse ist als Benutzername nicht erlaubt.',
  'inputError.invalidIban': 'Bitte geben Sie eine gültige IBAN ein.',
  'inputError.invalidFourDigit': 'Bitte geben Sie die letzten vier Ziffern der Mobiltelefonnummer ein.',
  'inputError.minLength': 'Muss mindestens {length} Zeichen lang sein.',
  'inputError.maxLength': 'Darf maximal {length} Zeichen lang sein.',
  'inputError.notEmail': 'Wir meinen Ihren Benutzernamen, nicht Ihre E-Mail-Adresse.',
  'inputError.password.length': 'Das Passwort muss mindestens 8 Zeichen lang sein.',
  'inputError.password.match': 'Die Passwörter stimmen nicht überein. Bitte versuchen Sie es erneut.',
  'inputError.password.required': 'Bitte geben Sie ein gültiges Passwort ein.',
  'inputError.pin.THREE_EQUAL_DIGITS_IN_ROW':
    'Drei gleiche Ziffern hintereinander sind zu leicht zu erraten. Bitte wählen Sie eine andere PIN.',
  'inputError.pin.SEQUENTIAL_DIGITS':
    'Aufeinanderfolgende Ziffern sind zu leicht zu erraten. Bitte wählen Sie eine andere PIN.',
  'inputError.pin.CONFIRMATION_MISSMATCH':
    'Offenbar haben Sie im Bestätigungsfeld eine andere PIN eingegeben. Bitte versuche es erneut.',
  'inputError.required': 'Dies ist ein Pflichtfeld.',
  'inputError.recipient.required': 'Bitte wählen Sie ein Empfängerkonto.',
  'inputError.smsChallenge': 'Ungültiger Code. Bitte versuchen Sie es erneut.',

  // Labels
  'label.accountHolder': 'Kontoinhaber',
  'label.address': 'Adresse',
  'label.amount': 'Betrag',
  'label.availableBalance': 'Verfügbarer Kontostand',
  'label.balance': 'Kontostand',
  'label.bic': 'BIC',
  'label.birthDate': 'Geburtsdatum',
  'label.bookedBalance': 'Gebuchter Kontostand',
  'label.cardHolder': 'Karteninhaber',
  'label.cardNo': 'Karten-Nr.',
  'label.confirmPassword': 'Passwort bestätigen',
  'label.description': 'Verwendungszweck',
  'label.email': 'E-Mail',
  'label.expDate': 'Ablaufdatum',
  'label.iban': 'IBAN',
  'label.inflow': 'Zufluss',
  'label.fourDigit': 'Letzte 4 Ziffern der registrierten Mobiltelefonnummer',
  'label.lostOrStolen': 'Verloren oder gestohlen',
  'label.mandateNumber': 'Mandat-Nr.',
  'label.mobileNumber': 'Handynummer',
  'label.showOnlyActiveAccounts': 'Nur aktive Konten anzeigen',
  'label.name': 'Name',
  'label.openedAt': 'Eröffnet am',
  'label.outflow': 'Abfluss',
  'label.password': 'Passwort',
  'label.phone': 'Telefon',
  'label.phoneNumberVerification':
    'Bitte halten Sie Ihr Telefon bereit. Im nächsten Schritt bitten wir Sie um die Eingabe eines Codes, den wir Ihnen per SMS zusenden.',
  'label.placeOfBirth': 'Geburtsort',
  'label.poolId': 'Pool ID',
  'label.protectedAmount': 'Freibetrag',
  'label.recipient': 'Empfänger',
  'label.reportingReason': 'Meldungsgrund',
  'label.reservedBalance': 'Reservierter Kontostand',
  'label.reserved': 'Vorgemerkt',
  'label.seizedAmount': 'Pfändungsbetrag',
  'label.subTopic': 'Unterthema',
  'label.taxCountry': 'Steuerpflichtiges Land',
  'label.taxId': 'Steuer Nummer',
  'label.topic': 'Thema',
  'label.username': 'Benutzername',

  // Locales
  'locale.en': 'Englisch (EN)',
  'locale.de': 'Deutsch (DE)',

  // Messages
  'message.3ds.check': 'Bitte prüfen Sie Ihre ausstehende Zahlung innerhalb des Zeitrahmens:',
  'message.3ds.hint': 'Nach Ablauf der Frist wird die Überweisung abgelehnt.',
  'message.amountAvailable': '{amount} verfügbar',
  'message.balance': 'Dieser Kontostand beinhaltet keine vorgemerkten Umsätze oder Überziehunglimits.',
  'message.dearCustomer': 'Liebe Kundin, lieber Kunde',
  'message.forgotPassword':
    'Bitte geben Sie Ihre für die Registrierung verwendete E-Mail-Adresse ein. Sie erhalten in Kürze eine E-Mail zum Zurücksetzen Ihres Passwortes.',
  'message.forgotPasswordRequested':
    'Wir haben Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet.',
  'message.inclOverdraftCredit': 'Inkl. Überziehung und Kreditlimit',
  'message.loadingDocument': 'Dokument wird geladen …',
  'message.logoutTimer': 'Automatische Abmeldung in: ',
  'message.noAccountsFound': 'Keine Konten gefunden.',
  'message.noDocumentsFound': 'Keine Dokumente gefunden.',
  'message.noReferenceAccountsFound': 'Keine Referenzkonten gefunden.',
  'message.noTransactionsFound': 'Noch keine Umsätze vorhanden.',
  'message.pin.new': 'Bitte geben Sie eine neue PIN ein.',
  'message.pin.confirm': 'Bitte bestätigen Sie die neue PIN.',
  'message.setPassword':
    'Bitte geben Sie ein Passwort für Ihr Konto ein. Ihr Passwort sollte mindestens 8 Zeichen lang sein.',
  'message.smsChallenge':
    'Bitte geben Sie den Code ein, den wir Ihnen per SMS an Ihre registrierte Mobil-Telefonnummer gesendet haben.',
  'message.smsChallenge.loading': 'SMS wird versendet …',
  'message.setCredentials':
    'Bitte geben Sie einen eindeutigen Benutzernamen und ein Passwort ein, um Ihre Registrierung abzuschließen. Das Passwort sollte mindestens 8 Zeichen lang sein.',
  'message.signedIn': 'Sie sind angemeldet.',
  'message.verifyEmail':
    'Nach erfolgreicher Prüfung können Sie einen Benutzernamen und ein Passwort erstellen, um auf Ihr Solaris-Konto zuzugreifen. Bitte beachten Sie: Ihr Solaris-Konto wurde möglicherweise über unseren Partner erstellt.',
  'message.welcomePageMessage1':
    'SolarisGo bietet Ihnen die Möglichkeit, auf Ihr Guthaben zuzugreifen und bestimmte Banking-Funktionen weiterhin zu nutzen.',
  'message.welcomePageMessage2':
    'Anmeldeinformationen werden von Solaris in einem Fallback-Szenario ausgestellt. Bitte beachten Sie, dass Solaris keine Anmeldeinformationen auf individuelle Anfrage ausstellt.',

  // Request support

  'requestSupport.descriptionError':
    'Die maximale Zeichenanzahl von 1.600 wurde überschritten. Bitte kürzen Sie Ihre Nachricht.',
  'requestSupport.successMessage': 'Wir haben Ihre Anfrage erhalten.',
  'requestSupport.successMessageDescription':
    'Wir werden uns so schnell wie möglich um Ihr Anliegen kümmern und Sie per E-Mail an die uns bekannte Adresse kontaktieren.',

  'requestSupport.subject.accountManagement': 'Kontoverwaltung',
  'requestSupport.subject.cardManagement': 'Kartenverwaltung',
  'requestSupport.subject.paymentTransferWithdrawals': 'Zahlungen, Überweisungen & Auszahlungen',
  'requestSupport.subject.personalDetails': 'Persönliche Daten',
  'requestSupport.subject.statements': 'Kontoauszüge',
  'requestSupport.subject.seizures': 'Pfändungen',
  'requestSupport.other': 'Sonstiges',
  'requestSupport.subTopic.accountManagement.accountClosure': 'Kontoauflösung',
  'requestSupport.subTopic.accountManagement.changeOfLinkedAccount': 'Änderung des verknüpften Kontos',
  'requestSupport.subTopic.cardManagement.chargeback': 'Chargeback',
  'requestSupport.subTopic.cardManagement.cardBlock': 'Kartensperre',
  'requestSupport.subTopic.cardManagement.cardIssueOrDecline': 'Kartenanfrage oder -ablehnung',
  'requestSupport.subTopic.paymentTransferWithdrawals.payoutRequest': 'Auszahlungsanfrage',
  'requestSupport.subTopic.paymentTransferWithdrawals.transactionInvestigation': 'Transaktionsuntersuchung',
  'requestSupport.subTopic.paymentTransferWithdrawals.sddRecall': 'SDD widerrufen',
  'requestSupport.subTopic.statements.accountConfirmation': 'Konto-Bestätigung',
  'requestSupport.subTopic.statements.paymentConfirmation': 'Zahlungsbestätigung',
  'requestSupport.subTopic.statements.balanceConfirmation': 'Kontostandsbestätigung',
  'requestSupport.subTopic.statements.bankStatement': 'Bankauszug',

  // Account Reservation types
  'reservationType.CARD_AUTHORIZATION': 'Kartenautorisierung',
  'reservationType.SEIZURE': 'Pfändung',
  'reservationType.SEPA_CREDIT_TRANSFER': 'SEPA-Überweisung',
  'reservationType.SEPA_INSTANT_CREDIT_TRANSFER': 'SEPA-Sofortüberweisung',
  'reservationType.OTHER': 'Sonstige',

  // Statuses
  'status.ARCHIVED': 'Geschlossen', // This is on purpose to simplify it for the customer
  'status.ACTIVE': 'Aktiv',
  'status.BLOCKED': 'Gesperrt',
  'status.BLOCKED_BY_SOLARIS': 'Gesperrt',
  'status.CLOSED_BY_SOLARIS': 'Geschlossen',
  'status.INACTIVE': 'Inaktiv',
  'status.OPEN': 'Offen',
  'status.PENDING': 'Ausstehend',
  'status.PROCESSING': 'In Bearbeitung',
  'status.REPORTED': 'Gemeldet',
  'status.RESOLVED': 'Gelöst',

  // Success messages
  'success.3ds.title': 'Zahlung erfolgreich bestätigt.',
  'success.3ds.message': 'Sie können nun zu Ihrem Händler zurückkehren.',
  [`success.${CARD_BLOCK_ENDPOINT}`]: 'Karte erfolgreich gesperrt.',
  [`success.${CARD_UNBLOCK_ENDPOINT}`]: 'Karte erfolgreich entsperrt.',
  'success.credentials.title': 'Ihre Anmeldedaten wurden erfolgreich erstellt.',
  'success.credentials.message': 'Sie können sich jetzt mit Ihrem Benutzernamen und Passwort anmelden.',
  'success.emailVerification.message':
    'Vielen Dank für die Zusendung Ihrer Informationen. Nach erfolgreicher Prüfung erhalten Sie eine Nachrich mit weiteren Schritten an Ihre registrierte E-Mail-Adresse.',
  'success.passwordReset.message': 'Sie können sich jetzt mit Ihrem neuen Passwort anmelden.',
  'success.passwordReset.title': 'Passwort erfolgreich geändert',
  'success.phoneVerification.title': 'Verifizierung erfolgreich.',
  'success.phoneNumberVerification.message':
    'Wir haben eine Nachricht an Ihre registrierte E-Mail-Adresse gesendet. Bitte nutzen Sie den dort genannten Link, um Ihren Benutzernamen und Ihr Passwort einzurichten.',
  'success.pinChange': 'Ihre PIN wurde erfolgreich geändert.',
  'success.transfer.title': 'Wir haben Ihren Überweisungsauftrag erhalten.',
  'success.transfer.message':
    'Es kann bis zu 2 Geschäftstage dauern, bis der Betrag dem Empfänger-Konto gutgeschrieben wurde.',

  // Titles
  'title.account': 'Konto',
  'title.accounts': 'Konten',
  'title.accountBalance': 'Guthaben',
  'title.cards': 'Karten',
  'title.changeCardPin': 'Karten-PIN ändern',
  'title.checkEmails': 'Bitte überprüfen Sie Ihre E-Mails.',
  'title.contact': 'Kontakt',
  'title.customerSupport': 'Kundenservice',
  'title.details': 'Details',
  'title.enterSmsCode': 'Mit SMS-Code bestätigen',
  'title.imprint': 'Impressum',
  'title.information': 'Information',
  'title.login': 'Anmelden',
  'title.logout': 'Sie werden abgemeldet …',
  'title.ownAccount': 'Eigenes Konto',
  'title.personalData': 'Persönliche Daten',
  'title.phoneNumberVerification': 'Bestätigen Sie Ihre Telefonnummer',
  'title.privacyPolicy': 'Datenschutz',
  'title.profile': 'Profil',
  'title.postbox': 'Briefkasten',
  'title.referenceAccount': 'Referenzkonto',
  'title.referenceAccounts': 'Referenzkonten',
  'title.seizureInformation': 'Pfändung',
  'title.requestSupport': 'Support anfordern',
  'title.resetPassword': 'Passwort zurücksetzen',
  'title.service': 'Service',
  'title.setCredentials': 'Anmeldeinformationen festlegen',
  'title.setNewPassword': 'Neues Passwort festlegen',
  'title.signOut': 'Abmelden',
  'title.solarisgo': 'Solaris Go',
  'title.sepaTransfer': 'SEPA Überweisung',
  'title.summary': 'Zusammenfassung',
  'title.taxInformation': 'Steuer Information',
  'title.termsOfUse': 'Nutzungsbedingungen',
  'title.transactions': 'Umsätze',
  'title.transfer.confirm': 'Überweisung bestätigen',
  'title.transfer.create': 'Neue Überweisung',
  'title.transfer.originAccount': 'Zu belastendes Konto',
  'title.transfer.recipient': 'Empfänger',
  'title.verifyIdentity': 'Verifizieren Sie sich jetzt',
  'title.welcome': 'Willkommen',

  // Transaction categories
  'transactionCategory.AIR_TRAVEL': 'Flugreisen',
  'transactionCategory.AUTHORITIES': 'Behörden',
  'transactionCategory.AUTOMOBILE_ASSOCIATION': 'Automobilverband',
  'transactionCategory.CAR_AND_ACCESSORIES': 'Auto und Zubehör',
  'transactionCategory.CAR_RENTAL': 'Autovermietung',
  'transactionCategory.ENTERTAINMENT': 'Unterhaltung',
  'transactionCategory.FUEL_STATION': 'Tankstelle',
  'transactionCategory.GROCERY_STORE': 'Lebensmittel',
  'transactionCategory.HOTEL': 'Hotel',
  'transactionCategory.MEDICAL': 'Health',
  'transactionCategory.MISC_SERVICES': 'Sonstige Dienstleistungen',
  'transactionCategory.MOBILITY': 'Mobilität',
  'transactionCategory.OTHER': 'Sonstiges',
  'transactionCategory.PAYMENT': 'Zahlung',
  'transactionCategory.PERSONAL_SERVICES': 'Persönliche Dienstleistungen',
  'transactionCategory.RESTAURANT': 'Restaurant',
  'transactionCategory.SHOPPING': 'Einkaufen',
  'transactionCategory.TRAVEL_AGENCY': 'Reisebüro',

  // Urls

  'url.customerSupport': 'https://support.solarisgroup.com/hc/de',
  'url.imprint': 'https://www.solarisgroup.com/de/imprint/',
  'url.termsOfUse': 'https://www.solarisgroup.com/de/terms-of-use/',

  // Service and ZD
  'service.contactSupport':
    'Bei Fragen wenden Sie sich bitte an unser Service-Team. Wir werden so schnell wie möglich per E-Mail antworten.',
  // Misc
  copyright: '© Solaris SE. Alle Rechte vorbehalten.',
  loading: 'Lädt …',
};
