import { PayloadAction } from 'src/types';
import { ApiRequestParams } from '../../lib/api';
import { ListResourceName } from '../list/list-reducer';

export const LIST_SKIP_PAGE_ACTION = 'list/SKIP_PAGE';
export const LIST_FILTER_AND_UID_SEARCH_ACTION = 'list/FILTER_AND_UID_SEARCH';

// Skip page

export interface SkipPageActionPayload {
  readonly paginationSaga: (params: ApiRequestParams) => void;
  readonly resourceName: string;
  readonly pageNumber: number;
  readonly navigate?: boolean;
}

export type SkipListPageAction = PayloadAction<typeof LIST_SKIP_PAGE_ACTION, SkipPageActionPayload>;

export const skipPageAction = (payload: SkipPageActionPayload): SkipListPageAction => ({
  type: LIST_SKIP_PAGE_ACTION,
  payload,
});

// Filter and uid search

export interface FilterListAndUidSearchActionPayload {
  readonly resourceName: ListResourceName;
  readonly filteringSaga: (params: ApiRequestParams) => void;
  readonly values: Record<string, string>;
  readonly pathParams?: {};
  readonly queryParams?: {};
  readonly navigate?: boolean;
}

export type FilterListAndUidSearchAction = PayloadAction<
  typeof LIST_FILTER_AND_UID_SEARCH_ACTION,
  FilterListAndUidSearchActionPayload
>;

export const filterListAndUidSearchAction = (
  payload: FilterListAndUidSearchActionPayload,
): FilterListAndUidSearchAction => ({
  type: LIST_FILTER_AND_UID_SEARCH_ACTION,
  payload,
});
