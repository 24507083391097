import { confirmSignIn, ConfirmSignInOutput, fetchAuthSession, signIn, SignInOutput } from '@aws-amplify/auth';
import { AuthSession } from '@aws-amplify/core/dist/esm/singleton/Auth/types';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import authentication from 'src/models/authentication';
import { LOGIN_RESOURCE_NAME } from 'src/modules/login/models/login';
import {
  ConfirmSmsChallengeAction,
  LOGIN_CONFIRM_SMS_CHALLENGE_ACTION,
  LOGIN_WITH_AMPLIFY_REQUEST_ACTION,
  LoginWithAmplifyRequestAction,
} from 'src/modules/login/redux/login-actions';
import { SMS_CHALLENGE_AMPLIFY_RESOURCE_NAME } from 'src/modules/sms-challenge-amplify/model/sms-challenge';
import {
  SMS_CHALLENGE_AUTH_CONFIRMATION_STATE,
  SMS_CHALLENGE_AUTH_RESOURCE_NAME,
} from 'src/modules/sms-challenge-auth/model/sms-challenge-auth';
import { setAuthStateAction } from 'src/redux/auth/auth-actions';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
  resetEntityStateAction,
} from 'src/redux/entity/entity-actions';
import { forwardTo, goToLocationOfLastSession } from 'src/sagas/authentication';

export function* listenToLoginWithAmplifySubmit(): SagaIterator<void> {
  yield takeEvery(LOGIN_WITH_AMPLIFY_REQUEST_ACTION, handleLoginWithAmplifySubmit);
}
export function* handleLoginWithAmplifySubmit(action: LoginWithAmplifyRequestAction): SagaIterator<void> {
  const { username, password } = action.payload;

  // Check if user is already logged in
  const isLoggedIn: boolean = yield call(checkLoginStatus);
  if (isLoggedIn) {
    yield call(forwardTo, '/accounts');
  }

  // Set loading state
  yield put(
    fetchEntityInitAction({
      entityName: LOGIN_RESOURCE_NAME,
    }),
  );

  try {
    const cognitoResponse: SignInOutput = yield call(signIn, {
      username,
      password,
      options: {
        authFlowType: 'CUSTOM_WITH_SRP',
      },
    });
    yield put(
      fetchEntitySuccessAction({
        entityName: LOGIN_RESOURCE_NAME,
        data: cognitoResponse,
      }),
    );
  } catch (e) {
    yield put(
      fetchEntityErrorAction({
        entityName: LOGIN_RESOURCE_NAME,
        error: { message: 'LOGIN_INCORRECT_CREDENTIALS_ERROR', status: 400 },
      }),
    );
  }
}

export function* listenToConfirmCognitoSmsChallenge(): SagaIterator<void> {
  yield takeEvery(LOGIN_CONFIRM_SMS_CHALLENGE_ACTION, handleConfirmCognitoSmsChallenge);
}

export function* handleConfirmCognitoSmsChallenge(action: ConfirmSmsChallengeAction): SagaIterator<void> {
  const {
    payload: { challenge },
  } = action;
  // Set loading state
  yield put(
    fetchEntityInitAction({
      entityName: SMS_CHALLENGE_AMPLIFY_RESOURCE_NAME,
    }),
  );
  try {
    const response: ConfirmSignInOutput = yield call(confirmSignIn, { challengeResponse: challenge });
    if (response.isSignedIn) {
      // Set success state
      yield put(
        fetchEntitySuccessAction({
          entityName: SMS_CHALLENGE_AMPLIFY_RESOURCE_NAME,
          // setting the sms challenge status manually here
          // it is a temporary workaround since we are using amplify
          // and the actual response looks differently
          data: { attributes: { status: 'COMPLETED' } },
        }),
      );
      yield put(setAuthStateAction({ isAuthorized: true }));
      yield call(goToLocationOfLastSession, authentication.getLastLocation());
      // clearing entity state for sms challenge to not show random modal on account index page
      yield put(resetEntityStateAction({ entityName: LOGIN_RESOURCE_NAME }));
      yield put(resetEntityStateAction({ entityName: SMS_CHALLENGE_AMPLIFY_RESOURCE_NAME }));
    }
  } catch (e) {
    yield put(
      fetchEntityErrorAction({
        entityName: SMS_CHALLENGE_AMPLIFY_RESOURCE_NAME,
        error: { message: 'LOGIN_INCORRECT_2FA_ERROR', status: 400 },
      }),
    );
    yield put(setAuthStateAction({ isAuthorized: false }));
  }
}

export function* checkLoginStatus(): SagaIterator<boolean> {
  // TODO this skip can and should be removed when using identity-broker
  // problem is: we are checking against aws which we cannot easily mock
  // since we are going to replace this implementation anyway
  // when running cypress specs locally, you start with yarn start-skip-login
  const skipAuth =
    document.location.hostname === 'solarisgo.solaris-ci' || process.env['REACT_APP_SKIP_LOGIN'] === 'true';
  let isAuthorized: boolean = skipAuth || false;
  let idToken: string | undefined;
  try {
    if (!skipAuth) {
      const result: AuthSession = yield call(fetchAuthSession);
      idToken = result.tokens?.idToken?.toString();
      isAuthorized = Boolean(idToken && idToken?.length > 0);
    }
  } catch (err) {
    isAuthorized = false;
  } finally {
    yield put(setAuthStateAction({ isAuthorized, idToken }));
    return isAuthorized;
  }
}

// This saga clears the error state of login resource to clear the error message displayed in the form
export function* loginPageEnter(): SagaIterator<void> {
  yield put(resetEntityStateAction({ entityName: LOGIN_RESOURCE_NAME }));
  yield put(resetEntityStateAction({ entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME }));
  yield put(resetEntityStateAction({ entityName: SMS_CHALLENGE_AUTH_CONFIRMATION_STATE }));
}
