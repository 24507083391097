import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import { AppState } from 'src/types';
import {
  POST_CONFIRM_CONFIRMATION_AUTH_VALUE_ENDPOINT,
  SMS_CHALLENGE_AUTH_CONFIRMATION_STATE,
  smsChallengeAuthResourceConfig,
} from '../../model/sms-challenge-auth';
import {
  CONFIRM_SMS_CHALLENGE_AUTH,
  ConfirmSmsChallengeAuthAction,
  ConfirmSmsChallengeAuthActionPayload,
} from '../../redux/sms-challenge-auth-actions';

export function* listenToConfirmSmsChallengeAuth(): SagaIterator<void> {
  yield takeLatest(CONFIRM_SMS_CHALLENGE_AUTH, handleConfirmSmsChallengeAuth);
}

export function* handleConfirmSmsChallengeAuth(action: ConfirmSmsChallengeAuthAction): SagaIterator<void> {
  // Set loading state
  yield put(fetchEntityInitAction({ entityName: SMS_CHALLENGE_AUTH_CONFIRMATION_STATE }));

  // Confirm challenge
  const response = yield call(confirmSmsChallengeAuth, action.payload);
  if (response.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: SMS_CHALLENGE_AUTH_CONFIRMATION_STATE,
        error: response.error,
      }),
    );
    return;
  }

  // Remove loading state
  yield put(
    fetchEntitySuccessAction({
      entityName: SMS_CHALLENGE_AUTH_CONFIRMATION_STATE,
      data: response.data,
    }),
  );
}

export function* confirmSmsChallengeAuth(args: ConfirmSmsChallengeAuthActionPayload): SagaIterator<void> {
  const { formValues, pathParams } = args;
  const requestBody = {
    data: {
      type: 'challenge_confirmation',
      attributes: formValues,
    },
  };

  const token = yield select((state: AppState) => state.auth.idToken);

  return yield call(resourceRequest, {
    resourceConfig: smsChallengeAuthResourceConfig,
    endpoint: POST_CONFIRM_CONFIRMATION_AUTH_VALUE_ENDPOINT,
    apiRequestParams: {
      pathParams,
      queryParams: {},
      // We need to send the temporary token here which was passed via
      // URL param and stored in state, since user is about to register
      // and not logged in yet
      headerParams: { Authorization: `Bearer ${token}` },
    },
    requestBody,
  });
}
