import { JWK } from 'jose';
import * as jose from 'jose';

/**
 * Compare https://docs.solarisgroup.com/api-reference/digital-banking/cards/#tag/Encrypted-PIN-Change
 * Copied over from ADAC: https://github.solarisbank.de/b2c-banking/adac-banking-web/blob/27950e2f3eb6f4c86d48fc4f8eb576770656d17d/src/solaris/lib/utils/encrypt-secret.ts
 */
export async function encryptSecretWithJwk(secret: string, jwk: JWK): Promise<string> {
  const encodedSecret = new TextEncoder().encode(`{"pin":"${secret}"}`);

  /**
   * TODO: Remove this once the Solaris API supports it
   * Hopefully the Solaris docs shows the wrong algorithm
   * Should the algorithm be correct, then we can't use it for encryption, because the WebCrypto API doesn't support it
   * RS256 maps to RSASSA-PKCS1-v1_5 using SHA-256 which can only be used for signing
   * Encryption is only possible with RSASSA-PKCS1-v1_5 in node.js but not in browser javascript
   *
   * Compare
   * - https://help.solarisbank.de/display/CSADAC/Card+Management+-+Change+PIN
   * - https://www.w3.org/2012/webcrypto/WebCryptoAPI-20142503/Overview.html#jwk-mapping
   * - https://www.w3.org/2012/webcrypto/WebCryptoAPI-20142503/Overview.html#algorithms-index
   * */
  if (jwk.alg === 'RS256') {
    // TODO: improve error handling
    // Please be aware the following line changes the eslint rule for the _whole file_
    /* eslint no-console: "warn" */
    // eslint-disable-next-line no-console
    console.warn('Invalid JWK Algorithm RS256 - check API');
    jwk.alg = 'RSA-OAEP-256';
  }

  const publicKey = await jose.importJWK(jwk);

  const encryptedSecret = await new jose.CompactEncrypt(encodedSecret)
    .setProtectedHeader({
      alg: 'RSA-OAEP-256',
      enc: 'A256CBC-HS512',
      kid: jwk.kid,
    })
    .encrypt(publicKey);

  return encryptedSecret.toString();
}
