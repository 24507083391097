import { ResourceConfig } from 'src/types';

export const SMS_CHALLENGE_AUTH_RESOURCE_NAME = 'smsChallengeAuth';
export const SMS_CHALLENGE_AUTH_CONFIRMATION_STATE = 'smsChallengeAuthConfirmation';
export const POST_CREATE_SMS_CHALLENGE_AUTH_ENDPOINT = 'post/triggerSmsChallengeAuth';
export const POST_CONFIRM_CONFIRMATION_AUTH_VALUE_ENDPOINT = 'post/confirmSmsCodeAuth';

export const smsChallengeAuthResourceConfig: ResourceConfig = {
  resourceName: SMS_CHALLENGE_AUTH_RESOURCE_NAME,
  endpoints: {
    [POST_CREATE_SMS_CHALLENGE_AUTH_ENDPOINT]: {
      path: '/auth/challenges',
      httpMethod: 'post',
    },
    [POST_CONFIRM_CONFIRMATION_AUTH_VALUE_ENDPOINT]: {
      path: '/auth/challenges/{challengeId}/confirmations',
      httpMethod: 'post',
    },
  },
};
