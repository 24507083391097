import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from 'src/redux/root/root-reducer';
import { AppState } from './../types';

export const sagaMiddleware = createSagaMiddleware();
// @ts-ignore - we ignore this access exceptionally
const composeEnhancer = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;
export const store = createStore<AppState, any, any, any>(
  rootReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware)),
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
