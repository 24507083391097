import invariant from 'invariant';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getRoutes } from 'src/config';
import { Customer } from 'src/modules/customer/models/customer';
import { showCustomer } from 'src/modules/customer/sagas/customer-show';
import { checkLoginStatus } from 'src/modules/login/sagas';
import { initialPageEnterDone } from 'src/redux/boot/boot-actions';
import { clearAllListsAction } from 'src/redux/list/list-actions';
import { RoutesConfig } from 'src/router/lib/routes-config';
import { ON_ENTER_ROUTE_ACTION, OnEnterRouteAction } from 'src/router/redux/router-actions';
import { AppState } from 'src/types';

export function* runSaga(action: OnEnterRouteAction) {
  const { payload } = action;
  const routes: RoutesConfig = getRoutes();

  if (payload && payload.routeName) {
    invariant(routes[payload.routeName], `No configuration found for ${payload.routeName}.`);
    const saga = routes[payload.routeName].saga;
    if (saga) {
      try {
        yield call(saga, {
          queryParams: payload.queryParams,
          pathParams: payload.pathParams,
        });
      } catch (err) {}
    }
  }
}

export function* listenToRouteHooks() {
  yield takeEvery(ON_ENTER_ROUTE_ACTION, handlePageEnter);
}

export function* handlePageEnter(action: OnEnterRouteAction) {
  yield put(clearAllListsAction({ resetFiltersAndPages: true }));
  const isLoggedIn: boolean = yield call(checkLoginStatus);

  if (isLoggedIn) {
    const customerData: Customer = yield select((state: AppState) => state.entity?.customer?.data);

    if (!customerData) {
      yield call(showCustomer);
    }
  }
  // Some page enter sagas depend on the customer data being loaded before
  yield call(runSaga, action);
  yield put(initialPageEnterDone());
}

export function* selectInitialPageLoadStatus(): SagaIterator<boolean> {
  return yield select((state: AppState) => state.boot.initialPageEnterDone);
}
