import { ModuleConfig } from './../../models/module';
import { CARD_CHANGE_PIN_RESOURCE_NAME } from './models/card-change-pin-flow-model';
import { listenToChangeCardPin } from './sagas/change-card-pin-flow';

export const cardChangePinFlowConfig: ModuleConfig = {
  moduleName: CARD_CHANGE_PIN_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToChangeCardPin],
  routes: {},
};
