import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Box, Header, PageTitle } from 'sb-ui-components';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { PageWrapper } from '../../../components/page-wrapper';
import { PostboxList } from '../../postbox/components/postbox-list/postbox-list';

const ServiceShowPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: 'title.service' });

  return (
    <PageWrapper title={title}>
      <Header>
        <PageTitle text={title} />
      </Header>
      <Box>
        <p>{formatMessage({ id: 'service.contactSupport' })}</p>
        <Link to={createPathByRouteName('service.requestSupport')}>{formatMessage({ id: 'button.requestHelp' })}</Link>
      </Box>
      <PostboxList />
    </PageWrapper>
  );
};

export default ServiceShowPage;
