import { SagaIterator } from 'redux-saga';
import { call, fork, put } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { ACCOUNT_TRANSACTION_RESOURCE_NAME } from 'src/modules/account-transaction/models/account-transaction';
import { indexAccountTransactionList } from 'src/modules/account-transaction/sagas/account-transaction-list';
import { indexAccountCardList } from 'src/modules/card/sagas';
import { REFERENCE_ACCOUNT_RESOURCE_NAME } from 'src/modules/reference-account/models/reference-account';
import { indexReferenceAccountList } from 'src/modules/reference-account/sagas/reference-account-index';
import { setListLoadingAction } from 'src/redux/list/list-actions';
import { resourceShowAndStoreToState } from 'src/sagas/resource-requests';
import { ResourceResponse } from 'src/types';
import { indexAccountReservationList } from '../../../account-reservation/sagas/account-reservation-list';
import { Account, accountResourceConfig } from '../../models/account';

export function* showAccount(params: ApiRequestParams): SagaIterator<ResourceResponse<Account>> {
  return yield call(resourceShowAndStoreToState, { resourceConfig: accountResourceConfig, params });
}

export function* showPageEnterAccount(params: ApiRequestParams): SagaIterator<void> {
  // Set loading states
  // Because the index sagas are blocked by showAccount, we have to set the loading states
  // before calling showAccount, to see all loaders from the beginning on
  yield put(setListLoadingAction({ resourceName: REFERENCE_ACCOUNT_RESOURCE_NAME, isLoading: true }));
  yield put(setListLoadingAction({ resourceName: ACCOUNT_TRANSACTION_RESOURCE_NAME, isLoading: true }));
  // Fetch account data
  yield call(showAccount, params);
  // Fetch further account related data
  yield fork(indexAccountReservationList, {
    pathParams: { accountId: params.pathParams.id },
    queryParams: {},
  });
  yield fork(indexAccountCardList, params);
  yield fork(indexReferenceAccountList, params);
  yield fork(indexAccountTransactionList, params);
}
