import { ComponentType, createElement, FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  InlineText,
  ItemGroup,
  LoaderSkeletonItem,
  Notification,
  ResponsiveTopHeader,
  SidebarMenu,
  SidebarMenuItemGroupProps,
  ThemeName,
  themeNames,
  ThemeProvider,
  ToastyList,
} from 'sb-ui-components';
import { Customer, CUSTOMER_RESOURCE_NAME } from 'src/modules/customer/models/customer';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { Enum } from 'typescript-string-enums';
import { Footer } from '../footer';
import { LocaleOption, locales } from '../localization-wrapper';
import { LogoutTimer } from '../logout-timer';
import { PageWrapper } from '../page-wrapper';
import { SimpleLoadingPage } from '../simple-loading-page/simple-loading-page';
import { SuccessErrorMessage } from '../success-error-message';
import styles from './app.module.scss';

export interface AppProps {
  notifications: Notification[];
  onSidebarMenuItemClick: () => void;
  onCloseNotification: (notification: Notification) => void;
  loggedIn: boolean;
  currentComponent: ComponentType<any>;
  theme?: ThemeName;
  onSetTheme: (theme: ThemeName) => void;
  onSetLocale: (locale: LocaleOption) => void;
  locale: LocaleOption;
}

export const APP_LAYOUT_ROOT_WRAPPER_ID = 'appLayoutRootWrapper';

const App: FunctionComponent<AppProps> = props => {
  const {
    onSidebarMenuItemClick,
    loggedIn,
    currentComponent,
    theme,
    onSetTheme,
    onSetLocale,
    locale,
    notifications,
    onCloseNotification,
  } = props;

  const { formatMessage } = useIntl();
  const entityState: EntityState = useSelector((state: AppState) => state.entity);
  const customer: Entity<Customer> | undefined = entityState?.[CUSTOMER_RESOURCE_NAME];

  const sidebarMenuItemsGroups: SidebarMenuItemGroupProps[] = [
    {
      label: formatMessage({ id: 'title.accounts' }),
      icon: 'HouseLight',
      link: '/accounts',
      authorized: true,
    },
    {
      label: formatMessage({ id: 'title.service' }),
      icon: 'EnvelopeLight',
      link: '/service',
      authorized: true,
    },
    {
      label: formatMessage({ id: 'title.profile' }),
      icon: 'UserLight',
      link: '/profile',
      authorized: true,
    },
    {
      label: formatMessage({ id: 'title.signOut' }),
      icon: 'ArrowRightFromBracketLight',
      authorized: true,
      link: '/logout',
    },
  ];

  const defaultOsTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? themeNames.dark : themeNames.light;
  const currentTheme = theme || (localStorage.getItem('theme') as ThemeName) || defaultOsTheme;
  const handleSetTheme = () => {
    const newTheme = currentTheme === themeNames.dark ? themeNames.light : themeNames.dark;
    onSetTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const handleSetLocale = (newLocale: LocaleOption) => {
    onSetLocale(newLocale);
    localStorage.setItem('locale', newLocale);
  };

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const localizedNotifications =
    notifications?.length &&
    notifications.map(notification => ({
      ...notification,
      topic: formatMessage({ id: notification.topic }),
      ...(notification.title && { title: formatMessage({ id: notification.title }) }),
    }));

  return (
    <ThemeProvider theme={currentTheme}>
      {/* 
        The APP_LAYOUT_ROOT_WRAPPER_ID id is needed to target this div to render the modals in the correct place 
        Since we show modals in logged in state as well as when user is not authorized yet, it needs to wrap
        both application frames.
      */}
      <div
        id={APP_LAYOUT_ROOT_WRAPPER_ID}
        className={classNames(styles.base, { [styles.baseNoScroll]: isSideBarOpen })}
      >
        {loggedIn && document.location.pathname !== '/login' && document.location.pathname !== '/privacy-policy' ? (
          <>
            <ResponsiveTopHeader
              theme={currentTheme}
              setTheme={handleSetTheme}
              onToggleMenu={() => setIsSideBarOpen(!isSideBarOpen)}
              locales={{
                options: Enum.values(locales).map(locale => ({
                  label: formatMessage({ id: `locale.${locale}` }),
                  value: locale,
                })),
                currentLocale: locale,
                setLocale: (locale: string) => handleSetLocale(locale as LocaleOption),
              }}
            />
            <main className={styles.main} data-cy="appMain">
              <LogoutTimer />
              <section className={styles.content} data-cy="contentSection">
                {/* currentComponent is actually the container of a component we want to render,
                    so it's necessary to create a React element from it before displaying it in DOM */}
                {customer?.loading && <SimpleLoadingPage title={formatMessage({ id: 'loading' })} />}
                {customer?.error && (
                  <PageWrapper title={formatMessage({ id: 'error.general.title' })} dynamicTopMargin maxWidth="small">
                    <SuccessErrorMessage
                      type="error"
                      title={formatMessage({ id: 'error.general.title' })}
                      message={formatMessage({ id: 'error.general.message' })}
                    />
                  </PageWrapper>
                )}
                {customer?.data && createElement(currentComponent)}
              </section>
            </main>
            <div className={classNames(styles.sidebar, { [styles.sidebarOpen]: isSideBarOpen })} data-cy="sidebar">
              <div className={styles.userInfo}>
                {customer?.data ? (
                  <>
                    <InlineText size="xsmall" color="muted">
                      {formatMessage({ id: 'title.welcome' })}
                    </InlineText>
                    <br />
                    <InlineText type="em" size="base">
                      {customer.data?.attributes?.first_name} {customer.data?.attributes?.last_name}
                    </InlineText>
                  </>
                ) : (
                  <>
                    <ItemGroup fullwidth alignItems="start" gutter="small">
                      <LoaderSkeletonItem width="50%" height="0.5rem" />
                      <LoaderSkeletonItem width="80%" />
                    </ItemGroup>
                  </>
                )}
              </div>
              <SidebarMenu
                sidebarMenuGroups={sidebarMenuItemsGroups}
                onSidebarMenuItemClick={onSidebarMenuItemClick}
                persistMenuState
                size="base"
                collapsable={false}
              />
            </div>
            <Footer />
            <ToastyList
              notifications={localizedNotifications || []}
              onCloseNotification={onCloseNotification}
              className={styles.notifications}
            />
          </>
        ) : (
          <>
            {/* currentComponent is actually the container of a component we want to render,
            so it's necessary to create a React element from it before displaying it in DOM */}
            {createElement(currentComponent)}
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;
