import { ModuleConfig } from '../../models/module';
import RequestSupportPage from './components/request-support-page/request-support-page';
import { REQUEST_SUPPORT_RESOURCE_NAME } from './models/request-support';
import { listenToCreateRequestSupportTicket } from './sagas';

export const requestSupportConfig: ModuleConfig = {
  moduleName: REQUEST_SUPPORT_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToCreateRequestSupportTicket],
  routes: {
    'service.requestSupport': {
      path: '/service/request-support',
      component: RequestSupportPage,
    },
  },
};
