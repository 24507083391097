import { SimpleAction } from '../../types';

export const PROGRESS_START_LOADING_ACTION = 'progress/START_LOADING';
export const PROGRESS_FINISH_LOADING_ACTION = 'progress/FINISH_LOADING';

export type ProgressStartAction = SimpleAction<typeof PROGRESS_START_LOADING_ACTION>;

export type ProgressFinishAction = SimpleAction<typeof PROGRESS_FINISH_LOADING_ACTION>;

export function startProgressAction(): ProgressStartAction {
  return {
    type: PROGRESS_START_LOADING_ACTION,
  };
}

export function finishProgressAction(): ProgressFinishAction {
  return {
    type: PROGRESS_FINISH_LOADING_ACTION,
  };
}
