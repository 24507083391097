import { SagaIterator } from 'redux-saga';
import { call, takeEvery } from 'redux-saga/effects';
import { ApiRequestParams } from '../../../../lib/api';
import { downloadBase64BinaryDataAsAttachment } from '../../../../lib/utils';
import { binaryResourceRequest } from '../../../../sagas/resource-requests';
import { ResourceResponse } from '../../../../types';
import { POSTBOX_ACTION_ENDPOINT, postboxResourceConfig } from '../../models/postbox';
import { DOWNLOAD_POSTBOX_ACTION, DownloadPostboxActionPayload } from '../../redux/postbox-actions';

export function* listenToDownloadPostbox(): SagaIterator<void> {
  yield takeEvery(DOWNLOAD_POSTBOX_ACTION, downloadPostboxDocument);
}

export function* downloadPostboxDocument(action: DownloadPostboxActionPayload): SagaIterator<void> {
  const params: ApiRequestParams = { queryParams: {}, pathParams: { id: action.payload.id } };
  const downloadResponse: ResourceResponse<string> = yield call(
    binaryResourceRequest,
    postboxResourceConfig,
    POSTBOX_ACTION_ENDPOINT,
    params,
    {
      data: {
        type: 'postbox_item_action',
        attributes: {
          action: 'DOWNLOAD',
        },
      },
    },
  );
  if (downloadResponse.data) {
    yield call(downloadBase64BinaryDataAsAttachment, downloadResponse.data, `${action.payload.fileName}.pdf`);
  }
}
