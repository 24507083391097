import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { listResourcePayloadToListResourceState } from 'src/lib/converter';
import { initializeList, setListLoadingAction } from 'src/redux/list/list-actions';
import { AppState, ResourceConfig, ResourceResponse } from 'src/types';
import { resourceRequest } from '../generic-resource-requests';

const defaultApiRequestParams: ApiRequestParams = { queryParams: {}, pathParams: {} };
export const PAGE_SIZE = 20;

export function buildIndexApiQueryParams(requestQueryParams: {} = {}, stateQueryParams: {} = {}) {
  return requestQueryParams && Object.keys(requestQueryParams).length === 0 ? stateQueryParams : requestQueryParams;
}

export function* resourceIndex(args: {
  resourceConfig: ResourceConfig;
  params: ApiRequestParams;
}): SagaIterator<ResourceResponse<unknown[]>> {
  const { resourceConfig, params = defaultApiRequestParams } = args;
  const resourceName = resourceConfig.resourceName;
  const stateModel = yield select((state: AppState) => state.list[resourceName]);
  const queryParams = {
    ...buildIndexApiQueryParams(params.queryParams, stateModel?.queryParams),
    'page[size]': PAGE_SIZE,
  };
  return yield call(resourceRequest, {
    resourceConfig,
    endpoint: 'index',
    apiRequestParams: { ...params, queryParams },
  });
}

export function* storeListToState(resourceName: string, response: ResourceResponse<unknown[]>) {
  const listResourceState = listResourcePayloadToListResourceState<unknown>(response);
  yield put(initializeList({ resourceName, listData: listResourceState }));
}

export function* resourceIndexAndStoreToState(args: {
  resourceConfig: ResourceConfig;
  params: ApiRequestParams;
}): SagaIterator {
  const { resourceConfig, params = defaultApiRequestParams } = args;
  const { resourceName } = resourceConfig;
  // Set loading state
  yield put(setListLoadingAction({ resourceName, isLoading: true }));
  // Fetch data
  const response = yield call(resourceIndex, { resourceConfig, params });
  // Update list state with data or error
  yield call(storeListToState, resourceConfig.resourceName, response);
}
