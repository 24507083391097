import { SagaIterator } from 'redux-saga';
import { call, fork, takeLatest } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { resourceIndexAndStoreToState } from 'src/sagas/resource-requests';
import { indexCustomer3dsList } from '../../../customer-3ds/sagas/index/customer-3ds-index';
import { accountResourceConfig } from '../../models/account';
import { INDEX_ACCOUNT_LIST_ACTION } from '../../redux/account-actions';

export function* indexAccount(params: ApiRequestParams): SagaIterator<void> {
  yield call(resourceIndexAndStoreToState, { resourceConfig: accountResourceConfig, params });
}

export function* indexPageEnterAccount(params: ApiRequestParams): SagaIterator<void> {
  yield call(indexAccount, params);
  yield fork(indexCustomer3dsList);
}

export function* listenToIndexAccountList(): SagaIterator<void> {
  yield takeLatest(INDEX_ACCOUNT_LIST_ACTION, handleIndexAccountList);
}

export function* handleIndexAccountList(): SagaIterator<void> {
  yield call(indexAccount, { pathParams: {}, queryParams: {} });
}
