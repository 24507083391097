import { PayloadAction, SimpleAction } from 'src/types';

export const FETCH_CUSTOMER_3DS_ACTION = 'customer-3ds/FETCH';
export const DECLINE_CUSTOMER_3DS_ACTION = 'customer-3ds/DECLINE';
export const APPROVE_CUSTOMER_3DS_ACTION = 'customer-3ds/APPROVE';

export type FetchCustomer3dsAction = SimpleAction<typeof FETCH_CUSTOMER_3DS_ACTION>;

export const fetchCustomer3dsAction = (): FetchCustomer3dsAction => ({
  type: FETCH_CUSTOMER_3DS_ACTION,
});

// Approve
export interface ApproveCustomer3dsActionPayload {
  notificationId: string;
}
export type ApproveCustomer3dsAction = PayloadAction<
  typeof APPROVE_CUSTOMER_3DS_ACTION,
  ApproveCustomer3dsActionPayload
>;

export const approveCustomer3dsAction = (payload: ApproveCustomer3dsActionPayload): ApproveCustomer3dsAction => ({
  type: APPROVE_CUSTOMER_3DS_ACTION,
  payload,
});

// Decline
export interface DeclineCustomer3dsActionPayload {
  notificationId: string;
}

export type DeclineCustomer3dsAction = PayloadAction<
  typeof DECLINE_CUSTOMER_3DS_ACTION,
  DeclineCustomer3dsActionPayload
>;

export const declineCustomer3dsAction = (payload: DeclineCustomer3dsActionPayload): DeclineCustomer3dsAction => ({
  type: DECLINE_CUSTOMER_3DS_ACTION,
  payload,
});
