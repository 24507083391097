import { BadgeColor } from 'sb-ui-components';
import { Enum } from 'typescript-string-enums';
import { ResourceConfig } from '../../../types';

//  ToDo: This interface is in WIP state. The endpoints are not final yet.

export interface PostboxAttributes {
  readonly status: PostboxStatus;
  readonly name: string;
  readonly description: string;
  readonly download_url: string;
  readonly created_at: string;
  readonly available_actions: any[];
}

export interface Postbox {
  readonly id: string;
  readonly type: string;
  readonly attributes: PostboxAttributes;
  readonly relationships?: any;
}

// Postbox statuses

export const postboxStatuses = Enum('PENDING', 'COMPLETED');
export type PostboxStatus = Enum<typeof postboxStatuses>;
export const postboxStatusColorMap: Record<PostboxStatus, BadgeColor> = {
  PENDING: 'neutral',
  COMPLETED: 'green',
};

// Resource config

export const POSTBOX_RESOURCE_NAME = 'postbox';

export const POSTBOX_INDEX_REQ_ACTION = 'solaris_service_postbox#all'; // unknown
export const POSTBOX_DOWNLOAD_REQ_ACTION = 'solaris_service_postbox#download'; // unknown

export const POSTBOX_ACTION_ENDPOINT = 'actions';

export const postboxResourceConfig: ResourceConfig = {
  resourceName: POSTBOX_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/customers/me/postbox-items',
      httpMethod: 'get',
      requiredAction: POSTBOX_INDEX_REQ_ACTION,
    },
    [POSTBOX_ACTION_ENDPOINT]: {
      path: '/postbox-items/{id}/actions',
      httpMethod: 'post',
      requiredAction: POSTBOX_DOWNLOAD_REQ_ACTION,
    },
  },
};
