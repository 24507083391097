// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.
// Sagas help us gather all our side effects (network requests in this case) in one place
import authentication from '../models/authentication';
import history from '../router/lib/history';
import { NullableString } from '../types';

// Little helper function to abstract going to different pages
export function forwardTo(location: string) {
  history.push(location);
}

export function replaceAndRemoveLocation(location: string) {
  authentication.removeLastLocation();
  window.location.replace(location);
}

export function* goToLocationOfLastSession(lastLocation: NullableString) {
  const defaultPostLoginLocation = '/accounts';
  yield lastLocation ? replaceAndRemoveLocation(lastLocation) : forwardTo(defaultPostLoginLocation);
}
