import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import { ResourceConfig, ResourceResponse } from 'src/types';

const defaultApiRequestParams: ApiRequestParams = { queryParams: {}, pathParams: {} };
export const UNAUTHORIZED_SHOW_RESPONSE: ResourceResponse<{}> = {
  data: '',
  meta: { queryParams: {}, pathParams: {} },
  error: { status: 403 },
};

export function* resourceShow(args: {
  resourceConfig: ResourceConfig;
  params: ApiRequestParams;
}): SagaIterator<ResourceResponse<any>> {
  const { resourceConfig, params = defaultApiRequestParams } = args;
  return yield call(resourceRequest, { resourceConfig, endpoint: 'show', apiRequestParams: params });
}

export function* resourceShowAndStoreToState(args: {
  resourceConfig: ResourceConfig;
  params: ApiRequestParams;
}): SagaIterator<any | void> {
  const { resourceConfig, params = defaultApiRequestParams } = args;
  // Set entities loading state
  yield put(fetchEntityInitAction({ entityName: resourceConfig.resourceName }));
  // Fetch the resource
  const resource: ResourceResponse<unknown> = yield call(resourceShow, {
    resourceConfig,
    params,
  });
  // Update entity state with error or data and unset loading state
  if (resource.error) {
    yield put(fetchEntityErrorAction({ entityName: resourceConfig.resourceName, error: resource.error }));
  } else {
    yield put(fetchEntitySuccessAction({ entityName: resourceConfig.resourceName, data: resource.data }));
    return resource.data;
  }
}
