import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Balance,
  Button,
  DataPresenter,
  displayValueOrDefault,
  formatBalance,
  formatIban,
  InlineText,
  ItemGroup,
  PageSection,
} from 'sb-ui-components';
import { Account } from 'src/modules/account/models/account';
import { createPathByRouteName } from '../../../lib/route-config-helper';
import { resetEntityStateAction } from '../../../redux/entity/entity-actions';
import { ACCOUNT_INDEX_ROUTE } from '../../account';
import { SmsChallengeModal } from '../../sms-challenge/components';
import { SMS_CHALLENGE_RESOURCE_NAME } from '../../sms-challenge/model/sms-challenge';
import {
  IntraTransferFormValues,
  ReferenceAccountPayoutFormValues,
  SepaCreditTransferFormValues,
  TRANSFER_CREATION_RESOURCE_NAME,
} from '../model/transfer';

export interface TransferSummaryProps {
  account: Account;
  summaryData: SepaCreditTransferFormValues | IntraTransferFormValues | ReferenceAccountPayoutFormValues;
  isSubmitting: boolean;
  onBack: () => void;
  onConfirm: () => void;
}

export const TransferSummary: FunctionComponent<TransferSummaryProps> = props => {
  const { account, summaryData, isSubmitting, onBack, onConfirm } = props;
  const attributes = account.attributes;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const title = formatMessage({ id: 'title.summary' });

  // Used to open the 2FA modal
  const isSmsChallangeCreated = Boolean(useSelector((state: any) => state.entity?.[SMS_CHALLENGE_RESOURCE_NAME]));

  const handleOnClose = () => {
    // Reset the state in case user opens the modal again
    dispatch(resetEntityStateAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_RESOURCE_NAME }));
    history.push(createPathByRouteName(`${[ACCOUNT_INDEX_ROUTE]}`));
  };

  return (
    <>
      <h2>{title}</h2>
      <PageSection>
        <DataPresenter
          data={[
            {
              label: formatMessage({ id: 'title.transfer.originAccount' }),
              value: (
                <>
                  <em>
                    {formatMessage({
                      id: `accountType.${attributes.account_type}`,
                      defaultMessage: attributes.account_type,
                    })}
                  </em>
                  <br />
                  {formatIban(attributes.iban)}
                </>
              ),
            },
          ]}
        />
      </PageSection>
      <PageSection>
        <DataPresenter
          data={[
            ...((summaryData as SepaCreditTransferFormValues).recipient_name &&
            (summaryData as SepaCreditTransferFormValues).recipient_iban
              ? [
                  {
                    label: formatMessage({ id: 'label.recipient' }),
                    value: (
                      <>
                        <em>{(summaryData as SepaCreditTransferFormValues).recipient_name}</em>
                        <br />
                        {formatIban((summaryData as SepaCreditTransferFormValues).recipient_iban)}
                      </>
                    ),
                  },
                ]
              : []),
            ...((summaryData as IntraTransferFormValues).recipientAccount
              ? [
                  {
                    label: formatMessage({ id: 'label.recipient' }),
                    value: (
                      <>
                        <em>
                          {formatMessage({
                            id: `accountType.${
                              (summaryData as IntraTransferFormValues).recipientAccount.attributes.account_type
                            }`,
                          })}
                        </em>
                        <br />
                        {formatIban((summaryData as IntraTransferFormValues).recipientAccount.attributes.iban)}
                      </>
                    ),
                  },
                ]
              : []),
            ...((summaryData as ReferenceAccountPayoutFormValues).referenceAccount
              ? [
                  {
                    label: formatMessage({ id: 'label.recipient' }),
                    value: (
                      <>
                        <em>
                          {formatIban(
                            (summaryData as ReferenceAccountPayoutFormValues).referenceAccount.attributes.iban,
                          )}
                        </em>
                        <br />
                        <InlineText type="small" size="xsmall" color="soft">{`${formatMessage({
                          id: `label.mandateNumber`,
                        })} ${displayValueOrDefault(
                          (summaryData as ReferenceAccountPayoutFormValues).referenceAccount.attributes.mandate_number,
                        )}`}</InlineText>
                      </>
                    ),
                  },
                ]
              : []),
            {
              label: formatMessage({ id: 'label.amount' }),
              value: <em>{formatBalance(summaryData.amount as Balance)}</em>,
            },
            { label: formatMessage({ id: 'label.description' }), value: summaryData.description },
          ]}
        />
      </PageSection>
      <PageSection>
        <ItemGroup justifyItems="space-between">
          <Button
            size="big"
            appearance="stroked"
            color="neutral"
            handleClick={onBack}
            leadingIcon="ArrowLeftRegular"
            disabled={isSubmitting}
          >
            {formatMessage({ id: 'button.back' })}
          </Button>
          <Button size="big" handleClick={onConfirm} isLoading={isSubmitting}>
            {formatMessage({ id: 'button.transferNow' })}
          </Button>
        </ItemGroup>
      </PageSection>

      {/* SMS 2FA modal */}
      <SmsChallengeModal
        successMessage={{
          title: formatMessage({ id: 'success.transfer.title' }),
          message: formatMessage({ id: 'success.transfer.message' }),
        }}
        isOpen={Boolean(isSmsChallangeCreated)}
        onClose={handleOnClose}
      />
    </>
  );
};
