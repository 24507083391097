import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CodeInput, InlineText, ItemGroup, ModalProps, ModalSection, SolarisSpinner } from 'sb-ui-components';
import { ModalWrapper } from 'src/components/modal-wrapper';
import { SuccessErrorMessage, SuccessErrorMessageProps } from 'src/components/success-error-message';
import useCountdown from 'src/lib/hooks/countdown';
import { ExpiredCredentialsErrorModal } from 'src/modules/credentials/components/expired-credentials-error-modal';
import { SmsChallengeCreation } from 'src/modules/sms-challenge/model/sms-challenge';
import { fetchEntityInitAction, resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { SMS_CHALLENGE_AUTH_CONFIRMATION_STATE, SMS_CHALLENGE_AUTH_RESOURCE_NAME } from '../model/sms-challenge-auth';
import {
  confirmSmsChallengeAuthAction,
  ConfirmSmsChallengeAuthActionPayload,
  createSmsChallengeAuthAction,
} from '../redux/sms-challenge-auth-actions';
import styles from './sms-challenge-auth.module.scss';

export interface SmsChallengeAuthModalProps extends ModalProps {
  successMessage?: {
    title: SuccessErrorMessageProps['title'];
    message?: SuccessErrorMessageProps['message'];
    cta?: SuccessErrorMessageProps['cta'];
  };
  isOpen: boolean;
  onClose?: () => void;
}

export const SmsChallengeAuthModal: FunctionComponent<SmsChallengeAuthModalProps> = props => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { control, handleSubmit, setValue, reset } = useForm<ConfirmSmsChallengeAuthActionPayload['formValues']>({
    mode: 'onChange',
  });
  const { successMessage, isOpen, onClose } = props;
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const entityState: EntityState = useSelector((state: AppState) => state.entity);

  // Challenge creation state
  const smsChallengeAuthState: Entity<SmsChallengeCreation> | undefined = entityState[SMS_CHALLENGE_AUTH_RESOURCE_NAME];
  const challengeRequestId = smsChallengeAuthState?.data?.relationships.challenge_request.data.id;
  const challengeId = smsChallengeAuthState?.data?.id;
  const isChallengeCreationPending = smsChallengeAuthState?.loading;
  const smsChallangeCreationError = smsChallengeAuthState?.error;

  // Challenge confirmation state
  const smsChallengeAuthConfirmationState = entityState[SMS_CHALLENGE_AUTH_CONFIRMATION_STATE];
  const isSuccess = smsChallengeAuthConfirmationState?.data?.attributes?.status === 'COMPLETED';
  const isChallengeConfirmationPending = Boolean(smsChallengeAuthConfirmationState?.loading);
  const challengeConfirmationError = smsChallengeAuthConfirmationState?.error;

  // Token expired
  const isTokenExpired = [401, 403].includes(
    smsChallangeCreationError?.status || challengeConfirmationError?.status || 0,
  );

  const onResend = () => {
    // Reset form
    reset();
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_AUTH_CONFIRMATION_STATE }));
    // Set loading state
    dispatch(fetchEntityInitAction({ entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME }));
    // Trigger sms challenge
    challengeRequestId && dispatch(createSmsChallengeAuthAction({ challengeRequestId }));
  };

  const onFormSubmit = (formValues: ConfirmSmsChallengeAuthActionPayload['formValues']) => {
    challengeId &&
      dispatch(
        confirmSmsChallengeAuthAction({
          formValues,
          pathParams: { challengeId },
        }),
      );
  };

  const handleOnClose = () => {
    onClose && onClose();
    setShowResendSmsButton(false);
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME }));
    dispatch(resetEntityStateAction({ entityName: SMS_CHALLENGE_AUTH_CONFIRMATION_STATE }));
  };

  const [showResendSmsButton, setShowResendSmsButton] = useState<boolean>(false);

  const remainingSeconds = useCountdown({
    initialRemainingSeconds: 5,
    onTimeout: () => {
      // we are enabling the resend button when modal is open
      // end disable it again as soon as modal closes
      setShowResendSmsButton(isOpen && !isChallengeCreationPending);
    },
    // we want the countdown to start automatically as soon as the modal is open and
    // creating the challenge finished
    autoStart: isOpen && !isChallengeCreationPending,
  });

  const renderRemainingSeconds = () => {
    if (remainingSeconds > 0) {
      return `(${remainingSeconds})`;
    } else {
      return null;
    }
  };

  if (isTokenExpired) {
    return <ExpiredCredentialsErrorModal isOpen={true} />;
  }

  if (smsChallangeCreationError) {
    return (
      <ModalWrapper
        isOpen={isOpen}
        modalProps={{
          maxWidth: 'xsmall',
          className: styles.modal,
          dataCy: 'modal__sms_challenge',
        }}
      >
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.general.title' })}
          message={formatMessage({ id: 'error.general.message' })}
          cta={
            <Button size="big" handleClick={handleOnClose}>
              {formatMessage({ id: 'button.ok' })}
            </Button>
          }
        />
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      modalProps={{
        maxWidth: 'xsmall',
        headerTitle: !isSuccess ? formatMessage({ id: 'title.enterSmsCode' }) : undefined,
        className: styles.modal,
        dataCy: 'modal__sms_challenge',
      }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalSection>
          <ItemGroup justifyItems="center" gutterY="large" fullwidth>
            {isChallengeCreationPending && (
              <>
                <br />
                <SolarisSpinner size="large" />
                <p>{formatMessage({ id: 'message.smsChallenge.loading' })}</p>
                <br />
              </>
            )}
            {isSuccess && successMessage && (
              <SuccessErrorMessage
                type="success"
                title={successMessage.title}
                message={successMessage.message}
                cta={
                  successMessage.cta || (
                    <Button size="big" handleClick={handleOnClose}>
                      {formatMessage({ id: 'button.ok' })}
                    </Button>
                  )
                }
              />
            )}
            {!isChallengeCreationPending && !isSuccess && (
              <>
                <label>
                  <InlineText size="small">{formatMessage({ id: 'message.smsChallenge' })}</InlineText>
                </label>
                <CodeInput
                  id="confirmation_value"
                  length={6}
                  control={control}
                  autoFocus={true}
                  setValue={setValue}
                  onComplete={() => setIsFormValid(true)}
                  dataCy="confirmation_value_input"
                  disabled={isChallengeConfirmationPending}
                  isInvalid={Boolean(challengeConfirmationError)}
                  errorMessage={
                    challengeConfirmationError ? formatMessage({ id: 'inputError.smsChallenge' }) : undefined
                  }
                />
              </>
            )}
          </ItemGroup>
        </ModalSection>
        {!isSuccess && (
          <>
            <ModalSection>
              {!isChallengeCreationPending && (
                <ItemGroup justifyItems="center" alignItems="baseline" gutter="none">
                  <InlineText size="small">{formatMessage({ id: 'hint.noChallengeReceivedYet' })}</InlineText>
                  <Button
                    type="button"
                    appearance="no-background"
                    dataCy="cta__resend"
                    handleClick={onResend}
                    disabled={!showResendSmsButton}
                    className={styles.resendButton}
                  >
                    {formatMessage({ id: 'button.resendCode' })} {renderRemainingSeconds()}
                  </Button>
                </ItemGroup>
              )}
            </ModalSection>
            <ModalSection>
              <ItemGroup justifyItems="space-between">
                <Button size="big" appearance="stroked" color="neutral" handleClick={handleOnClose}>
                  {formatMessage({ id: 'button.cancel' })}
                </Button>
                <Button
                  size="big"
                  type="submit"
                  disabled={!isFormValid || isChallengeCreationPending}
                  dataCy="cta__submit_confirmation_value"
                  isLoading={isChallengeConfirmationPending}
                >
                  {formatMessage({ id: 'button.confirm' })}
                </Button>
              </ItemGroup>
            </ModalSection>
          </>
        )}
      </form>
    </ModalWrapper>
  );
};
