import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Box, ItemGroup, LoaderSkeletonItem, PageSection } from 'sb-ui-components';
import { AccountListItemLoader } from 'src/modules/account/components/account-list-item-loader';

export const CreateTransferLoaderSkeleton: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <PageSection>
        <h2>{formatMessage({ id: 'title.transfer.originAccount' })}</h2>
        <AccountListItemLoader displayActions={false} />
      </PageSection>

      <PageSection>
        <h2>{formatMessage({ id: 'title.transfer.recipient' })}</h2>
        <Box>
          <ItemGroup fullwidth gutter="small" alignItems="start">
            <LoaderSkeletonItem width="10rem" height="var(--font-size-xsmall)" />
            <LoaderSkeletonItem width="100%" height="2.5rem" />
          </ItemGroup>
          <br />
          <ItemGroup fullwidth gutter="small" alignItems="start">
            <LoaderSkeletonItem width="10rem" height="var(--font-size-xsmall)" />
            <LoaderSkeletonItem width="100%" height="2.5rem" />
          </ItemGroup>
        </Box>
      </PageSection>

      <PageSection>
        <h2>{formatMessage({ id: 'title.details' })}</h2>
        <Box>
          <ItemGroup fullwidth gutter="small" alignItems="start">
            <LoaderSkeletonItem width="10rem" height="var(--font-size-xsmall)" />
            <LoaderSkeletonItem width="100%" height="2.5rem" />
          </ItemGroup>
          <br />
          <ItemGroup fullwidth gutter="small" alignItems="start">
            <LoaderSkeletonItem width="10rem" height="var(--font-size-xsmall)" />
            <LoaderSkeletonItem width="100%" height="2.5rem" />
          </ItemGroup>
        </Box>
      </PageSection>

      <PageSection>
        <ItemGroup justifyItems="space-between">
          <LoaderSkeletonItem width="8rem" height="2.5rem" round />
          <LoaderSkeletonItem width="8rem" height="2.5rem" round />
        </ItemGroup>
      </PageSection>
    </>
  );
};
