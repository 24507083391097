import { FunctionComponent } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Balance, BalanceInput, Box, formatBalance, TextInput } from 'sb-ui-components';
import { RegisterType } from 'src/types';

export interface TransferDetailsFormSectionProps {
  control: Control;
  register: RegisterType;
  errors: FieldErrors;
  balance: Balance;
}

export const TransferDetailsFormSection: FunctionComponent<TransferDetailsFormSectionProps> = props => {
  const { control, register, errors, balance } = props;
  const formattedBalance = formatBalance(balance);

  const { formatMessage } = useIntl();

  return (
    <>
      <h2>{formatMessage({ id: 'title.details' })}</h2>
      <Box>
        <BalanceInput
          id="amount"
          label={formatMessage({ id: 'label.amount' })}
          control={control}
          currency={balance.currency!}
          hint={`(${formatMessage({ id: 'message.amountAvailable' }, { amount: formattedBalance })})`}
          validation={{
            validate: {
              required: value => value?.value !== '' || formatMessage({ id: 'inputError.required' }),
              notZero: value => parseInt(value?.value) > 0 || formatMessage({ id: 'inputError.amount.zero' }),
              tooHigh: value =>
                parseInt(value?.value) <= balance.value ||
                formatMessage(
                  {
                    id: 'inputError.amount.tooHigh',
                  },
                  { amount: formattedBalance },
                ),
            },
          }}
          errorMessage={errors.amount?.message}
        />
        <TextInput
          id="description"
          label={formatMessage({ id: 'label.description' })}
          register={register}
          validation={{
            validate: { required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }) },
          }}
          errorMessage={errors.description?.message}
        />
      </Box>
    </>
  );
};
