import { ResourceConfig } from '../../../types';

export interface CredentialCreateFormValues {
  username: string;
  password: string;
  confirm_password: string;
}

export const CREDENTIALS_RESOURCE_NAME = 'credentials';
export const CREDENTIALS_CREATE_ENDPOINT = 'credentials/CREATE';

export const credentialResourceConfig: ResourceConfig = {
  resourceName: CREDENTIALS_RESOURCE_NAME,
  endpoints: {
    [CREDENTIALS_CREATE_ENDPOINT]: {
      path: '/auth/credentials',
      httpMethod: 'post',
    },
  },
};
