import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  ButtonLink,
  EN_SPACE,
  Header,
  InlineText,
  ItemGroup,
  PageSection,
  PageTitle,
  SvgIcon,
} from 'sb-ui-components';
import { PageWrapper } from 'src/components/page-wrapper';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { AccountTransactionList } from 'src/modules/account-transaction/components/account-transaction-list';
import { CardListItem } from 'src/modules/card/components/card-list-item';
import { ACCOUNT_CARD_RESOURCE_NAME, Card } from 'src/modules/card/models/card';
import { ReferenceAccountList } from 'src/modules/reference-account/components/reference-account-list';
import { AppState, Entity } from 'src/types';
import AccountReservationList from '../../account-reservation/components/account-reservation-list';
import { Account, ACCOUNT_RESOURCE_NAME, accountBlockStateColorMap, accountStatusColorMap } from '../models/account';
import { AccountDetails } from './account-details';
import { AccountSeizureInformation } from './account-seizure-information';

const AccountShowPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  const account: Entity<Account> = useSelector((state: any) => state.entity[ACCOUNT_RESOURCE_NAME]);
  const cards = useSelector((state: AppState) => state.list[ACCOUNT_CARD_RESOURCE_NAME]);

  const isActive = account?.data?.attributes?.status === 'ACTIVE';
  const isBlocked = isActive && account?.data?.attributes?.block_state !== 'NO_BLOCK';
  const isTransferEnabled =
    isActive && !['BLOCK', 'DEBIT_BLOCK'].includes(account?.data?.attributes?.block_state || '');
  const hasStatus = account?.data?.attributes.status !== undefined;

  const title = account?.loading
    ? formatMessage({ id: 'loading' })
    : formatMessage({
        id: `accountType.${account?.data?.attributes?.account_type}`,
        defaultMessage: formatMessage({ id: 'title.account' }),
      });

  const seizureProtection = account?.data?.attributes?.seizure_protection;

  return (
    <PageWrapper title={title}>
      <div>
        <Link to={createPathByRouteName('account.index')}>
          <SvgIcon type="ArrowLeftRegular" size="xsmall" />
          {EN_SPACE}
          <InlineText size="xsmall">{formatMessage({ id: 'title.accounts' })}</InlineText>
        </Link>
      </div>
      <br />
      <Header>
        <PageTitle text={title}>
          {/* If the account is not active, display the account status badge */}
          {!isActive && hasStatus && (
            <Badge
              message={formatMessage({
                id: `status.${account?.data?.attributes?.status}`,
                defaultMessage: account?.data?.attributes?.status,
              })}
              color={accountStatusColorMap[account?.data?.attributes?.status!]}
            />
          )}
          {/* If the account is active and blocked, display the account block state badge */}
          {isBlocked && (
            <Badge
              message={formatMessage({
                id: `accountBlockState.${account?.data?.attributes?.block_state}`,
                defaultMessage: account?.data?.attributes?.block_state,
              })}
              color={accountBlockStateColorMap[account?.data?.attributes?.block_state!]}
            />
          )}
        </PageTitle>

        {/* ToDo: Align on where to add intra customer transfer and how to differentiate it from SEPA credit transfer */}
        {account?.data && isTransferEnabled && (
          <ButtonLink
            size="big"
            href={createPathByRouteName('sepaCreditTransfer.create', { pathParams: { id: account.data.id } })}
            dataCy="cta__create_transfer"
          >
            {formatMessage({ id: 'button.transfer' })}
          </ButtonLink>
        )}
      </Header>
      <AccountDetails account={account} />
      {Boolean(seizureProtection?.current_blocked_amount.value || seizureProtection?.protected_amount.value) && (
        <AccountSeizureInformation seizureProtection={seizureProtection!} />
      )}

      {Boolean(cards?.list?.length) && (
        <PageSection>
          <Header>
            <h2>{formatMessage({ id: 'title.cards' })}</h2>
          </Header>
          <ItemGroup fullwidth>
            {cards?.list?.map((item: Card) => (
              <CardListItem key={item.id} card={item} />
            ))}
          </ItemGroup>
        </PageSection>
      )}
      <ReferenceAccountList />
      <PageSection>
        <Header>
          <h2>{formatMessage({ id: 'title.transactions' })}</h2>
          {account?.data?.id && isTransferEnabled && (
            <ButtonLink
              href={createPathByRouteName('sepaCreditTransfer.create', { pathParams: { id: account.data.id } })}
            >
              {formatMessage({ id: 'button.transfer' })}
            </ButtonLink>
          )}
        </Header>
        <ItemGroup fullwidth>
          <AccountReservationList />
          <AccountTransactionList />
        </ItemGroup>
      </PageSection>
    </PageWrapper>
  );
};

export default AccountShowPage;
