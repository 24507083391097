import { PayloadAction } from '../../../types';
import { EmailVerificationRequestFormValues } from '../models/email-verification';

export const EMAIL_VERIFICATION_REQUEST_ACTION = 'emailVerification/REQUEST';

export interface EmailVerificationRequestActionPayload {
  formValues: EmailVerificationRequestFormValues;
}

export type EmailVerificationRequestAction = PayloadAction<
  typeof EMAIL_VERIFICATION_REQUEST_ACTION,
  EmailVerificationRequestActionPayload
>;

export const emailVerificationRequestAction = (
  payload: EmailVerificationRequestActionPayload,
): EmailVerificationRequestAction => ({
  type: EMAIL_VERIFICATION_REQUEST_ACTION,
  payload,
});
