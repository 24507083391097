import { BadgeColor } from 'sb-ui-components';
import { ResourceConfig } from 'src/types';
import { Enum } from 'typescript-string-enums';

export interface ReferenceAccountAttributes {
  readonly status: ReferenceAccountStatus;
  readonly iban: string;
  readonly bic: string;
  readonly mandate_number: string;
}
export interface ReferenceAccount {
  readonly id: string;
  readonly type: 'reference_account';
  readonly attributes: ReferenceAccountAttributes;
}

export const referenceAccountStatuses = Enum('ACTIVE', 'INACTIVE', 'PENDING');
export type ReferenceAccountStatus = Enum<typeof referenceAccountStatuses>;
export const referenceAccountStatusColorMap: Record<ReferenceAccountStatus, BadgeColor> = {
  ACTIVE: 'green',
  INACTIVE: 'neutral',
  PENDING: 'yellow',
};

export const REFERENCE_ACCOUNT_RESOURCE_NAME = 'reference-account';

export const referenceAccountResourceConfig: ResourceConfig = {
  resourceName: REFERENCE_ACCOUNT_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/accounts/{id}/reference-accounts',
      httpMethod: 'get',
    },
  },
};
