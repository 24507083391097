import { useEffect, useState } from 'react';
interface CountdownProps {
  initialRemainingSeconds: number;
  onTimeout: () => void;
  autoStart?: boolean;
}

const useCountdown = ({ initialRemainingSeconds, onTimeout, autoStart = true }: CountdownProps) => {
  const [remainingSeconds, setRemainingSeconds] = useState<number>(initialRemainingSeconds);
  let intervalId: NodeJS.Timeout;

  // Function to update the countdown
  const updateCounter = () => {
    // Start the countdown interval if it's not already running
    if (!intervalId && autoStart) {
      intervalId = setInterval(() => {
        // Decrease the remaining seconds by 1 every second
        setRemainingSeconds(current => current && current - 1);
      }, 1000);
    }

    // If remaining seconds reach 0, clear the interval and trigger the timeout callback
    if (remainingSeconds === 0) {
      clearInterval(intervalId);
      onTimeout();
    }
  };

  // Effect hook to start the countdown and clear the interval on component unmount
  useEffect(() => {
    updateCounter();
    return () => {
      if (!autoStart && remainingSeconds !== initialRemainingSeconds) {
        // counter was stopped (deactivated) after running - we need to reset
        // the state to initial value, otherwise when being rendered again
        // the counter would continue from the last remaining time.
        setRemainingSeconds(initialRemainingSeconds);
      }
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingSeconds, onTimeout]);

  // Return the remaining seconds to be used in the component
  return remainingSeconds;
};

export default useCountdown;
