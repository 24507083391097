import { ModuleConfig } from './../../models/module';
import AccountListPage from './components/account-list-page';
import AccountShowPage from './components/account-show-page';
import { ACCOUNT_RESOURCE_NAME } from './models/account';
import { indexPageEnterAccount, listenToIndexAccountList, showPageEnterAccount } from './sagas';

export const ACCOUNT_INDEX_ROUTE = 'account.index';

export const accountModuleConfig: ModuleConfig = {
  moduleName: ACCOUNT_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToIndexAccountList],
  routes: {
    [ACCOUNT_INDEX_ROUTE]: {
      path: '/accounts',
      component: AccountListPage,
      saga: indexPageEnterAccount,
    },
    'account.show': {
      path: '/accounts/:id',
      component: AccountShowPage,
      saga: showPageEnterAccount,
    },
  },
};
