import { ResourceConfig } from '../../../types';

export interface EmailVerificationRequestFormValues {
  iban: string;
  birth_date: string;
  mobile_number: string;
}

export const EMAIL_VERIFICATION_RESOURCE_NAME = 'emailVerification';
export const EMAIL_VERIFICATION_ENDPOINT = 'emailVerification/VERIFY';

export const emailVerificationResourceConfig: ResourceConfig = {
  resourceName: EMAIL_VERIFICATION_RESOURCE_NAME,
  endpoints: {
    [EMAIL_VERIFICATION_ENDPOINT]: {
      path: '/auth/email-verifications',
      httpMethod: 'post',
    },
  },
};
