import { DEFAULT_AUTH_STATE } from 'src/redux/auth/auth-reducer';
import { DEFAULT_BOOT_STATE } from 'src/redux/boot/boot-reducer';
import { DEFAULT_ENTITY_STATE } from 'src/redux/entity/entity-reducer';
import { DEFAULT_LIST_STATE } from 'src/redux/list/list-reducer';
import { DEFAULT_NOTIFICATION_STATE } from 'src/redux/notification/notification-reducer';
import { DEFAULT_PROGRESSBAR_STATE } from 'src/redux/progress-bar/progress-bar-reducer';
import { DEFAULT_UI_STATE } from 'src/redux/ui/ui-reducer';
import { AppState } from 'src/types';

export const DEFAULT_APP_STATE: AppState = {
  ui: DEFAULT_UI_STATE,
  auth: DEFAULT_AUTH_STATE,
  boot: DEFAULT_BOOT_STATE,
  entity: DEFAULT_ENTITY_STATE,
  list: DEFAULT_LIST_STATE,
  notifications: DEFAULT_NOTIFICATION_STATE,
  progressBar: DEFAULT_PROGRESSBAR_STATE,
};
