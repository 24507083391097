import { FunctionComponent } from 'react';
import { Box, DataListItemLoader, ItemGroup, LoaderSkeletonItem } from 'sb-ui-components';

export interface AccountListItemLoaderProps {
  displayActions?: boolean;
}

export const AccountListItemLoader: FunctionComponent<AccountListItemLoaderProps> = props => {
  const { displayActions = true } = props;

  return (
    <Box padding="none">
      <DataListItemLoader
        leadingIcon
        leadingContent={<LoaderSkeletonItem width="calc(5rem + 50%)" height="var(--font-size-medium)" />}
        leadingSubline={
          <>
            <LoaderSkeletonItem width="calc(4rem + 40%)" height="var(--font-size-xsmall)" />
            {displayActions && (
              <>
                <br />
                <ItemGroup>
                  <LoaderSkeletonItem width="8rem" height="2.25rem" round />
                  <LoaderSkeletonItem width="8rem" height="2.25rem" round />
                </ItemGroup>
              </>
            )}
          </>
        }
        trailingContent={<LoaderSkeletonItem alignment="right" width="5rem" height="var(--font-size-big)" />}
      />
    </Box>
  );
};
