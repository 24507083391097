import { AuthenticationMessageStatus } from '../../types';
import {
  AUTH_REQUEST_ERROR_ACTION,
  AUTH_SENDING_REQUEST_ACTION,
  AUTH_SET_AUTH_ACTION,
  AUTH_STORE_PARTNER_ID,
  AUTH_STORE_SMS_CHALLENGE_REQUEST_ID,
  AuthAction,
  SetAuthActionPayload,
  SetAuthRequestErrorActionPayload,
  SetIsAuthRequestSendingActionPayload,
  StorePartnerIdActionPayload,
  StoreSmsChallengeAuthRequestIdActionPayload,
} from './auth-actions';

export interface AuthState {
  loggedIn: boolean;
  currentlySending: boolean;
  statusMessage: AuthenticationMessageStatus;
  idToken?: string;
  challengeRequestId?: string;
  partnerId?: string;
}

// The initial application state
export const DEFAULT_AUTH_STATE = {
  currentlySending: false,
  loggedIn: false,
  statusMessage: AuthenticationMessageStatus.PRISTINE,
};

const getAuthStatusMessage = (payload: SetAuthActionPayload) =>
  payload.isAuthorized
    ? AuthenticationMessageStatus.PRISTINE
    : payload.isReasonInactivity
    ? AuthenticationMessageStatus.LOGGED_OUT_FOR_INACTIVITY
    : AuthenticationMessageStatus.LOGGED_OUT;

const getAuthErrorMessage = (httpStatusCode: number) => {
  switch (httpStatusCode) {
    case 401:
      return AuthenticationMessageStatus.WRONG_CREDENTIALS;
    case 403:
      return AuthenticationMessageStatus.NETWORK_INVALID;
    default:
      return AuthenticationMessageStatus.GENERIC_ERROR;
  }
};

export const auth = (state: AuthState = DEFAULT_AUTH_STATE, action: AuthAction): AuthState => {
  switch (action.type) {
    case AUTH_SET_AUTH_ACTION:
      if (!action.payload) {
        return state;
      }
      const setAuthActionPayload = action.payload as SetAuthActionPayload;
      return {
        ...state,
        loggedIn: setAuthActionPayload.isAuthorized,
        statusMessage: getAuthStatusMessage(setAuthActionPayload),
        idToken: setAuthActionPayload.idToken,
      };
    case AUTH_SENDING_REQUEST_ACTION:
      const sendRequestActionPayload = action.payload as SetIsAuthRequestSendingActionPayload;
      return {
        ...state,
        currentlySending: sendRequestActionPayload.sending,
      };
    case AUTH_REQUEST_ERROR_ACTION:
      const loginErrorPayload = action.payload as SetAuthRequestErrorActionPayload;
      return {
        ...state,
        currentlySending: false,
        statusMessage: getAuthErrorMessage(loginErrorPayload.httpResponseCode),
      };
    case AUTH_STORE_SMS_CHALLENGE_REQUEST_ID:
      const { challengeRequestId } = action.payload as StoreSmsChallengeAuthRequestIdActionPayload;
      return {
        ...state,
        challengeRequestId,
      };
    case AUTH_STORE_PARTNER_ID:
      const { partnerId } = action.payload as StorePartnerIdActionPayload;
      return {
        ...state,
        partnerId,
      };
    default:
      return state;
  }
};
