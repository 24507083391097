import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Box, DataPresenter, EN_DASH, formatAddress, formatPhone, Header } from 'sb-ui-components';
import { Customer } from 'src/modules/customer/models/customer';
import { CustomerMobileNumber } from 'src/modules/customer-mobile-number/models/customer-mobile-number';
import { Entity, ListResourceState, ServerErrorResponse } from 'src/types';

export interface CustomerDetailsProps {
  customer: Entity<Customer>;
  mobileNumbers: ListResourceState<CustomerMobileNumber>;
}

export const CustomerContact: FunctionComponent<CustomerDetailsProps> = props => {
  const { customer, mobileNumbers } = props;
  const { formatMessage } = useIntl();

  const error = customer?.error;
  const attributes = customer?.data?.attributes;
  const { email, address } = attributes || {};

  const phoneNumbers = mobileNumbers?.list;

  const renderError = (err?: ServerErrorResponse) =>
    err && (
      <Box>
        {err.status} {EN_DASH} {err.message}
      </Box>
    );
  return (
    <>
      {renderError(error)}
      <Header>
        <h2>{formatMessage({ id: 'title.contact' })}</h2>
      </Header>
      <DataPresenter
        dataCy="customerContactData"
        isLoading={customer?.loading}
        data={[
          {
            label: formatMessage({ id: 'label.email' }),
            value: email,
          },
          ...(phoneNumbers?.map(phone => ({
            label: formatMessage({ id: 'label.phone' }),
            value: formatPhone(phone.attributes.number),
          })) || []),
          {
            label: formatMessage({ id: 'label.address' }),
            value: formatAddress(address),
          },
        ]}
      />
    </>
  );
};
