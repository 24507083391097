import { Balance } from 'sb-ui-components';
import { AccountReservationCategory } from 'src/modules/account-reservation/models/account-reservation';
import { Enum } from 'typescript-string-enums';
import { ResourceConfig } from '../../../types';

//  ToDo: This interface is in WIP state. The endpoints are not final yet.

export interface AccountTransactionAttributes {
  readonly transaction_type: AccountTransactionType;
  readonly is_return_transaction: boolean;
  readonly amount: Balance;
  readonly booked_at: string;
  readonly valued_at: string;
  readonly transacted_at: string;
  readonly note: string;
  readonly counterparty_name: string;
  readonly category: AccountReservationCategory;
}

export const accountTransasctionTypes = Enum('CARD', 'SEPA_CREDIT_TRANSFER', 'SEPA_DIRECT_DEBIT', 'CASHBACK', 'OTHER');
export type AccountTransactionType = Enum<typeof accountTransasctionTypes>;
export interface AccountTransaction {
  readonly type: 'transaction';
  readonly id: string;
  readonly attributes: AccountTransactionAttributes;
}

export const ACCOUNT_TRANSACTION_RESOURCE_NAME = 'account-transaction';

export const accountTransactionResourceConfig: ResourceConfig = {
  resourceName: ACCOUNT_TRANSACTION_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/accounts/{id}/transactions',
      httpMethod: 'get',
    },
  },
};
