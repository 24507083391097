import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Notification, ThemeName } from 'sb-ui-components';
import { LocaleOption } from 'src/components/localization-wrapper';
import App from '../components/app';
import { clearAllListsAction } from '../redux/list/list-actions';
import { clearNotificationAction } from '../redux/notification/notification-actions';
import { uiSetLocaleAction, uiSetThemeAction } from '../redux/ui/ui-actions';
import { AppState } from '../types';

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSidebarMenuItemClick: () => {
      dispatch(clearAllListsAction({ resetFiltersAndPages: true }));
    },
    onCloseNotification: (notification: Notification) => {
      dispatch(clearNotificationAction({ id: notification.id }));
    },
    onSetTheme: (theme: ThemeName) => {
      dispatch(uiSetThemeAction({ theme }));
    },
    onSetLocale: (locale: LocaleOption) => {
      dispatch(uiSetLocaleAction({ locale }));
    },
  };
};

export const mapStateToProps = (state: AppState) => {
  return {
    loggedIn: state.auth.loggedIn,
    theme: state.ui.theme,
    locale: state.ui.locale,
    notifications: state.notifications,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
