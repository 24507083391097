import { FunctionComponent } from 'react';

const PrivacyPolicyDE: FunctionComponent = () => {
  return (
    <>
      <h1>Datenverarbeitung beim Besuch der Webseite</h1>
      <p>
        Wir freuen uns über Ihren Besuch auf unserer Webseite. Nachfolgend möchten wir Sie über den Umgang mit Ihren
        Daten gem. Art. 13 Datenschutz-Grundverordnung (DSGVO) informieren.
      </p>

      <h2>Verantwortlicher</h2>
      <p>Verantwortlich für die nachfolgend dargestellten Datenverarbeitungen ist die:</p>
      <p>
        Solaris SE
        <br />
        Cuvrystraße 53
        <br />
        10997 Berlin
      </p>
      <p>
        E-Mail-Adresse: <a href="mailto:privacy@solarisgroup.com">privacy@solarisgroup.com</a>
      </p>

      <h2>Kontaktdaten der Datenschutzbeauftragten</h2>
      <p>
        Unsere Datenschutzbeauftragte steht Ihnen gerne für Auskünfte zum Thema Datenschutz unter folgenden Kontaktdaten
        zur Verfügung:
      </p>
      <p>
        Datenschutzbeauftragte: <a href="mailto:eleonora.bianchi@solarisgroup.com">eleonora.bianchi@solarisgroup.com</a>
        <br />
        Stellvertreterin: <a href="mailto:sabrina.rudorf@solarisgroup.com">sabrina.rudorf@solarisgroup.com</a>
      </p>

      <h2>Nutzungsdaten</h2>
      <p>
        Wenn Sie unsere Webseiten besuchen, werden auf unserem Webserver temporär sogenannte Nutzungsdaten zu
        statistischen Zwecken als Protokoll ausgewertet, um die Qualität unserer Webseiten zu verbessern. Dieser
        Datensatz besteht aus
      </p>
      <ul>
        <li>dem Namen und der Adresse der angeforderten Inhalte,</li>
        <li>dem Datum und der Uhrzeit der Abfrage,</li>
        <li>der übertragenen Datenmenge,</li>
        <li>dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden),</li>
        <li>der Beschreibung des verwendeten Webbrowsers und des Betriebssystems,</li>
        <li>dem Referral-Link, der angibt, von welcher Seite Sie auf unsere gelangt sind,</li>
        <li>
          der IP-Adresse des anfragenden Rechners, die so verkürzt wird, dass ein Personenbezug nicht mehr herstellbar
          ist.
        </li>
      </ul>
      <p>
        Die genannten Protokolldaten werden nur anonymisiert ausgewertet. Rechtsgrundlage der Verarbeitung der
        Nutzungsdaten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Die Verarbeitung erfolgt in dem berechtigten Interesse, die
        Inhalte der Webseite bereitzustellen und eine Geräte- sowie Browseroptimierte Darstellung sicherzustellen.
      </p>

      <h2>Erforderliche Cookies</h2>
      <p>
        Auf unseren Webseiten setzen wir Cookies ein, die zur Nutzung unseren Webseiten erforderlich sind. Cookies sind
        kleine Textdateien, die auf Ihrem Endgerät gespeichert und ausgelesen werden können. Man unterscheidet zwischen
        Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten Cookies, die
        über die einzelne Sitzung hinaus gespeichert werden. Wir nutzen diese erforderlichen Cookies nicht für Analyse-,
        Tracking- oder Werbezwecke. Teilweise enthalten diese Cookies lediglich Informationen zu bestimmten
        Einstellungen und sind nicht personenbeziehbar. Sie können auch notwendig sein, um die Benutzerführung,
        Sicherheit und Umsetzung der Seite zu ermöglichen. Die durch diese Cookies verarbeiteten Daten sind für die
        vorgenannten Zwecke zur Wahrung unserer berechtigten Interessen nach Art. 6 Abs. 1 S. 1 lit. f DSGVO in
        Verbindung mit § 25 Abs. 2 Nr. 2 TTDSG erforderlich, um einen von Ihnen gewünschten Dienst anzubieten.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von Cookies informiert. Zudem können
        Sie diese jederzeit über die entsprechende Browsereinstellung löschen und das Setzen neuer Cookies verhindern.
        Bitte beachten Sie, dass unsere Webseiten dann gegebenenfalls nicht vollständig angezeigt werden können und
        einige Funktionen technisch nicht mehr zur Verfügung stehen.
      </p>

      <h2>Personenbezogene Daten, die wir im Rahmen von Anfragen an unseren Kundenservice verarbeiten</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon) sowie im Rahmen bestehender Nutzer-
        und Geschäftsbeziehungen werden die Angaben der anfragenden Personen verarbeitet soweit dies zur Beantwortung
        der Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist. Die Beantwortung der Kontaktanfragen
        sowie die Verwaltung von Kontakt- und Anfragedaten im Rahmen von vertraglichen oder vorvertraglichen Beziehungen
        erfolgt zur Erfüllung unserer vertraglichen Pflichten oder zur Beantwortung von (vor)vertraglichen Anfragen
        gemäß Art. 6 Abs. 1 lit. f) DSGVO und im Übrigen auf Grundlage der berechtigten Interessen gemäß Art. 6 Abs. 1
        lit. f) DSGVO an der Beantwortung der Anfragen und Pflege von Nutzer- bzw. Geschäftsbeziehungen. Wir setzen zu
        diesem Zweck verschiedene Dienstleister ein, dazu zählen Anbieter von Tickettools und Anbieter von
        Cloudlösungen.
      </p>

      <h2>Personenbezogene Daten, die wir im Rahmen der Bereitstellung Ihres Kundenkontos verarbeiten</h2>
      <p>
        Wenn Sie ein Konto bei der Solaris SE führen, können Sie über SolarisGo auf Ihr Konto zugreifen, wenn der
        Zugriff über die Partnerapp oder -webseite nicht möglich ist, bei der Sie sich ursprünglich registriert haben
        und Sie eine Einladung zu SolarisGo erhalten haben. Hierfür müssen Sie sich zunächst authentifizieren. Für die
        Authentifizierung nutzen wir die mit Ihrem Onlinebankingkonto bei uns verknüpfte E-Mail-Adresse und
        Telefonnummer. Zudem fragen wir weitere Daten wie Ihre IBAN und Ihr Geburtsdatum ab, um sicherzugehen, dass kein
        unberechtigter Zugriff auf Ihr Konto erfolgen kann.
      </p>
      <p>
        Nachdem Sie sich erfolgreich authentifiziert haben, können Sie sich mit Ihrem Nutzernamen und Ihrer
        Mobiltelefonnummer einloggen, um auf Ihr Onlinebankingkonto zuzugreifen, Transaktionen durchzuführen und Ihre
        Daten zu bearbeiten. Ohne eine Mobiltelefonnummer, auf der Sie SMS empfangen können, ist die Nutzung von
        SolarisGo nicht möglich.
      </p>
      <p>
        SolarisGo verarbeitet hierbei neben Ihren personenbezogenen Identifizierungsdaten und Kontaktdaten (Name,
        Anschrift, Geburtsdatum) auch Bankdaten und Verbindungsdaten (insbesondere Kontoverbindungsdaten wie IBAN,
        Kontosalden, Umsätze, Logdaten), die für die Erbringung des Webbanking-Service erforderlich sind. Die
        Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 lit. b DSGVO.
      </p>
      <p>
        Als Bank unterliegen wir diversen rechtlichen und regulatorischen Verpflichtungen, das heißt gesetzlichen
        Anforderungen (z.B. Kreditwesengesetz, Geldwäschegesetz, Steuergesetze) sowie bankaufsichtsrechtlichen Vorgaben
        (z.B. der Europäischen Zentralbank, der Europäischen Bankenaufsicht und der Bundesanstalt für
        Finanzdienstleistungsaufsicht). Auch zur Erfüllung dieser Verpflichtungen und Vorgaben kann es erforderlich
        werden, dass wir Ihre personenbezogenen Daten gem. Art. 6 Abs. 1 lit. c DSGVO und Art. 6 Abs. 1 lit. e DSGVO
        verarbeiten. Dazu können Steuerdaten sowie Transaktionsdaten zählen. Zu den Zwecken der Verarbeitung gehören
        unter anderem die Betrugs- und Geldwäscheprävention sowie die Erfüllung steuerrechtlicher Kontroll- und
        Meldepflichten und die Bewertung und Steuerung von Risiken für die Bank. Hierfür kann es auch erforderlich sein,
        dass wir Ihre Daten an Aufsichtsbehörden, Steuerbehörden oder Justizbehörden weitergeben.
      </p>
      <p>
        Wir setzen Dienstleister ein, die uns beim Webhosting, bei der Betrugsprävention, dem Customer Relationship
        Management und bei der Onlinekommunikation unterstützen.
      </p>
      <p>
        Zum Zweck der Bearbeitung bestimmter Zahlungsvorgänge müssen wir eventuell Informationen über Sie an eine andere
        Bank oder ein spezialisiertes Finanzdienstleistungsunternehmen übermitteln (z. B. SWIFT, VISA, Bankverlag).
      </p>

      <h2>Pflicht zur Bereitstellung der personenbezogenen Daten</h2>
      <p>
        Sofern Sie Zugriff auf Ihr Konto erlangen und Transaktionen durchführen wollen, sind Sie verpflichtet, uns die
        Daten bereitzustellen, die für die Vertragserfüllung, für den sicheren Online-Zugriff auf Ihr Konto und für die
        Erfüllung unserer gesetzlichen Pflichten erforderlich sind. Wenn Sie diese Daten nicht bereitstellen, können wir
        die geschäftliche Beziehung nicht fortführen und Ihnen keinen Online-Zugriff auf das Konto gewähren.
      </p>

      <h2>Speicherdauer</h2>
      <p>
        Sofern wir nicht im Einzelnen bereits über die Speicherdauer informiert haben, löschen wir personenbezogene
        Daten, wenn sie für die vorgenannten Verarbeitungszwecke nicht mehr erforderlich sind und keine berechtigten
        Interessen oder andere (gesetzliche) Aufbewahrungsgründe einer Löschung entgegenstehen. Gesetzliche
        Aufbewahrungsgründe ergeben sich hauptsächlich aus dem Handelsgesetzbuch, der Abgabenordnung, dem
        Kreditwesengesetz und dem Geldwäschegesetz. Die dort vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation
        betragen fünf bis zehn Jahre.
      </p>

      <h2>Ihre Rechte als betroffene Person</h2>
      <p>
        Bei der Verarbeitung Ihrer personenbezogenen Daten gewährt die DSGVO Ihnen als betroffene Person bestimmte
        Rechte.
      </p>
      <h3>Auskunftsrecht (Art. 15 DSGVO)</h3>
      <p>
        Sie haben das Recht eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet
        werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in
        Art. 15 DSGVO im Einzelnen aufgeführten Informationen.
      </p>
      <h3>Recht auf Berichtigung (Art. 16 DSGVO)</h3>
      <p>
        Sie haben das Recht, unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf.
        die Vervollständigung unvollständiger Daten zu verlangen.
      </p>
      <h3>Recht auf Löschung (Art. 17 DSGVO)</h3>
      <p>
        Sie haben das Recht, zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden,
        sofern einer der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe zutrifft.
      </p>
      <h3>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO
        aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für
        die Dauer der Prüfung durch den Verantwortlichen.
      </p>
      <h3>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</h3>
      <p>
        In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt sind, haben Sie das Recht, die Sie
        betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten
        bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.
      </p>
      <h3>Widerrufsrecht (Art. 7 DSGVO)</h3>
      <p>
        Sofern die Verarbeitung von Daten auf Grundlage Ihrer Einwilligung erfolgt, sind Sie nach Art. 7 Abs. 3 DSGVO
        berechtigt, die Einwilligung in die Verwendung Ihrer personenbezogenen Daten jederzeit zu widerrufen. Bitte
        beachten Sie, dass der Widerruf erst für die Zukunft wirkt. Verarbeitungen, die vor dem Widerruf erfolgt sind,
        sind davon nicht betroffen.
      </p>
      <h3>Widerspruchsrecht (Art. 21 DSGVO)</h3>
      <p>
        Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO (Datenverarbeitung zur Wahrung berechtigter
        Interessen) oder auf Grundlage von Art. 6 Abs. 1 S. 1 lit. e DSGVO (Datenverarbeitung zur Wahrung öffentlichen
        Interesse oder in Ausübung öffentlicher Gewalt) erhoben, steht Ihnen das Recht zu, aus Gründen, die sich aus
        Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die
        personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für
        die Verarbeitung vor, die gegenüber Ihren Interessen, Rechten und Freiheiten überwiegen, oder die Verarbeitung
        dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <h3>Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO)</h3>
      <p>
        Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind,
        dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das
        Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres gewöhnlichen
        Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.
      </p>
      <p>Geltendmachung Ihrer Rechte</p>
      <p>
        Sofern vorstehend nichts anderes beschrieben wurde, wenden Sie sich zur Geltendmachung Ihrer Betroffenenrechte
        bitte an die im Impressum genannte Stelle.
      </p>
    </>
  );
};

export default PrivacyPolicyDE;
