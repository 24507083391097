import { PayloadAction } from 'src/types';

export const BLOCK_CARD_ACTION = 'card/BLOCK_ACTION';
export const UNBLOCK_CARD_ACTION = 'card/UNBLOCK_ACTION';
// BLOCK
export type BlockCardAction = PayloadAction<typeof BLOCK_CARD_ACTION, ChangeCardStatusActionPayload>;

export interface ChangeCardStatusActionPayload {
  id: string;
}

export const blockCardAction = (payload: ChangeCardStatusActionPayload): BlockCardAction => {
  return {
    type: BLOCK_CARD_ACTION,
    payload,
  };
};

// UNBLOCK
export interface UnblockCardActionPayload {
  id: string;
}

export type UnblockCardAction = PayloadAction<typeof UNBLOCK_CARD_ACTION, UnblockCardActionPayload>;

export const unblockCardAction = (payload: ChangeCardStatusActionPayload): UnblockCardAction => {
  return {
    type: UNBLOCK_CARD_ACTION,
    payload,
  };
};
