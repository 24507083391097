import { ModuleConfig } from 'src/models/module';
import { accountModuleConfig } from './modules/account';
import { cardModuleConfig } from './modules/card';
import { cardChangePinFlowConfig } from './modules/card-change-pin-flow';
import { credentialConfig } from './modules/credentials';
import { customerModuleConfig } from './modules/customer';
import { customer3dsModuleConfig } from './modules/customer-3ds';
import { emailVerificationConfig } from './modules/email-verification';
import { forgotPasswordConfig } from './modules/forgot-password';
import { loginModuleConfig } from './modules/login';
import { phoneVerificationConfig } from './modules/phone-verification';
import { postboxModuleConfig } from './modules/postbox';
import { privacyPolicyModuleConfig } from './modules/privacy-policy';
import { referenceAccountModuleConfig } from './modules/reference-account';
import { requestSupportConfig } from './modules/request-support';
import { serviceModuleConfig } from './modules/service';
import { smsChallengeModuleConfig } from './modules/sms-challenge';
import { smsChallengeAuthModuleConfig } from './modules/sms-challenge-auth';
import { accountTransferResourceConfig } from './modules/transfer';

export const moduleConfigs: ModuleConfig[] = [
  accountModuleConfig,
  accountTransferResourceConfig,
  cardChangePinFlowConfig,
  cardModuleConfig,
  credentialConfig,
  customer3dsModuleConfig,
  customerModuleConfig,
  emailVerificationConfig,
  forgotPasswordConfig,
  loginModuleConfig,
  phoneVerificationConfig,
  postboxModuleConfig,
  privacyPolicyModuleConfig,
  referenceAccountModuleConfig,
  requestSupportConfig,
  serviceModuleConfig,
  smsChallengeAuthModuleConfig,
  smsChallengeModuleConfig,
];

export const modulesRegistry: Record<string, ModuleConfig> = moduleConfigs.reduce(
  (acc, config) => ({
    ...acc,
    [config.moduleName]: config,
  }),
  {},
);
