import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from '../../../../redux/entity/entity-actions';
import { resourceRequest } from '../../../../sagas/resource-requests';
import {
  accountTransferResourceConfig,
  CREATE_REFERENCE_ACCOUNT_PAYOUT_ENDPOINT,
  TRANSFER_CREATION_RESOURCE_NAME,
} from '../../model/transfer';
import {
  CREATE_REFERENCE_ACCOUNT_PAYOUT_ACTION,
  CreateReferenceAccountPayoutAction,
} from '../../redux/transfer-actions';

export function* listenToCreateReferenceAccountPayout(): SagaIterator<void> {
  yield takeEvery(CREATE_REFERENCE_ACCOUNT_PAYOUT_ACTION, handleCreateReferenceAccountPayout);
}

export function* handleCreateReferenceAccountPayout(action: CreateReferenceAccountPayoutAction): SagaIterator<void> {
  const { formValues, pathParams } = action.payload;

  // Set loading state
  yield put(fetchEntityInitAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));

  // Create reference account payout
  const { amount, description } = formValues;
  const { account_id } = pathParams;

  const createReferenceAccountPayoutResponse = yield call(resourceRequest, {
    resourceConfig: accountTransferResourceConfig,
    endpoint: CREATE_REFERENCE_ACCOUNT_PAYOUT_ENDPOINT,
    apiRequestParams: {
      pathParams: { account_id },
      queryParams: {},
    },
    requestBody: {
      data: {
        type: 'payout',
        meta: {
          use_credit: false,
        },
        attributes: {
          reference: uuidv4(),
          description,
          amount,
        },
      },
    },
  });

  // Set error state
  if (createReferenceAccountPayoutResponse.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: TRANSFER_CREATION_RESOURCE_NAME,
        error: createReferenceAccountPayoutResponse.error,
      }),
    );
    return;
  }

  // Set success state
  if (createReferenceAccountPayoutResponse.data) {
    yield put(fetchEntitySuccessAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));
  }
}
