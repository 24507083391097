import { ResourceConfig } from 'src/types';

export const CUSTOMER_MOBILE_NUMBER_RESOURCE_NAME = 'customer-mobile-number';

export interface MobileNumberAttributes {
  readonly status: string;
  readonly number: string;
}

export interface MobileNumberOwner {
  readonly type: 'owner';
  readonly id: string;
}

export interface MobileNumberRelationship {
  readonly owner: MobileNumberOwner;
}

export interface CustomerMobileNumber {
  readonly type: 'mobile_number';
  readonly id: string;
  readonly relationships: MobileNumberRelationship;
  readonly attributes: MobileNumberAttributes;
}

export const customerMobileNumberResourceConfig: ResourceConfig = {
  resourceName: CUSTOMER_MOBILE_NUMBER_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/customers/me/mobile-numbers',
      httpMethod: 'get',
    },
  },
};
