import { PayloadAction } from 'src/types';

export const AUTH_SET_AUTH_ACTION = 'auth/SET_AUTH';
export const AUTH_SENDING_REQUEST_ACTION = 'auth/SENDING_REQUEST';
export const AUTH_LOGOUT_ACTION = 'auth/LOGOUT';
export const AUTH_REQUEST_ERROR_ACTION = 'auth/REQUEST_ERROR';
export const AUTH_STORE_SMS_CHALLENGE_REQUEST_ID = 'auth/STORE_CHALLENGE_REQUEST_ID';
export const AUTH_STORE_PARTNER_ID = 'auth/STORE_PARTNER_ID';

export type AuthActionPayload =
  | SetAuthActionPayload
  | SetIsAuthRequestSendingActionPayload
  | LogoutActionPayload
  | SetAuthRequestErrorActionPayload
  | {};

export type AuthAction = PayloadAction<string, AuthActionPayload>;
// Set authentication state

export interface SetAuthActionPayload {
  readonly isAuthorized: boolean;
  readonly isReasonInactivity?: boolean;
  readonly idToken?: string;
}

export type SetAuthAction = PayloadAction<typeof AUTH_SET_AUTH_ACTION, SetAuthActionPayload>;

export const setAuthStateAction = (payload: SetAuthActionPayload): SetAuthAction => ({
  type: AUTH_SET_AUTH_ACTION,
  payload,
});

// Set state for if auth request is currently in sending

export interface SetIsAuthRequestSendingActionPayload {
  readonly sending: boolean;
}

export type SetIsAuthRequestSendingAction = PayloadAction<
  typeof AUTH_SENDING_REQUEST_ACTION,
  SetIsAuthRequestSendingActionPayload
>;

export const setIsAuthRequestSendingAction = (
  payload: SetIsAuthRequestSendingActionPayload,
): SetIsAuthRequestSendingAction => ({
  type: AUTH_SENDING_REQUEST_ACTION,
  payload,
});

// Logout

interface LogoutActionPayload {
  readonly inactive?: boolean;
}

export type LogoutAction = PayloadAction<typeof AUTH_LOGOUT_ACTION, LogoutActionPayload>;

export const logoutAction = (payload?: LogoutActionPayload): LogoutAction => ({
  type: AUTH_LOGOUT_ACTION,
  payload: payload || {},
});

// Set auth error

export interface SetAuthRequestErrorActionPayload {
  httpResponseCode: number;
}
export type SetAuthRequestErrorAction = PayloadAction<
  typeof AUTH_REQUEST_ERROR_ACTION,
  SetAuthRequestErrorActionPayload
>;

export const setAuthRequestErrorAction = (payload: SetAuthRequestErrorActionPayload): SetAuthRequestErrorAction => ({
  type: AUTH_REQUEST_ERROR_ACTION,
  payload,
});

// Store ChallengeRequestId
export interface StoreSmsChallengeAuthRequestIdActionPayload {
  challengeRequestId: string;
}

export type StoreSmsChallengeAuthRequestIdAction = PayloadAction<
  typeof AUTH_STORE_SMS_CHALLENGE_REQUEST_ID,
  StoreSmsChallengeAuthRequestIdActionPayload
>;

export const storeAuthSmsChallengeRequestIdAction = (
  payload: StoreSmsChallengeAuthRequestIdActionPayload,
): StoreSmsChallengeAuthRequestIdAction => ({
  type: AUTH_STORE_SMS_CHALLENGE_REQUEST_ID,
  payload,
});

// Store PartnerId
export interface StorePartnerIdActionPayload {
  partnerId: string;
}

export type StorePartnerIdAction = PayloadAction<typeof AUTH_STORE_PARTNER_ID, StorePartnerIdActionPayload>;

export const storePartnerIdAction = (payload: StorePartnerIdActionPayload): StorePartnerIdAction => ({
  type: AUTH_STORE_PARTNER_ID,
  payload,
});
