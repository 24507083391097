import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Header, PageTitle } from 'sb-ui-components';
import { PageWrapper } from 'src/components/page-wrapper';
import { Customer, CUSTOMER_RESOURCE_NAME } from 'src/modules/customer/models/customer';
import {
  CUSTOMER_MOBILE_NUMBER_RESOURCE_NAME,
  CustomerMobileNumber,
} from 'src/modules/customer-mobile-number/models/customer-mobile-number';
import { Entity, ListResourceState } from 'src/types';
import { CustomerContact } from './customer-contact';
import { CustomerDetails } from './customer-details';
import { CustomerTaxInformation } from './customer-tax-information';

const AccountShowPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const customer: Entity<Customer> = useSelector((state: any) => state.entity[CUSTOMER_RESOURCE_NAME]);
  const mobileNumbers: ListResourceState<CustomerMobileNumber> = useSelector(
    (state: any) => state.list[CUSTOMER_MOBILE_NUMBER_RESOURCE_NAME],
  );
  const title = customer?.loading ? formatMessage({ id: 'loading' }) : formatMessage({ id: 'title.profile' });

  return (
    <PageWrapper title={title}>
      <Header>
        <PageTitle text={title} />
      </Header>
      <CustomerDetails customer={customer} />
      <CustomerContact customer={customer} mobileNumbers={mobileNumbers} />
      <CustomerTaxInformation customer={customer} />
    </PageWrapper>
  );
};

export default AccountShowPage;
