import { TextColor } from 'sb-ui-components';
import { ResourceConfig } from 'src/types';
import { Enum } from 'typescript-string-enums';

export const cardStatuses = Enum(
  'ACTIVE',
  'ARCHIVED',
  'BLOCKED',
  'BLOCKED_BY_SOLARIS',
  'CLOSED_BY_SOLARIS',
  'INACTIVE',
  'PROCESSING',
  'REPORTED',
);
export type CardStatus = Enum<typeof cardStatuses>;

export const visibleCardStatuses: Partial<CardStatus>[] = [
  'BLOCKED',
  'BLOCKED_BY_SOLARIS',
  'CLOSED_BY_SOLARIS',
  'INACTIVE',
  'PROCESSING',
  'REPORTED',
];

export const cardStatusColorMap: Record<CardStatus, TextColor> = {
  ACTIVE: 'green',
  ARCHIVED: 'soft',
  BLOCKED: 'red',
  BLOCKED_BY_SOLARIS: 'red',
  CLOSED_BY_SOLARIS: 'red',
  INACTIVE: 'red',
  PROCESSING: 'soft',
  REPORTED: 'red',
};

export const cardTypes = Enum(
  'MASTERCARD_BUSINESS_DEBIT',
  'MASTERCARD_CREDIT',
  'MASTERCARD_DEBIT',
  'VIRTUAL_MASTERCARD_BUSINESS_DEBIT',
  'VIRTUAL_MASTERCARD_DEBIT',
  'VIRTUAL_MASTERCARD_FREELANCE_DEBIT',
  'VIRTUAL_VISA_BUSINESS_CREDIT',
  'VIRTUAL_VISA_BUSINESS_DEBIT',
  'VIRTUAL_VISA_CREDIT',
  'VIRTUAL_VISA_DEBIT',
  'VIRTUAL_VISA_FREELANCE_DEBIT',
  'VISA_BUSINESS_CREDIT',
  'VISA_BUSINESS_DEBIT',
  'VISA_CREDIT',
  'VISA_DEBIT',
);

export type CardType = Enum<typeof cardTypes>;

export const cardSchemes = Enum('MASTERCARD', 'VISA');
export type CardScheme = Enum<typeof cardSchemes>;

export const allowedCardRegions = Enum('EUROPE', 'GERMANY', 'NONE', 'WORLD');
export type AllowedCardRegion = Enum<typeof allowedCardRegions>;

export interface CardAttributes {
  readonly status: CardStatus;
  readonly card_type: CardType;
  readonly scheme: CardScheme;
  readonly is_virtual: boolean;
  readonly is_replacement_ordered: false;
  readonly expires_at: string;
  readonly created_at: string;
  readonly card_options?: {};
  readonly representation: {
    readonly line1: 'Lieschen Müller';
    readonly line2?: string;
    readonly masked_pan: string;
    readonly formatted_expiration_date: string;
  };
  readonly spending_controls: {
    readonly online_payments_allowed: boolean;
    readonly allowed_region: AllowedCardRegion;
  };
}

export interface Card {
  readonly type: 'card';
  readonly id: string;
  readonly relationships?: any; // ToDo: still in WIP state
  readonly attributes: CardAttributes;
}

// Resource names

export const ACCOUNT_CARD_RESOURCE_NAME = 'accountCard';
export const CARD_BLOCK_UNBLOCK_STATE = 'blockUnblockCard';
export const CARD_BLOCK_ENDPOINT = 'blockCard';
export const CARD_UNBLOCK_ENDPOINT = 'unblockCard';

export const accountCardResourceConfig: ResourceConfig = {
  resourceName: ACCOUNT_CARD_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/accounts/{id}/cards',
      httpMethod: 'get',
    },
    [CARD_BLOCK_ENDPOINT]: {
      path: '/cards/{id}/block',
      httpMethod: 'post',
    },
    [CARD_UNBLOCK_ENDPOINT]: {
      path: '/cards/{id}/unblock',
      httpMethod: 'post',
    },
  },
};
