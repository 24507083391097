import { ModuleConfig } from '../../models/module';
import PrivacyPolicyPage from './components/privacy-policy-page';
import { PRIVACY_POLICY_RESOURCE_NAME } from './models/privacy-policy';

export const PRIVACY_POLICY_ROUTE = 'privacy-policy';

export const privacyPolicyModuleConfig: ModuleConfig = {
  moduleName: PRIVACY_POLICY_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [],
  routes: {
    [PRIVACY_POLICY_ROUTE]: {
      path: '/privacy-policy',
      component: PrivacyPolicyPage,
      skipAuthentication: true,
    },
  },
};
