import { PayloadAction } from 'src/types';

export const CARD_CHANGE_PIN_ACTION = 'card/changePin';

export interface ChangeCardPinActionPayload {
  cardId: string;
  pin: string;
}

export type ChangeCardPinAction = PayloadAction<typeof CARD_CHANGE_PIN_ACTION, ChangeCardPinActionPayload>;

export const changeCardPinAction = (payload: ChangeCardPinActionPayload): ChangeCardPinAction => ({
  type: CARD_CHANGE_PIN_ACTION,
  payload,
});
