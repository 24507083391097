import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DataList, displayValueOrDefault, formatIban, InlineText, RadioInput } from 'sb-ui-components';
import { Account } from 'src/modules/account/models/account';
import {
  REFERENCE_ACCOUNT_RESOURCE_NAME,
  ReferenceAccount,
} from 'src/modules/reference-account/models/reference-account';
import { indexReferenceAccountListAction } from 'src/modules/reference-account/redux/reference-account-actions';
import { ListResourceState } from 'src/types';
import { ReferenceAccountPayoutFormValues, TransferStep } from '../model/transfer';
import { CreateTransferFormButtons } from './create-transfer-form-buttons';
import { TransferDetailsFormSection } from './transfer-details-form-section';

export interface ReferenceAccountPayoutFormProps {
  account: Account;
  setStep: (step: TransferStep) => void;
  setSummaryData: (data: ReferenceAccountPayoutFormValues) => void;
  summaryData?: ReferenceAccountPayoutFormValues;
}

export const ReferenceAccountPayoutForm: FunctionComponent<ReferenceAccountPayoutFormProps> = props => {
  const { setStep, summaryData, setSummaryData, account } = props;

  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const { register, control, handleSubmit, errors, reset } = useForm<ReferenceAccountPayoutFormValues>({
    mode: 'onSubmit',
  });

  const referenceAccounts: ListResourceState<ReferenceAccount> = useSelector(
    (state: any) => state.list[REFERENCE_ACCOUNT_RESOURCE_NAME],
  );

  const defaultIsRecipientPresent =
    summaryData?.end_to_end_id === undefined ? undefined : Boolean(summaryData?.end_to_end_id);
  const [isRecipentFieldValid, setIsRecipentFieldValid] = useState<Boolean | undefined>(defaultIsRecipientPresent);

  const onSubmit = (formValues: ReferenceAccountPayoutFormValues) => {
    if (isRecipentFieldValid) {
      setStep('SUMMARY');
      const recipientAccount = referenceAccounts?.list!.find(
        (item: ReferenceAccount) => item.id === formValues.end_to_end_id,
      );
      setSummaryData({ ...formValues, referenceAccount: recipientAccount! });
    } else {
      setIsRecipentFieldValid(false);
      setSummaryData(formValues);
    }
  };

  // We only load the reference accounts if they haven't been fetched already
  useEffect(() => {
    if (!referenceAccounts) {
      dispatch(indexReferenceAccountListAction({ accountId: account.id }));
    }
  }, [account, dispatch, referenceAccounts]);

  useEffect(() => {
    if (summaryData) {
      reset(summaryData);
    }
  }, [reset, summaryData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {referenceAccounts && (
        <>
          <DataList
            list={referenceAccounts?.list}
            isLoading={referenceAccounts.isLoading}
            noValuePlaceHolder={formatMessage({ id: 'message.noReferenceAccountsFound' })}
            leadingIcon={() => 'BuildingColumnsLight'}
            leadingContent={(item: ReferenceAccount) => (
              <InlineText nowrap type="div">
                {formatIban(item.attributes.iban)}
              </InlineText>
            )}
            leadingSubline={(item: ReferenceAccount) => (
              <InlineText nowrap type="div" size="xsmall" color="soft">
                {`${formatMessage({ id: `label.mandateNumber` })} ${displayValueOrDefault(
                  item.attributes.mandate_number,
                )}`}
              </InlineText>
            )}
            trailingContent={(item: ReferenceAccount) => (
              <RadioInput
                id={`${item.id}`}
                register={register}
                name={'end_to_end_id'}
                label={''}
                labelledBy={`iban-${item.attributes.iban}`}
                onChange={() => setIsRecipentFieldValid(true)}
              />
            )}
          />
          {isRecipentFieldValid === false && (
            <InlineText color="red" size="xsmall">
              {formatMessage({ id: 'inputError.recipient.required' })}
            </InlineText>
          )}
        </>
      )}

      <TransferDetailsFormSection
        control={control}
        register={register}
        errors={errors}
        balance={account.attributes.balance}
      />

      <CreateTransferFormButtons />
    </form>
  );
};
