import { PayloadAction } from 'src/types';
import { ModuleConfig } from '../../models/module';
import { RoutesConfig } from '../../router/lib/routes-config';

export const MODULES_SET_ROUTES_ACTION = 'modules/SET-ROUTES';

export interface SetRoutesActionPayload {
  modules: Record<string, ModuleConfig>;
  routes: RoutesConfig;
}

export type SetRoutesAction = PayloadAction<typeof MODULES_SET_ROUTES_ACTION, SetRoutesActionPayload>;

export function setRoutesAction(params: SetRoutesActionPayload): SetRoutesAction {
  return {
    type: MODULES_SET_ROUTES_ACTION,
    payload: {
      modules: params.modules,
      routes: params.routes,
    },
  };
}
