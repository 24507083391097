import { SimpleAction } from 'src/types';

export const RESET_APP_STATE_ACTION = 'root/RESET_APP_STATE';

export type ResetAppStateAction = SimpleAction<typeof RESET_APP_STATE_ACTION>;

export function resetAppState(): ResetAppStateAction {
  return {
    type: RESET_APP_STATE_ACTION,
  };
}
