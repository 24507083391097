import { ThemeName } from 'sb-ui-components';
import { defaultLocale, LocaleOption } from 'src/components/localization-wrapper';
import { ListElementUiState } from '../../types';
import {
  UI_SET_LOCALE_ACTION,
  UI_SET_THEME_ACTION,
  UI_SHOW_COUNTDOWN_ACTION,
  UiSetLocaleAction,
  UiSetThemeAction,
  UiShowCountdownAction,
} from './ui-actions';

export type ListItemUiList = { [id: string]: ListElementUiState };

export interface UiState {
  readonly listItemEntities: ListItemUiList;
  readonly theme?: ThemeName;
  readonly locale: LocaleOption;
  readonly showCountDown: boolean;
}

export const DEFAULT_UI_STATE: UiState = {
  listItemEntities: {},
  theme: undefined,
  locale: defaultLocale as LocaleOption,
  showCountDown: false,
};

export const ui = (
  state: UiState = DEFAULT_UI_STATE,
  action: UiSetThemeAction | UiSetLocaleAction | UiShowCountdownAction,
): UiState => {
  switch (action.type) {
    case UI_SET_THEME_ACTION: {
      const theme = action.payload?.theme as ThemeName;
      return {
        ...state,
        theme,
      };
    }

    case UI_SET_LOCALE_ACTION: {
      const locale = action.payload?.locale as LocaleOption;
      return {
        ...state,
        locale,
      };
    }

    case UI_SHOW_COUNTDOWN_ACTION: {
      const showCountDown = action.payload;
      return {
        ...state,
        showCountDown,
      };
    }

    default:
      return state;
  }
};
