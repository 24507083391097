import { saveAs } from 'file-saver';
import moment from 'moment';
import { API_DATE_FORMAT } from 'sb-ui-components/dist/lib/format-helper';
import { NullableString, ResourceResponse } from '../types';
import PolicyActionMap from './policy-action-map';

export function arrayContainsArray(superset?: string[], subset?: string[]) {
  if (!superset || !subset?.length) {
    return false;
  }
  if (superset.length < subset.length) {
    return false;
  }
  return subset?.every((value: string) => {
    return superset?.indexOf(value) >= 0;
  });
}

export function decoratePayloadData<T, K>(decoratorParams: {
  decoratePayload: ResourceResponse<T[]>;
  withPayload: ResourceResponse<K[]>;
  matchFunction: (listToDecorateElement: T, resourceListElement: K) => boolean;
  decoratorFunction: (listToDecorateElement: T, resourceListElement: K) => T;
}): ResourceResponse<T[]> {
  const { decoratePayload, withPayload, matchFunction, decoratorFunction } = decoratorParams;
  const decoratedData = decoratePayload.data?.map(elementToDecorate => {
    const matchingResourceElement = withPayload.data?.find(resourceListElement =>
      matchFunction(elementToDecorate, resourceListElement),
    );
    if (matchingResourceElement) {
      return decoratorFunction(elementToDecorate, matchingResourceElement);
    } else {
      return elementToDecorate;
    }
  });
  return {
    ...decoratePayload,
    data: decoratedData,
  };
}

export function isAuthorized(currentPolicyActions: string[] | undefined, authorizationRule: string) {
  return arrayContainsArray(currentPolicyActions, PolicyActionMap[authorizationRule as keyof typeof PolicyActionMap]);
}

export function openBase64BinaryInNewTab(data: string, contentType: string, target: string = '_blank') {
  const dataBlobUrl = dataToBlobUrl(data, contentType);
  if (!dataBlobUrl) {
    return;
  }
  const iframe = `<iframe width="100%" height="100%" style="border:0" src="data:application/pdf;base64, ${data}"></iframe>`;
  const win = window.open(dataBlobUrl, target);
  if (win) {
    win.document.open();
    win.document.write(iframe);
    win.document.close();
  }
  // … which we need to revoke to clear up memory as soon as page is rendered.
  URL.revokeObjectURL(dataBlobUrl);
}

export function dataToBlobUrl(data: string, contentType: string): NullableString {
  if (!data || !window) {
    return null;
  }
  // A blob is created from the binary data
  const blob = new Blob([data], { type: `${contentType};charset=utf-8` });
  // since uploaded documents can be large PDFs which exceed URL varying browser limits when encoded as base 64 data URL,
  // we need to create a temporary blob object in memory instead, which the data url is then pointing to …
  const dataBlobUrl = URL.createObjectURL(blob);
  return dataBlobUrl;
}

export function downloadBase64BinaryDataAsAttachment(data: string, fileName: string) {
  if (!data) {
    return;
  }
  const b64 = `data:application/pdf;base64,${data}`;
  saveAs(b64, fileName);
}

export const addDaysToApiDate = (date: NullableString, days: number) => {
  const momentDate = moment(date);
  if (momentDate.isValid() && !Number.isNaN(days)) {
    momentDate.add(days, 'days');
    return momentDate.format(API_DATE_FORMAT);
  }
  return date;
};

export const formatTime = (seconds: number) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};
