import { ModuleConfig } from '../../models/module';
import CredentialsCreatePage from './components/credentials-create-page';
import { CREDENTIALS_RESOURCE_NAME } from './models/credentials-create';
import { createCredentialsEnter, listenToCreateCredentials } from './sagas/credentials-create';

export const credentialConfig: ModuleConfig = {
  moduleName: CREDENTIALS_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToCreateCredentials],
  routes: {
    'credentials.create': {
      path: '/credentials-create',
      component: CredentialsCreatePage,
      skipAuthentication: true,
      saga: createCredentialsEnter,
    },
  },
};
