import { FunctionComponent } from 'react';

const PrivacyPolicyEN: FunctionComponent = () => {
  return (
    <>
      <h1>Data processing when visiting the website</h1>
      <p>
        Thank you for visiting our website. In the following, we would like to inform you about the handling of your
        data according to Art. 13 of the General Data Protection Regulation (GDPR).
      </p>

      <h2>Data Controller</h2>
      <p>The data controller for the data processing presented below is the:</p>
      <p>
        Solaris SE
        <br />
        Cuvrystraße 53
        <br />
        10997 Berlin
      </p>
      <p>
        E-mail address: <a href="mailto:privacy@solarisgroup.com">privacy@solarisgroup.com</a>
      </p>

      <h2>Contact details of the data protection officer</h2>
      <p>
        Our data protection officer will be happy to provide you with information on the subject of data protection
        under the following contact details:
      </p>
      <p>
        Data protection officer:{' '}
        <a href="mailto:eleonora.bianchi@solarisgroup.com">eleonora.bianchi@solarisgroup.com</a>
        <br />
        Deputy: <a href="mailto:sabrina.rudorf@solarisgroup.com">sabrina.rudorf@solarisgroup.com</a>
      </p>

      <h2>Usage data</h2>
      <p>
        When you visit our web pages, so-called usage data is temporarily evaluated on our web server for statistical
        purposes as a log in order to improve the quality of our web pages. This data set consists of
      </p>
      <ul>
        <li>the name and address of the requested content,</li>
        <li>the date and time of the query,</li>
        <li>the amount of data transferred,</li>
        <li>the access status (content transferred, content not found),</li>
        <li>the description of the web browser and operating system used,</li>
        <li>the referral link, which indicates from which page you have reached ours,</li>
        <li>
          the IP address of the requesting computer, which is shortened so that a personal reference can no longer be
          established.
        </li>
      </ul>
      <p>
        The aforementioned log data is only evaluated anonymously. The legal basis for the processing of usage data is
        Art. 6 para. 1 p. 1 lit. f GDPR. The processing is carried out in the legitimate interest of providing the
        content of the website and ensuring a device- and browser-optimized display.
      </p>

      <h2>Required cookies</h2>
      <p>
        We use cookies on our websites, which are necessary for the use of our websites. Cookies are small text files
        that are stored on your terminal device and can be read. A distinction is made between session cookies, which
        are deleted as soon as you close your browser, and permanent cookies, which are stored beyond the individual
        session. We do not use these necessary cookies for analysis, tracking or advertising purposes. In some cases,
        these cookies only contain information about certain settings and are not personally identifiable. They may also
        be necessary to enable user navigation, security and implementation of the site. The data processed by these
        cookies are necessary for the aforementioned purposes to protect our legitimate interests pursuant to Art. 6 (1)
        p. 1 lit. f GDPR in conjunction with Section 25 (2) No. 2 TTDSG to provide a service you requested.
      </p>
      <p>
        You can set your browser to inform you about the placement of cookies. In addition, you can delete them at any
        time via the corresponding browser setting and prevent the setting of new cookies. Please note that our websites
        may then not be fully displayed and some functions may no longer be technically available.
      </p>

      <h2>Personal data we process when you contact our customer service</h2>
      <p>
        When contacting us (e.g. via contact form, email, telephone) as well as in the context of existing user and
        business relationships, the information of the inquiring persons is processed insofar as this is necessary to
        answer the contact inquiries and any requested measures. The response to the contact inquiries as well as the
        management of contact and inquiry data in the context of contractual or pre-contractual relationships is carried
        out to fulfill our contractual obligations or to respond to (pre)contractual inquiries pursuant to Art. 6 (1) f)
        GDPR and otherwise on the basis of legitimate interests pursuant to Art. 6 (1) f) GDPR in responding to the
        inquiries and maintaining user or business relationships. We use various service providers for this purpose,
        including providers of CRM solutions and ticketing tools, providers for digital contract signing, providers of
        cloud solutions for office products.
      </p>

      <h2>Personal data we process in order to provide your user account</h2>
      <p>
        If you have an account with Solaris SE, you can access your account via SolarisGo if access is not possible via
        the partner app or website with which you originally registered and you have been invited to SolarisGo. To do
        this, you must first authenticate yourself. For authentication, we use the e-mail address and telephone number
        linked to your online banking account with us. We also ask for additional data such as your IBAN or date of
        birth to ensure that no unauthorized access to your account can take place.
      </p>
      <p>
        Once you have successfully authenticated yourself, you can log in with your username and mobile phone number,
        which we will use to send an SMS to you to access your online banking account, carry out transactions and edit
        your data. Without a mobile phone number and the possibility to receive SMS, it will not be possible for you to
        use SolarisGo.
      </p>
      <p>
        In addition to your personal identification data and contact data (name, address, date of birth), SolarisGo also
        processes bank data and connection data (in particular account connection data such as IBAN, account balances,
        transactions, log data) that are required for the provision of the web banking service. The legal basis for the
        processing of this data is Art. 6 para. 1 lit. b GDPR.
      </p>
      <p>
        As a bank, we are subject to various legal and regulatory obligations, i.e. statutory requirements (e.g. German
        Banking Act, Money Laundering Act, tax laws) and banking supervisory requirements (e.g. European Central Bank,
        European Banking Authority and German Federal Financial Supervisory Authority). To fulfill these obligations and
        requirements, it may also be necessary for us to process your personal data in accordance with Art. 6 para. 1
        lit. c GDPR and Art. 6 para. 1 lit. e GDPR. This may include tax data and transaction data. The purposes of
        processing include the prevention of fraud and money laundering as well as the fulfillment of tax control and
        reporting obligations and the assessment and management of risks for the bank. For this purpose, it may also be
        necessary for us to pass on your data to supervisory authorities, tax authorities or judicial authorities.
      </p>
      <p>
        We use service providers who support us with web hosting, fraud prevention, customer relationship management and
        online communication.
      </p>
      <p>
        For the purpose of processing certain payment transactions, we may need to transfer information about you to
        another bank or a specialized financial services company (e.g. SWIFT, VISA, Bankverlag).
      </p>

      <h2>Obligation to provide personal data</h2>
      <p>
        If you wish to gain access to your account and carry out transactions, you are obliged to provide us with the
        data required for the fulfillment of the contract, for secure online access to your account and for the
        fulfillment of our legal obligations. If you do not provide this data, we will not be able to continue the
        business relationship and grant you online access to the account.
      </p>

      <h2>Storage duration</h2>
      <p>
        Unless we have already informed you in detail about the storage period, we delete personal data when they are no
        longer required for the aforementioned processing purposes and no legitimate interests or other (legal)
        retention reasons prevent deletion.
      </p>

      <h2>Your rights as a data subject</h2>
      <p>When processing your personal data, the GDPR grants you certain rights as a data subject.</p>
      <h3>Right of access(Art. 15 GDPR)</h3>
      <p>
        You have the right to request confirmation as to whether personal data concerning you is being processed; if
        this is the case, you have a right to information about this personal data and to the information listed in
        detail in Article 15 of the GDPR.
      </p>
      <h3>Right to rectification (Art. 16 GDPR)</h3>
      <p>
        You have the right to request the correction of inaccurate personal data concerning you and, if necessary, the
        completion of incomplete data without delay.
      </p>
      <h3>Right to erasure (Art. 17 GDPR)</h3>
      <p>
        You have the right to request that personal data concerning you be deleted without undue delay, provided that
        one of the reasons listed in detail in Art. 17 GDPR applies.
      </p>
      <h3>Right to restriction of processing (Art. 18 GDPR)</h3>
      <p>
        You have the right to request the restriction of processing if one of the conditions listed in Art. 18 GDPR is
        met, e.g. if you have objected to the processing, for the duration of the review by the controller.
      </p>
      <h3>Right to data portability (Art. 20 GDPR)</h3>
      <p>
        In certain cases, which are listed in detail in Art. 20 GDPR, you have the right to receive the personal data
        concerning you in a structured, common and machine-readable format or to request the transfer of this data to a
        third party.
      </p>
      <h3>Right of withdrawal (Art. 7 GDPR)</h3>
      <p>
        If the processing of data is based on your consent, you are entitled to revoke your consent to the use of your
        personal data at any time in accordance with Art. 7 (3) GDPR. Please note that the revocation is only effective
        for the future. Processing that took place before the revocation is not affected.
      </p>
      <h3>Right of objection (Art. 21 GDPR)</h3>
      <p>
        If data is collected on the basis of Art. 6 (1) p. 1 lit. f GDPR (data processing for legitimate interests) or
        on the basis of Art. 6 (1) p. 1 lit. e GDPR (data processing for the protection of public interest or in the
        exercise of official authority), you have the right to object to the processing at any time for reasons arising
        from your particular situation. We will then no longer process the personal data unless there are demonstrably
        compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the
        processing serves to assert, exercise or defend legal claims.
      </p>
      <h3>Right to lodge a complaint with a supervisory authority (Art. 77 GDPR)</h3>
      <p>
        Pursuant to Art. 77 GDPR, you have the right to lodge a complaint with a supervisory authority if you are of the
        opinion that the processing of data concerning you violates data protection provisions. The right of complaint
        may be asserted in particular before a supervisory authority in the Member State of your habitual residence,
        your place of work or the place of the alleged infringement.
      </p>
      <p>Assertion of your rights</p>
      <p>
        Unless otherwise described above, please contact the data controller mentioned in the imprint to assert your
        data protection rights.
      </p>
    </>
  );
};

export default PrivacyPolicyEN;
