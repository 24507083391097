import { Address } from 'sb-api-types';
import { ResourceConfig } from 'src/types';
import { Enum } from 'typescript-string-enums';

export const CUSTOMER_RESOURCE_NAME = 'customer';

export const salutations = Enum('MR', 'MS', 'OTHER');
export type Salutation = Enum<typeof salutations>;
export interface TaxIdentification {
  readonly number: string;
  readonly country: string;
}

export interface CustomerAttributes {
  readonly external_identifier: string;
  readonly salutation: Salutation;
  readonly first_name: string;
  readonly last_name: string;
  readonly birth_date: string;
  readonly birth_city: string;
  readonly birth_country: string;
  readonly email: string;
  readonly address: Address;
  readonly tax_identifications?: TaxIdentification[];
}

export interface Customer {
  readonly type: 'customer';
  readonly id: string;
  readonly relationships: {}; // ToDo: Define type
  readonly attributes: CustomerAttributes;
}

export const customerResourceConfig: ResourceConfig = {
  resourceName: CUSTOMER_RESOURCE_NAME,
  endpoints: {
    show: {
      path: '/customers/me',
      httpMethod: 'get',
    },
  },
};
