import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BalanceCell, DataList, formatApiDate, InlineText } from 'sb-ui-components';
import { ListResourceState } from 'src/types';
import { ACCOUNT_TRANSACTION_RESOURCE_NAME, AccountTransaction } from '../models/account-transaction';

export const AccountTransactionList: FunctionComponent = () => {
  const accountTransactions: ListResourceState<AccountTransaction> = useSelector(
    (state: any) => state.list[ACCOUNT_TRANSACTION_RESOURCE_NAME],
  );

  const { formatMessage } = useIntl();

  if (!accountTransactions) return null;

  return (
    <DataList
      list={accountTransactions.list}
      errorMessage={accountTransactions.error && formatMessage({ id: 'error.loading' })}
      isLoading={accountTransactions.isLoading}
      noValuePlaceHolder={formatMessage({ id: 'message.noTransactionsFound' })}
      leadingIcon={(item: AccountTransaction) =>
        item.attributes.amount.value > 0 ? 'ArrowDownToArcLight' : 'ArrowUpFromArcLight'
      }
      leadingContent={(item: AccountTransaction) => (
        <InlineText nowrap type="div">
          {item.attributes.counterparty_name}
        </InlineText>
      )}
      leadingSubline={(item: AccountTransaction) => (
        <InlineText nowrap type="div" size="xsmall" color="soft">
          {formatApiDate(item.attributes.booked_at)}
        </InlineText>
      )}
      trailingContent={(item: AccountTransaction) => <BalanceCell data={item.attributes.amount} swapColorCoding />}
    />
  );
};
