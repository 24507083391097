import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  Button,
  CodeInput,
  InlineText,
  ItemGroup,
  ModalProps,
  ModalSection,
  SolarisSpinner,
  SvgIcon,
} from 'sb-ui-components';
import { ModalWrapper } from 'src/components/modal-wrapper';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { getLastCharsOfString } from 'src/modules/card/lib/helpers';
import { Card } from 'src/modules/card/models/card';
import { changeCardPinAction } from 'src/modules/card-change-pin-flow/redux/card-change-pin-flow-actions';
import { SmsChallengeModal } from 'src/modules/sms-challenge/components/sms-challenge-modal';
import { SMS_CHALLENGE_RESOURCE_NAME } from 'src/modules/sms-challenge/model/sms-challenge';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { Entity } from 'src/types';
import { validatePin } from '../../lib/helpers';
import {
  CARD_CHANGE_PIN_RESOURCE_NAME,
  CardChangePinFormValues,
  CardPinErrorMessage,
} from '../../models/card-change-pin-flow-model';
import styles from './card-change-pin-modal.module.scss';

export interface CardChangePinModalProps extends ModalProps {
  isOpen: boolean;
  card: Card;
  onClose: () => void;
}

export const CardChangePinModal: FunctionComponent<CardChangePinModalProps> = props => {
  const {
    card: {
      id: cardId,
      attributes: { representation, card_type },
    },
    isOpen,
    onClose,
  } = props;

  const maskedPan = getLastCharsOfString(representation.masked_pan);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, getValues } = useForm<CardChangePinFormValues>({ mode: 'onChange' });

  const [isStepConfirm, setIsStepConfirm] = useState<boolean | undefined>(undefined);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [firstInputErrorMessage, setFirstInputErrorMessage] = useState<CardPinErrorMessage | undefined>(undefined);
  const [secondInputErrorMessage, setSecondInputErrorMessage] = useState<CardPinErrorMessage | undefined>(undefined);

  const changePinState: Entity<unknown> = useSelector((state: any) => state.entity[CARD_CHANGE_PIN_RESOURCE_NAME]);
  const smsChallengeState: Entity<unknown> = useSelector((state: any) => state.entity[SMS_CHALLENGE_RESOURCE_NAME]);

  const isPinChangeLoading = changePinState?.loading;
  const isPinChangeSuccess = Boolean(changePinState?.data);
  const isPinChangeError = Boolean(changePinState?.error);

  const onFormSubmit = (formValues: CardChangePinFormValues) => {
    dispatch(changeCardPinAction({ cardId, pin: formValues['pin'] }));
  };

  const onCompleteFirst = (value: string) => {
    const { isValid, errorMessage } = validatePin(value);
    if (isValid) {
      setFirstInputErrorMessage(undefined);
      setIsStepConfirm(true);
    } else {
      setFirstInputErrorMessage(errorMessage);
    }
  };

  const onCompleteSecond = (value: string) => {
    if (value === getValues('pin')) {
      setSecondInputErrorMessage(undefined);
      setIsFormValid(true);
    } else {
      setSecondInputErrorMessage('CONFIRMATION_MISSMATCH');
      setIsFormValid(false);
    }
  };

  const handleOnClose = () => {
    // We have to reset the state to start from the beginning when user opens the modal again
    setIsStepConfirm(false);
    setSecondInputErrorMessage(undefined);
    setFirstInputErrorMessage(undefined);
    setIsFormValid(false);
    dispatch(resetEntityStateAction({ entityName: CARD_CHANGE_PIN_RESOURCE_NAME }));
    onClose();
  };

  // 2FA SMS Challenge Modal is displayed once the 2FA SMS challenge request was triggered
  if (smsChallengeState) {
    return (
      <SmsChallengeModal
        successMessage={{ title: formatMessage({ id: 'success.pinChange' }) }}
        isOpen={isOpen}
        onClose={handleOnClose}
      />
    );
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      modalProps={{
        maxWidth: 'xsmall',
        headerTitle: !isPinChangeError ? (
          <>
            {formatMessage({ id: 'title.changeCardPin' })}
            <br />
            <InlineText size="small" nowrap>
              <SvgIcon type="CreditCardLight" position="left" className={styles.cardIcon} />
              <span>{formatMessage({ id: `cardType.${card_type}`, defaultMessage: card_type })}</span>
              <span>{maskedPan}</span>
            </InlineText>
          </>
        ) : (
          undefined
        ),
        className: styles.modal,
        dataCy: 'modal__change_pin',
      }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalSection>
          <ItemGroup gutter="medium" justifyItems="center" className={styles.inputWrapper}>
            {isPinChangeLoading && <SolarisSpinner size="large" />}
            {isPinChangeError && (
              <SuccessErrorMessage
                type="error"
                title={formatMessage({ id: 'error.general.title' })}
                message={formatMessage({ id: 'error.general.message' })}
                cta={
                  <Button size="big" handleClick={handleOnClose}>
                    {formatMessage({ id: 'button.close' })}
                  </Button>
                }
              />
            )}
            {!changePinState && (
              <>
                <div className={classNames(styles.firstInput, { [styles.firstInputDisabled]: isStepConfirm })}>
                  <label>
                    <em>{formatMessage({ id: 'message.pin.new' })}</em>
                  </label>
                  <CodeInput
                    id="pin"
                    length={4}
                    control={control}
                    onComplete={onCompleteFirst}
                    disabled={isStepConfirm}
                    autoFocus={!isStepConfirm}
                    setValue={setValue}
                    masked
                    isInvalid={Boolean(firstInputErrorMessage)}
                    dataCy="pin_input"
                    errorMessage={
                      firstInputErrorMessage && formatMessage({ id: `inputError.pin.${firstInputErrorMessage}` })
                    }
                  />
                </div>
                {isStepConfirm && (
                  <div
                    className={classNames(styles.secondInput, { [styles.secondInputDisabled]: !isStepConfirm })}
                    data-cy="pin_confirm_input"
                  >
                    <label>
                      <em>{formatMessage({ id: 'message.pin.confirm' })}</em>
                    </label>
                    <CodeInput
                      id="confirmed_pin"
                      length={4}
                      control={control}
                      onComplete={onCompleteSecond}
                      autoFocus={isStepConfirm}
                      setValue={setValue}
                      masked
                      isValid={isFormValid}
                      isInvalid={Boolean(secondInputErrorMessage)}
                      errorMessage={
                        secondInputErrorMessage && formatMessage({ id: `inputError.pin.${secondInputErrorMessage}` })
                      }
                    />
                  </div>
                )}
              </>
            )}
          </ItemGroup>
        </ModalSection>
        <br />
        {!isPinChangeSuccess && !isPinChangeError && (
          <ModalSection>
            <ItemGroup justifyItems="space-between">
              <Button size="big" appearance="stroked" color="neutral" handleClick={handleOnClose}>
                {formatMessage({ id: 'button.cancel' })}
              </Button>
              <Button size="big" type="submit" disabled={!isFormValid || isPinChangeLoading} dataCy="cta__submit">
                {formatMessage({ id: 'button.changePin' })}
              </Button>
            </ItemGroup>
          </ModalSection>
        )}
      </form>
    </ModalWrapper>
  );
};
