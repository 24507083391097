import { FunctionComponent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, DataPresenter, formatApiDate, InlineText, ItemGroup, SvgIcon } from 'sb-ui-components';
import { CardChangePinModal } from '../../../card-change-pin-flow/components/card-change-pin-modal';
import { getLastCharsOfString } from '../../lib/helpers';
import { Card, CARD_BLOCK_UNBLOCK_STATE, cardStatusColorMap, visibleCardStatuses } from '../../models/card';
import { blockCardAction, unblockCardAction } from '../../redux/card-action';
import styles from './card-list-item.module.scss';

export type CardListItemProps = {
  card?: Card;
};

export const CardListItem: FunctionComponent<CardListItemProps> = props => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isProcessing = useSelector((state: any) => state.entity[CARD_BLOCK_UNBLOCK_STATE]?.loading);

  const { card } = props;

  // Hooks to open and close the change pin modal
  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);

  if (!card?.attributes) return null;

  const {
    id,
    attributes: { representation, status, card_type, expires_at },
  } = card;

  const blockCard = () => {
    dispatch(blockCardAction({ id }));
  };

  const unblockCard = () => {
    dispatch(unblockCardAction({ id }));
  };

  const maskedPan = getLastCharsOfString(representation.masked_pan);

  // We only display certain statuses
  const isStatusVisible = visibleCardStatuses.includes(status);

  return (
    <>
      <Box
        key={id}
        dataCy="card_item"
        isExpandable
        title={{
          text: (
            <>
              <SvgIcon type="CreditCardLight" size="big" className={styles.icon} />
              <span className={styles.title}>
                <span className={styles.cardType}>
                  {formatMessage({ id: `cardType.${card_type}`, defaultMessage: card_type })}
                </span>
                <span className={styles.maskedPin}>{maskedPan}</span>
                {isStatusVisible && (
                  <>
                    <InlineText size="small" color={cardStatusColorMap[status]}>
                      {formatMessage({ id: `status.${status}`, defaultMessage: status })}
                    </InlineText>
                  </>
                )}
              </span>
            </>
          ),
        }}
      >
        <DataPresenter
          noBox
          data={[
            { label: formatMessage({ id: 'label.cardHolder' }), value: representation.line1 },
            { label: formatMessage({ id: 'label.cardNo' }), value: representation.masked_pan },
            { label: formatMessage({ id: 'label.expDate' }), value: formatApiDate(expires_at) },
            ...(status === 'REPORTED'
              ? [
                  {
                    label: formatMessage({ id: 'label.reportingReason' }),
                    value: formatMessage({ id: 'label.lostOrStolen' }),
                  },
                ]
              : []),
          ]}
        />
        <ItemGroup className={styles.actions} gutter="big">
          {status === 'ACTIVE' && (
            <Button
              size="big"
              leadingIcon="LockSolid"
              handleClick={blockCard}
              dataCy="cta_card_block"
              isLoading={isProcessing}
            >
              {formatMessage({ id: 'button.block' })}
            </Button>
          )}
          {status === 'BLOCKED' && (
            <Button
              size="big"
              leadingIcon="UnlockSolid"
              handleClick={unblockCard}
              dataCy="cta_card_unblock"
              isLoading={isProcessing}
            >
              {formatMessage({ id: 'button.unblock' })}
            </Button>
          )}
          <Button
            size="big"
            appearance="stroked"
            handleClick={() => setIsChangePinModalOpen(true)}
            dataCy="cta__change_card_pin"
          >
            {formatMessage({ id: 'button.changePin' })}
          </Button>
        </ItemGroup>
      </Box>
      <CardChangePinModal isOpen={isChangePinModalOpen} card={card} onClose={() => setIsChangePinModalOpen(false)} />
    </>
  );
};
