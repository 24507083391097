import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { Account, ACCOUNT_RESOURCE_NAME } from 'src/modules/account/models/account';
import { showAccount } from 'src/modules/account/sagas';
import { REFERENCE_ACCOUNT_RESOURCE_NAME } from 'src/modules/reference-account/models/reference-account';
import { TRANSFER_CREATION_RESOURCE_NAME } from 'src/modules/transfer/model/transfer';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { clearListAction } from 'src/redux/list/list-actions';
import { Entity } from 'src/types';

export function* showPageEnterAccountTransfer(params: ApiRequestParams): SagaIterator<void> {
  const { id } = params.pathParams;

  // Reset the state of the transfer entity
  yield put(resetEntityStateAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));

  // Reset the state of the reference account list
  yield put(clearListAction({ resourceName: REFERENCE_ACCOUNT_RESOURCE_NAME }));

  const accountState: Entity<Account> = yield select(state => state.entity[ACCOUNT_RESOURCE_NAME]);

  // We only fetch the account data if it's not already in the store
  if (accountState?.data?.id !== id) {
    yield call(showAccount, params);
  }
}
