import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EN_DASH, EN_SPACE, ExternalLink, HorizontalDivider, ItemGroup } from 'sb-ui-components';
import { createPathByRouteName } from '../../lib/route-config-helper';
import { PRIVACY_POLICY_ROUTE } from '../../modules/privacy-policy';
import styles from './footer.module.scss';

export const Footer: FunctionComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <footer className={styles.base}>
      <ItemGroup alignItems="start" gutter="large" justifyItems="space-between">
        <ItemGroup gutterX="none">
          <span>
            {formatMessage({ id: 'copyright' })}
            {EN_SPACE}
            {EN_DASH}
            {EN_SPACE}
          </span>
          <span>Cuvrystraße 53, 10997 Berlin</span>
        </ItemGroup>
        <HorizontalDivider contrast="soft" weight="light" className={styles.line} />
        <ItemGroup alignItems="start" gutterX="large">
          <ExternalLink
            href={formatMessage({ id: 'url.customerSupport' })}
            text={formatMessage({ id: 'title.customerSupport' })}
          />
          <ExternalLink href={formatMessage({ id: 'url.imprint' })} text={formatMessage({ id: 'title.imprint' })} />
          <ExternalLink
            href={formatMessage({ id: 'url.termsOfUse' })}
            text={formatMessage({ id: 'title.termsOfUse' })}
          />
          <Link to={createPathByRouteName(`${PRIVACY_POLICY_ROUTE}`)}>
            {formatMessage({ id: 'title.privacyPolicy' })}
          </Link>
        </ItemGroup>
      </ItemGroup>
    </footer>
  );
};
