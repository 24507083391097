import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { resourceShowAndStoreToState } from 'src/sagas/resource-requests';
import { customerResourceConfig } from '../models/customer';

export function* showCustomer(): SagaIterator<void> {
  yield call(resourceShowAndStoreToState, {
    resourceConfig: customerResourceConfig,
    params: {
      pathParams: {},
      queryParams: {},
      headerParams: {},
    },
  });
}
