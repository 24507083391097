import { SagaIterator } from '@redux-saga/types';
import { Task } from 'redux-saga';
import { all, call, cancelled, delay, fork, put, takeLatest } from 'redux-saga/effects';
import { showCountDown, UI_MOUSE_CLICK_ACTION } from 'src/redux/ui/ui-actions';

export const SHOW_COUNTDOWN_AFTER_X_MINUTES = 3;
const MINUTE_IN_MS = 60 * 1000;
export const INACTIVITY_TIME_LIMIT = SHOW_COUNTDOWN_AFTER_X_MINUTES * MINUTE_IN_MS;

export function* logoutWhileIdling(): SagaIterator<void> {
  const taskStorage: Set<Task> = new Set([]);
  yield takeLatest(UI_MOUSE_CLICK_ACTION, handleActivity, taskStorage);
}

export function* handleActivity(taskStorage: Set<Task>) {
  const taskList = Array.from(taskStorage);
  // multiple timers may have been started - cancel all of them
  const tasksToCancel = taskList.map(task => call(task.cancel));
  yield all(tasksToCancel);
  // start new timer
  yield call(initializeAndStoreDelayedLogoutTask, taskStorage);
}

export function* initializeAndStoreDelayedLogoutTask(taskStorage: Set<Task>) {
  taskStorage.clear();
  const task: Task = yield fork(delayedLogout);
  taskStorage.add(task);
}

export function* delayedLogout() {
  try {
    // hide countdown if it is visible
    yield put(showCountDown(false));
    yield delay(INACTIVITY_TIME_LIMIT);
    // show countdown
    yield put(showCountDown(true));
  } finally {
    if (cancelled()) {
      //automatic logout cancelled
      // for debugging purposes we can issue console.log output here to
      // check if task was correctly cancelled.
    }
  }
}
