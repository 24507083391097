import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
} from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import { ResourceResponse } from 'src/types';
import {
  CREATE_REQUEST_SUPPORT_ENDPOINT,
  REQUEST_SUPPORT_RESOURCE_NAME,
  requestSupportResourceConfig,
  RequestSupportTicket,
} from '../../models/request-support';
import { SUBMIT_REQUEST_SUPPORT_TICKET, SubmitRequestSupportTicketAction } from '../../redux/request-support-actions';

export function* listenToCreateRequestSupportTicket(): SagaIterator<void> {
  yield takeEvery(SUBMIT_REQUEST_SUPPORT_TICKET, handleCreateSupportRequest);
}

export function* handleCreateSupportRequest(action: SubmitRequestSupportTicketAction): SagaIterator<void> {
  yield put(fetchEntityInitAction({ entityName: REQUEST_SUPPORT_RESOURCE_NAME }));

  const response: ResourceResponse<RequestSupportTicket> = yield call(resourceRequest, {
    resourceConfig: requestSupportResourceConfig,
    endpoint: CREATE_REQUEST_SUPPORT_ENDPOINT,
    apiRequestParams: {
      pathParams: {},
      queryParams: {},
    },
    requestBody: action.payload,
  });

  if (response.error) {
    yield put(fetchEntityErrorAction({ entityName: REQUEST_SUPPORT_RESOURCE_NAME, error: response.error }));
  } else {
    yield put(fetchEntitySuccessAction({ entityName: REQUEST_SUPPORT_RESOURCE_NAME }));
  }
}
