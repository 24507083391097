import { FunctionComponent } from 'react';
import { Redirect, Route, Router as ReactRouter, Switch } from 'react-router-dom';
// import LoginPage from 'src/modules/login/components/login-page';
import WelcomePage from 'src/modules/welcome-page/component/welcome-page';
import { RouterHookParams } from '../../../types';
import history from '../../lib/history';
import { defaultHomeRoute, RoutesConfig } from '../../lib/routes-config';
import { rfc6570ToReactRouterScheme } from '../../lib/url-builder';
import ProtectedRoute from '../protected-route';

interface RouterProps {
  loggedIn: boolean;
  bootFinished: boolean;
  routes: RoutesConfig;
  onEnterRoute: (params: RouterHookParams) => void;
}

const Router: FunctionComponent<RouterProps> = props => {
  const { routes, loggedIn, onEnterRoute, bootFinished } = props;

  return (
    <ReactRouter history={history}>
      <Switch>
        <Route exact path="/welcome" component={WelcomePage} />
        {Object.keys(routes).map(routeName => {
          const path = rfc6570ToReactRouterScheme(routes[routeName].path);
          // Make 100% all paths start with a slash
          const pathWithSlash = path.startsWith('/') ? path : `/${path}`;
          const skipAuthentication = Boolean(routes[routeName].skipAuthentication);
          const currentComponent = routes[routeName].component;
          return (
            <ProtectedRoute
              key={`route-${routeName}`}
              loggedIn={loggedIn}
              onEnterRoute={onEnterRoute}
              routeName={routeName}
              path={pathWithSlash}
              skipAuthentication={skipAuthentication}
              currentComponent={currentComponent}
              exact={true} // Needed hardcoded here to match routes with pathParams
              bootFinished={bootFinished}
            />
          );
        })}
        <Redirect from="/" to={defaultHomeRoute} />
      </Switch>
    </ReactRouter>
  );
};

export default Router;
