import { PayloadAction } from 'src/types';

export const CREATE_SMS_CHALLENGE_AUTH = 'smsChallengeAuth/CREATE';
export const CONFIRM_SMS_CHALLENGE_AUTH = 'smsChallengeAuth/CONFIRM';

// Create SMS challenge

export interface CreateSmsChallengeAuthActionPayload {
  challengeRequestId: string;
}

export type CreateSmsChallengeAuthAction = PayloadAction<
  typeof CREATE_SMS_CHALLENGE_AUTH,
  CreateSmsChallengeAuthActionPayload
>;

export const createSmsChallengeAuthAction = (
  payload: CreateSmsChallengeAuthActionPayload,
): CreateSmsChallengeAuthAction => ({
  type: CREATE_SMS_CHALLENGE_AUTH,
  payload,
});

// Confirm SMS challenge

export interface ConfirmSmsChallengeAuthActionPayload {
  formValues: { confirmation_value: string };
  pathParams: { challengeId: string };
}

export type ConfirmSmsChallengeAuthAction = PayloadAction<
  typeof CONFIRM_SMS_CHALLENGE_AUTH,
  ConfirmSmsChallengeAuthActionPayload
>;

export const confirmSmsChallengeAuthAction = (
  payload: ConfirmSmsChallengeAuthActionPayload,
): ConfirmSmsChallengeAuthAction => ({
  type: CONFIRM_SMS_CHALLENGE_AUTH,
  payload,
});
