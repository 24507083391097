import { Balance } from 'sb-ui-components';
import { Enum } from 'typescript-string-enums';
import { ResourceConfig } from '../../../types';

export const customer3dsStatuses = Enum('COMPLETED', 'EXPIRED', 'PENDING');
export type Customer3dsStatus = Enum<typeof customer3dsStatuses>;

export const customer3dsPriorities = Enum('HIGH', 'MEDIUM', 'LOW');
export type Customer3dsPriority = Enum<typeof customer3dsPriorities>;

export const customer3dsPlatforms = Enum('ANDROID', 'IOS', 'WEB');
export type Customer3dsPlatform = Enum<typeof customer3dsPlatforms>;

export const customer3dsAvailableActions = Enum('APPROVE_3DS', 'DECLINE_3DS', 'DISMISS');
export type Customer3dsAvailableAction = Enum<typeof customer3dsAvailableActions>;

export interface Customer3dsRelationships {
  readonly resource: {
    readonly data: {
      readonly type: 'card' | 'customer';
      readonly id: string;
    };
  };
}

export interface Customer3dsAttributes {
  readonly status: Customer3dsStatus;
  readonly priority: Customer3dsPriority;
  readonly platforms: Customer3dsPlatform[];
  readonly requires_immediate_action: boolean;
  readonly created_at: string;
  readonly expires_at: string;
  readonly available_actions: Customer3dsAvailableAction[];
  readonly amount: Balance;
  readonly merchant_name: string;
  readonly challenged_at: string;
  readonly masked_pan: string;
}

export interface Customer3ds {
  readonly type: 'notification' | '3ds_notification';
  readonly id: string;
  readonly relationships: Customer3dsRelationships;
  readonly attributes: Customer3dsAttributes;
}

// 3DS Notification
export interface NotificationAction {
  readonly data: {
    readonly type: 'notification_action';
    readonly attributes: {
      readonly action: Customer3dsAvailableAction;
    };
  };
}

export const CUSTOMER_3DS_RESOURCE_NAME = 'customer-3ds';
export const CUSTOMER_3DS_DECLINE_ENDPOINT = 'decline';
export const CUSTOMER_3DS_APPROVE_ENDPOINT = 'approve';
export const CUSTOMER_3DS_APPROVE_STATE = 'customer-3ds-approve';
export const CUSTOMER_3DS_DECLINE_STATE = 'customer-3ds-decline';

export const customer3dsResourceConfig: ResourceConfig = {
  resourceName: CUSTOMER_3DS_RESOURCE_NAME,
  endpoints: {
    index: {
      path: '/customers/{customerId}/notifications',
      httpMethod: 'get',
    },
    [CUSTOMER_3DS_APPROVE_ENDPOINT]: {
      path: '/notifications/{notificationId}/actions',
      httpMethod: 'post',
    },
    [CUSTOMER_3DS_DECLINE_ENDPOINT]: {
      path: '/notifications/{notificationId}/actions',
      httpMethod: 'post',
    },
  },
};
