import { SagaIterator } from 'redux-saga';
import { call, select, take } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { LIST_SKIP_PAGE_ACTION, SkipListPageAction } from 'src/redux/resources/resources-actions';
import { AppState } from 'src/types';

export function* listenToSkipPageAction(): SagaIterator {
  while (true) {
    const { payload }: SkipListPageAction = yield take(LIST_SKIP_PAGE_ACTION);
    // user might skip page while filtering results, this will ensure all queryparams are used
    const resourceListState = yield select((state: AppState) => state.list[payload!.resourceName]);
    const apiRequestParams: ApiRequestParams = {
      queryParams: {
        ...resourceListState.queryParams,
        'page[number]': payload!.pageNumber,
      },
      pathParams: resourceListState.pathParams,
    };
    yield call(payload!.paginationSaga, apiRequestParams);
  }
}
