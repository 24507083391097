import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SignInOutput } from 'aws-amplify/auth';
import { BoxWithIcon, Button, ItemGroup, PageSection, TextInput } from 'sb-ui-components';
import { AuthPageWrapper } from 'src/components/auth-wrapper';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { LOGIN_RESOURCE_NAME, LoginFormValues } from 'src/modules/login/models/login';
import { loginWithAmplify } from 'src/modules/login/redux/login-actions';
import { SmsChallengeAmplifyModal } from 'src/modules/sms-challenge-amplify/components';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { isPresent, noEmail } from '../../../lib/validation';
import { ACCOUNT_INDEX_ROUTE } from '../../account';
import styles from 'src/components/auth-wrapper/auth-wrapper.module.scss';

const LoginPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const isEnvProduction = window.location.host === 'solarisgo.solarisgroup.com';
  const isEnvSandbox = window.location.host === 'solarisgo.solaris-sandbox.com';
  const isEnvProductionOrSandbox = isEnvProduction || isEnvSandbox;

  const [isSmsChallengeModalOpen, setIsSmsChallengeModalOpen] = useState(false);

  const entityState: EntityState = useSelector((state: AppState) => state.entity);
  const loginState: Entity<SignInOutput> | undefined = entityState?.[LOGIN_RESOURCE_NAME];
  const isLoggedIn: boolean = useSelector((state: AppState) => state.auth.loggedIn);

  useEffect(() => {
    setIsSmsChallengeModalOpen(loginState?.data?.nextStep?.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE');
  }, [loginState?.data?.nextStep?.signInStep]);

  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitting },
  } = useForm<LoginFormValues>({ mode: 'onChange' });

  const onSubmitLogin = (formValues: LoginFormValues) => {
    const { username, password } = formValues;
    dispatch(loginWithAmplify({ password, username }));
  };

  return (
    <AuthPageWrapper>
      {!isLoggedIn && (
        <form className={styles.form} onSubmit={handleSubmit(onSubmitLogin)}>
          <h1>{formatMessage({ id: 'title.login' })}</h1>
          {loginState?.error && (
            <PageSection>
              <BoxWithIcon type="warning" icon="ExclamationTriangleLight" iconSize="big">
                {formatMessage({ id: `error.${loginState.error?.message}` })}
              </BoxWithIcon>
            </PageSection>
          )}
          <TextInput
            id="username"
            label={formatMessage({ id: 'label.username' })}
            register={register}
            disabled={loginState?.loading}
            validation={{
              validate: {
                isPresent: value => isPresent(value) || formatMessage({ id: 'inputError.required' }),
                noEmail: value => noEmail(value) || formatMessage({ id: 'inputError.notEmail' }),
              },
            }}
            errorMessage={errors.username?.message}
          />
          <TextInput
            id="password"
            type="password"
            label={formatMessage({ id: 'label.password' })}
            register={register}
            disabled={loginState?.loading}
          />
          <PageSection>
            <ItemGroup fullwidth gutterY="large" alignItems="start">
              <Button
                dataCy="cta__login"
                type="submit"
                size="big"
                disabled={isSubmitting}
                isLoading={loginState?.loading}
                className={styles.submitButton}
              >
                {formatMessage({ id: 'button.login' })}
              </Button>
              {/* Todo enable the following link */}
              {/* <Link
                to={createPathByRouteName('forgotPassword.flow')}
                data-cy="cta__forgot_password"
                className={styles.textLink}
              >
                {formatMessage({ id: 'button.forgotPassword' })}
              </Link> */}
            </ItemGroup>
          </PageSection>
        </form>
      )}

      {isLoggedIn && (
        <PageSection>
          <ItemGroup fullwidth gutterY="large" alignItems="center">
            <h1>{formatMessage({ id: 'message.signedIn' })}</h1>
            <Link to={createPathByRouteName(`${ACCOUNT_INDEX_ROUTE}`)}>
              <Button dataCy="cta__view_accounts" size="big" trailingIcon="ArrowRightRegular">
                {formatMessage({ id: 'button.viewMyAccounts' })}
              </Button>
            </Link>
            <Link to={'/logout'}>
              <Button dataCy="cta__sign_out" size="big" appearance="stroked" color="red" leadingIcon="SignOutRegular">
                {formatMessage({ id: 'button.signOut' })}
              </Button>
            </Link>
            {/* These mock flows need to be here because we are skipping login to run cypress specs locally */}
            {/* They are not displayed in production and sadbox */}
            {!isEnvProductionOrSandbox && (
              <>
                {/* Todo enable the following link */}
                {/* <Link
                  to={createPathByRouteName('forgotPassword.flow')}
                  data-cy="cta__forgot_password"
                  className={styles.textLink}
                >
                  {formatMessage({ id: 'button.forgotPassword' })}
                </Link> */}
                <Link
                  to={createPathByRouteName('forgotPassword.reset')}
                  data-cy="cta__forgot_password_reset"
                  className={styles.textLink}
                >
                  Set new password from email mock
                </Link>
                <Link
                  to={createPathByRouteName('credentials.create')}
                  data-cy="cta__credentials_create"
                  className={styles.textLink}
                >
                  Set credentials from email mock
                </Link>
                <Link
                  to={createPathByRouteName('phone.verification')}
                  data-cy="cta__phone_verification"
                  className={styles.textLink}
                >
                  Phone Verification
                </Link>
                <Link
                  to={createPathByRouteName('emailVerification.verify')}
                  data-cy="cta__email_verification"
                  className={styles.textLink}
                >
                  Verify Email flow mock
                </Link>
              </>
            )}
          </ItemGroup>
        </PageSection>
      )}
      <SmsChallengeAmplifyModal isOpen={isSmsChallengeModalOpen} />
    </AuthPageWrapper>
  );
};

export default LoginPage;
