import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Balance } from 'sb-ui-components';
import {
  fetchEntityErrorAction,
  fetchEntityInitAction,
  fetchEntitySuccessAction,
  resetEntityStateAction,
} from 'src/redux/entity/entity-actions';
import { v4 as uuidv4 } from 'uuid';
import { resourceRequest } from '../../../../sagas/resource-requests';
import { createSmsChallengeAction } from '../../../sms-challenge/redux/sms-challenge-actions';
import {
  accountTransferResourceConfig,
  CREATE_SEPA_CREDIT_TRANSFER_ENDPOINT,
  TRANSFER_CREATION_RESOURCE_NAME,
} from '../../model/transfer';
import { CREATE_SEPA_CREDIT_TRANSFER_ACTION, CreateSepaCreditTransferAction } from '../../redux/transfer-actions';

export function* listenToCreateSepaCreditTransfer(): SagaIterator<void> {
  yield takeEvery(CREATE_SEPA_CREDIT_TRANSFER_ACTION, handleCreateSepaCreditTransfer);
}

export function* handleCreateSepaCreditTransfer(action: CreateSepaCreditTransferAction): SagaIterator<void> {
  const { formValues, pathParams } = action.payload;
  const { account_id } = pathParams;
  const { amount, description, recipient_iban, recipient_name } = formValues;

  // Set loading state
  yield put(fetchEntityInitAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));

  // Create SEPA credit transfer
  const createSepaCreditTransferResponse = yield call(createSepaCreditTransfer, {
    account_id,
    amount,
    description,
    recipient_iban,
    recipient_name,
  });

  // Error handling
  if (createSepaCreditTransferResponse.error) {
    yield put(
      fetchEntityErrorAction({
        entityName: TRANSFER_CREATION_RESOURCE_NAME,
        error: createSepaCreditTransferResponse.error,
      }),
    );
    return;
  }

  // Remove loading state
  yield put(resetEntityStateAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));
  if (createSepaCreditTransferResponse.status === 202) {
    // Trigger SMS challenge
    const customerId = yield select(state => state.entity?.customer?.data?.id);
    const { id: challengeRequestId } = createSepaCreditTransferResponse.data;
    yield put(createSmsChallengeAction({ challengeRequestId, customerId }));
  }

  if (createSepaCreditTransferResponse.status === 201) {
    yield put(fetchEntitySuccessAction({ entityName: TRANSFER_CREATION_RESOURCE_NAME }));
  }
}

export interface CreateSepaCreditTransferArgs {
  account_id: string;
  amount: Balance;
  description?: string;
  recipient_iban: string;
  recipient_name: string;
}

export function* createSepaCreditTransfer(args: CreateSepaCreditTransferArgs): SagaIterator<void> {
  const { account_id, amount, description, recipient_iban, recipient_name } = args;

  const requestBody = {
    data: {
      type: 'sepa_credit_transfer',
      attributes: {
        reference: uuidv4(),
        recipient_name,
        recipient_iban,
        description,
        amount,
      },
    },
  };

  return yield call(resourceRequest, {
    resourceConfig: accountTransferResourceConfig,
    endpoint: CREATE_SEPA_CREDIT_TRANSFER_ENDPOINT,
    apiRequestParams: {
      pathParams: { account_id },
      queryParams: {},
    },
    requestBody,
  });
}
