import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CodeInput, ItemGroup, ModalSection, SolarisSpinner } from 'sb-ui-components';
import { ModalWrapper } from 'src/components/modal-wrapper';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { SMS_CHALLENGE_AUTH_RESOURCE_NAME } from 'src/modules/sms-challenge-auth/model/sms-challenge-auth';
import { fetchEntityInitAction } from 'src/redux/entity/entity-actions';
import { EntityState } from 'src/redux/entity/entity-reducer';
import { AppState, Entity } from 'src/types';
import { CONFIRM_PASSWORD_STATE } from '../models/forgot-password';
import { confirmResetPasswordAction } from '../redux/forgot-password-actions';
import styles from './forgot-password.module.scss';

export interface ForgotPasswordSmsChallengeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ForgotPasswordSmsChallengeModal: FunctionComponent<ForgotPasswordSmsChallengeModalProps> = props => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { control, handleSubmit, setValue } = useForm<{ confirmation_value: string }>({
    mode: 'onChange',
  });
  const { isOpen, onClose } = props;
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const entityState: EntityState = useSelector((state: AppState) => state.entity);

  const smsChallengeState: Entity<any> | undefined = entityState?.[SMS_CHALLENGE_AUTH_RESOURCE_NAME];
  const isChallengeLoading = smsChallengeState?.loading;
  const isChallengeSuccess = smsChallengeState?.data?.attributes?.status === 'COMPLETED';
  const isChallengeError = Boolean(smsChallengeState?.error);

  const confirmPasswordState: Entity<any> | undefined = entityState?.[CONFIRM_PASSWORD_STATE];
  const isConfirmPasswordLoading = confirmPasswordState?.loading;

  const onHandleResend = (e: MouseEvent) => {
    e.preventDefault(); // Prevents submitting the form
    setIsFormValid(false);
    // reset loading state
    dispatch(fetchEntityInitAction({ entityName: SMS_CHALLENGE_AUTH_RESOURCE_NAME }));
    onClose();
  };

  const onFormSubmit = (formValues: { confirmation_value: string }) => {
    dispatch(confirmResetPasswordAction(formValues));
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      modalProps={{
        maxWidth: 'xsmall',
        headerTitle: !isChallengeSuccess && !isChallengeError ? formatMessage({ id: 'title.enterSmsCode' }) : undefined,
        className: styles.modal,
        dataCy: 'modal__sms_challenge',
      }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <ModalSection className={styles.content}>
          <ItemGroup justifyItems="center" gutterY="large" fullwidth>
            {isChallengeLoading && <SolarisSpinner size="large" />}
            {!isChallengeLoading && !isChallengeSuccess && (
              <>
                {!isChallengeError ? (
                  <>
                    <label>
                      <em>{formatMessage({ id: 'message.smsChallenge' })}</em>
                    </label>
                    <CodeInput
                      id="confirmation_value"
                      length={6}
                      control={control}
                      autoFocus={true}
                      setValue={setValue}
                      onComplete={() => setIsFormValid(true)}
                      dataCy="confirmation_value_input"
                      errorMessage={isChallengeError ? formatMessage({ id: 'inputError.smsChallenge' }) : undefined}
                    />
                  </>
                ) : (
                  <SuccessErrorMessage
                    type="error"
                    title={formatMessage({ id: 'error.general.title' })}
                    message={formatMessage({ id: 'error.general.message' })}
                    cta={
                      <Button
                        handleClick={onHandleResend}
                        dataCy="cta__back_to_login"
                        size="big"
                        trailingIcon="ArrowRightRegular"
                      >
                        {formatMessage({ id: 'button.tryAgain' })}
                      </Button>
                    }
                  />
                )}
              </>
            )}
          </ItemGroup>
        </ModalSection>

        {!isChallengeSuccess && !isChallengeError && (
          <ModalSection>
            <ItemGroup justifyItems={isChallengeError ? 'center' : 'space-between'}>
              <Button size="big" appearance="stroked" color="neutral" handleClick={onClose}>
                {formatMessage({ id: 'button.cancel' })}
              </Button>
              <Button
                size="big"
                type="submit"
                disabled={!isFormValid || isChallengeLoading}
                isLoading={isConfirmPasswordLoading}
                dataCy="cta__submit_confirmation_value"
              >
                {formatMessage({ id: 'button.confirm' })}
              </Button>
            </ItemGroup>
          </ModalSection>
        )}
      </form>
    </ModalWrapper>
  );
};
