import { ModuleConfig } from '../../models/module';
import ForgotPasswordPage from './components/forgot-password-page';
import ForgotPasswordResetPage from './components/forgot-password-reset-page';
import { FORGOT_PASSWORD_RESOURCE_NAME } from './models/forgot-password';
import { listenToForgotPassword } from './sagas/forgot-password-request/forgot-password';
import {
  listenToConfirmResetPassword,
  listenToResetPassword,
} from './sagas/forgot-password-reset/forgot-password-reset';

export const forgotPasswordConfig: ModuleConfig = {
  moduleName: FORGOT_PASSWORD_RESOURCE_NAME,
  requires: [],
  redux: {},
  listenerSagas: [listenToForgotPassword, listenToResetPassword, listenToConfirmResetPassword],
  routes: {
    'forgotPassword.flow': {
      path: '/forgot-password',
      component: ForgotPasswordPage,
      skipAuthentication: true,
    },
    'forgotPassword.reset': {
      path: '/password-reset',
      component: ForgotPasswordResetPage,
      skipAuthentication: true,
    },
  },
};
