import {
  BOOT_FINISHED_ACTION,
  BootFinishedAction,
  INITIAL_PAGE_ENTER_FINISHED,
  InitialPageEnterDoneAction,
} from './boot-actions';

export interface BootState {
  done: boolean;
  initialPageEnterDone: boolean;
}

export const DEFAULT_BOOT_STATE: BootState = {
  done: false,
  initialPageEnterDone: false,
};

export const boot = (
  state: BootState = DEFAULT_BOOT_STATE,
  action: BootFinishedAction | InitialPageEnterDoneAction,
): BootState => {
  switch (action.type) {
    case BOOT_FINISHED_ACTION:
      return {
        ...state,
        done: true,
      };
    case INITIAL_PAGE_ENTER_FINISHED:
      return {
        ...state,
        initialPageEnterDone: true,
      };
    default:
      return state;
  }
};
