import { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Box, IbanInput, isIbanFormat, TextInput } from 'sb-ui-components';
import { Account } from 'src/modules/account/models/account';
import { SepaCreditTransferFormValues, TransferStep } from '../model/transfer';
import { CreateTransferFormButtons } from './create-transfer-form-buttons';
import { TransferDetailsFormSection } from './transfer-details-form-section';

export interface SepaCreditTransferFormProps {
  account: Account;
  setStep: (step: TransferStep) => void;
  setSummaryData: (data: SepaCreditTransferFormValues) => void;
  summaryData?: SepaCreditTransferFormValues;
}

export const SepaCreditTransferForm: FunctionComponent<SepaCreditTransferFormProps> = props => {
  const { setStep, summaryData, setSummaryData, account } = props;

  const { formatMessage } = useIntl();
  const { register, control, handleSubmit, errors, reset } = useForm<SepaCreditTransferFormValues>({
    mode: 'onSubmit',
  });

  const onSubmit = (formValues: SepaCreditTransferFormValues) => {
    setStep('SUMMARY');
    setSummaryData(formValues);
  };

  useEffect(() => {
    if (summaryData) {
      reset(summaryData);
    }
  }, [reset, summaryData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <TextInput
          id="recipient_name"
          label={formatMessage({ id: 'label.name' })}
          register={register}
          validation={{
            validate: {
              required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
            },
          }}
          errorMessage={errors.recipient_name?.message}
        />
        <IbanInput
          label={formatMessage({ id: 'label.iban' })}
          id="recipient_iban"
          register={register}
          control={control}
          validation={{
            validate: {
              required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
              invalid: value => isIbanFormat(value) || formatMessage({ id: 'inputError.invalidIban' }),
            },
          }}
          errorMessage={errors.recipient_iban?.message}
        />
      </Box>

      <TransferDetailsFormSection
        control={control}
        register={register}
        errors={errors}
        balance={account.attributes.balance}
      />

      <CreateTransferFormButtons />
    </form>
  );
};
