import { SagaIterator } from 'redux-saga';
import { call, takeLatest } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { resourceIndexAndStoreToState } from 'src/sagas/resource-requests';
import { referenceAccountResourceConfig } from '../models/reference-account';
import {
  INDEX_REFERENCE_ACCOUNT_LIST_ACTION,
  IndexReferenceAccountListAction,
} from '../redux/reference-account-actions';

export function* indexReferenceAccountList(params: ApiRequestParams): SagaIterator<void> {
  yield call(resourceIndexAndStoreToState, { resourceConfig: referenceAccountResourceConfig, params });
}

export function* listenToIndexReferenceAccountList(): SagaIterator<void> {
  yield takeLatest(INDEX_REFERENCE_ACCOUNT_LIST_ACTION, handleIndexReferenceAccountList);
}

export function* handleIndexReferenceAccountList(action: IndexReferenceAccountListAction): SagaIterator<void> {
  const { accountId } = action.payload;
  yield call(indexReferenceAccountList, { pathParams: { id: accountId }, queryParams: {} });
}
