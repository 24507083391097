import { PayloadAction } from '../../../types';

export const FORGOT_PASSWORD_REQUEST_ACTION = 'forgotPassword/REQUEST';
export const RESET_PASSWORD_ACTION = 'forgotPassword/RESET';
export const CONFIRM_RESET_PASSWORD_ACTION = 'forgotPassword/CONFIRM';

export type ForgotPasswordRequestAction = PayloadAction<typeof FORGOT_PASSWORD_REQUEST_ACTION, { username: string }>;

export function forgotPasswordRequestAction(payload: { username: string }): ForgotPasswordRequestAction {
  return {
    type: FORGOT_PASSWORD_REQUEST_ACTION,
    payload,
  };
}

// Reset

export type ResetPasswordAction = PayloadAction<typeof RESET_PASSWORD_ACTION, { password: string }>;

export function resetPasswordAction(payload: { password: string }): ResetPasswordAction {
  return {
    type: RESET_PASSWORD_ACTION,
    payload,
  };
}

// Confirm

export type ConfirmResetPasswordAction = PayloadAction<
  typeof CONFIRM_RESET_PASSWORD_ACTION,
  { confirmation_value: string }
>;

export function confirmResetPasswordAction(payload: { confirmation_value: string }): ConfirmResetPasswordAction {
  return {
    type: CONFIRM_RESET_PASSWORD_ACTION,
    payload,
  };
}
