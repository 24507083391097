import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchEntityInitAction, resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { resourceRequest } from '../../../../sagas/resource-requests';
import {
  CUSTOMER_3DS_DECLINE_ENDPOINT,
  CUSTOMER_3DS_DECLINE_STATE,
  customer3dsResourceConfig,
  NotificationAction,
} from '../../models/customer-3ds';
import { DECLINE_CUSTOMER_3DS_ACTION, DeclineCustomer3dsAction } from '../../redux/customer-3ds-actions';
import { indexCustomer3dsList } from '../index/customer-3ds-index';

export function* listenToDeclineCustomer3ds(): SagaIterator<void> {
  yield takeEvery(DECLINE_CUSTOMER_3DS_ACTION, handleDeclineCustomer3ds);
}

export function* handleDeclineCustomer3ds(action: DeclineCustomer3dsAction): SagaIterator<void> {
  const { notificationId } = action.payload;
  const entityName = `${CUSTOMER_3DS_DECLINE_STATE}_${notificationId}`;

  // Set loading state
  yield put(fetchEntityInitAction({ entityName }));

  // Decline customer 3DS notification
  const requestBody: NotificationAction = {
    data: {
      type: 'notification_action',
      attributes: {
        action: 'DECLINE_3DS',
      },
    },
  };

  yield call(resourceRequest, {
    resourceConfig: customer3dsResourceConfig,
    endpoint: CUSTOMER_3DS_DECLINE_ENDPOINT,
    apiRequestParams: {
      pathParams: { notificationId },
      queryParams: {},
    },
    requestBody,
  });

  // Remove loading state
  yield put(
    resetEntityStateAction({
      entityName,
    }),
  );

  // Refetch 3ds list
  yield call(indexCustomer3dsList);
}
