import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { encryptSecretWithJwk } from 'src/lib/encryption';
import {
  CARD_CHANGE_PIN_ACTION,
  ChangeCardPinAction,
} from 'src/modules/card-change-pin-flow/redux/card-change-pin-flow-actions';
import { createSmsChallengeAction } from 'src/modules/sms-challenge/redux/sms-challenge-actions';
import { fetchEntityErrorAction, fetchEntityInitAction } from 'src/redux/entity/entity-actions';
import { resourceRequest } from 'src/sagas/resource-requests';
import { ResourceResponse } from 'src/types';
import {
  CARD_CHANGE_PIN_RESOURCE_NAME,
  CARD_GET_ENCRYPTION_KEY_ENDPOINT,
  CARD_POST_CHANGE_PIN_ENDPOINT,
  cardChangePinResourceConfig,
  CardEncryptionKey,
} from '../models/card-change-pin-flow-model';

// Listen to change card pin action

export function* listenToChangeCardPin(): SagaIterator<void> {
  yield takeLatest(CARD_CHANGE_PIN_ACTION, handleChangeCardPin);
}

// Get encryption key

export function* loadCardEncryptionKey(cardId: string): SagaIterator<ResourceResponse<CardEncryptionKey>> {
  return yield call(resourceRequest, {
    resourceConfig: cardChangePinResourceConfig,
    endpoint: CARD_GET_ENCRYPTION_KEY_ENDPOINT,
    apiRequestParams: {
      pathParams: { cardId },
      queryParams: {},
    },
  });
}

// Post PIN change request
export interface RequestPinChangeArgs {
  cardId: string;
  encryptedPin: string;
  encryptionKey: string;
}

export function* requestPinChange(args: RequestPinChangeArgs): SagaIterator<any> {
  const { cardId, encryptedPin, encryptionKey } = args;
  const requestBody = {
    data: {
      type: 'pin_change_request',
      attributes: {
        encryption_key_id: encryptionKey,
        encrypted_pin: encryptedPin,
      },
    },
  };

  return yield call(resourceRequest, {
    resourceConfig: cardChangePinResourceConfig,
    endpoint: CARD_POST_CHANGE_PIN_ENDPOINT,
    apiRequestParams: {
      pathParams: { cardId },
      queryParams: {},
    },
    requestBody,
  });
}

// Handle change card pin

export function* handleChangeCardPin(action: ChangeCardPinAction): SagaIterator<void> {
  const { cardId, pin } = action.payload;

  // Set loading state
  yield put(fetchEntityInitAction({ entityName: CARD_CHANGE_PIN_RESOURCE_NAME }));

  // Get encryption key
  const encryptionKeyResponse: ResourceResponse<CardEncryptionKey> = yield call(loadCardEncryptionKey, cardId);

  // If request fails set error state and return from this saga
  if (encryptionKeyResponse.error) {
    yield put(
      fetchEntityErrorAction({ entityName: CARD_CHANGE_PIN_RESOURCE_NAME, error: encryptionKeyResponse.error }),
    );
    return;
  }

  // Encrypt pin
  const jwk = Array.isArray(encryptionKeyResponse.data) && encryptionKeyResponse.data[0]?.attributes?.jwk;
  const encryptedPin: string = yield call(encryptSecretWithJwk, pin, jwk);
  const encryptionKey = jwk?.kid;

  // Post pin change request
  const postPinChangeResponse = yield call(requestPinChange, { cardId, encryptedPin, encryptionKey });

  // If request fails set error state and return from this saga
  if (postPinChangeResponse.error) {
    yield put(
      fetchEntityErrorAction({ entityName: CARD_CHANGE_PIN_RESOURCE_NAME, error: postPinChangeResponse.error }),
    );
    return;
  }

  // Open SMS challenge modal
  const customerId = yield select(state => state.entity?.customer?.data?.id);
  const { id: challengeRequestId } = postPinChangeResponse.data;
  yield put(createSmsChallengeAction({ challengeRequestId, customerId }));
}
