import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DateInput, IbanInput, InputErrorMessage, isIbanFormat, TextInput } from 'sb-ui-components';
import { SuccessErrorMessage } from 'src/components/success-error-message';
import { fourDigitValidation } from 'src/lib/validation';
import { resetEntityStateAction } from 'src/redux/entity/entity-actions';
import { AppState } from 'src/types';
import { AuthPageWrapper } from '../../../components/auth-wrapper';
import { EMAIL_VERIFICATION_RESOURCE_NAME, EmailVerificationRequestFormValues } from '../models/email-verification';
import { emailVerificationRequestAction } from '../redux/email-verification-actions';
import styles from 'src/components/auth-wrapper/auth-wrapper.module.scss';

const EmailVerificationPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const entityState = useSelector((state: AppState) => state.entity);
  const createEmailVerificationState = entityState[EMAIL_VERIFICATION_RESOURCE_NAME];
  const isPending = createEmailVerificationState?.loading;
  const isSuccess = Boolean(createEmailVerificationState?.data);
  const isError = Boolean(createEmailVerificationState?.error);

  // form
  const { control, handleSubmit, errors, register } = useForm<EmailVerificationRequestFormValues>({
    mode: 'onSubmit',
  });

  const onSubmit = (formValues: EmailVerificationRequestFormValues) => {
    dispatch(emailVerificationRequestAction({ formValues }));
  };

  if (isSuccess) {
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="success"
          title={formatMessage({ id: 'title.checkEmails' })}
          message={formatMessage({ id: 'success.emailVerification.message' })}
        />
      </AuthPageWrapper>
    );
  }

  if (isError) {
    return (
      <AuthPageWrapper>
        <SuccessErrorMessage
          type="error"
          title={formatMessage({ id: 'error.general.title' })}
          message={formatMessage({ id: 'error.general.message' })}
          cta={
            <Button
              size="big"
              trailingIcon="ArrowRightRegular"
              handleClick={() => dispatch(resetEntityStateAction({ entityName: EMAIL_VERIFICATION_RESOURCE_NAME }))}
            >
              {formatMessage({ id: 'button.tryAgain' })}
            </Button>
          }
        />
      </AuthPageWrapper>
    );
  }

  return (
    <>
      <AuthPageWrapper>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <h1>{formatMessage({ id: 'title.verifyIdentity' })}</h1>
          <p>{formatMessage({ id: 'message.verifyEmail' })}</p>
          <IbanInput
            label={formatMessage({ id: 'label.iban' })}
            id="iban"
            control={control}
            disabled={isPending}
            validation={{
              validate: {
                required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
                invalid: value => isIbanFormat(value) || formatMessage({ id: 'inputError.invalidIban' }),
              },
            }}
            errorMessage={errors.iban?.message}
          />
          <TextInput
            id="mobile_number"
            register={register}
            disabled={isPending}
            validation={{
              validate: {
                required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
                invalid: value => fourDigitValidation(value) || formatMessage({ id: 'inputError.invalidFourDigit' }),
              },
            }}
            label={formatMessage({ id: 'label.fourDigit' })}
            errorMessage={errors.mobile_number?.message}
          />
          <DateInput
            control={control}
            id="birth_date"
            label={formatMessage({ id: 'label.birthDate' })}
            disabled={isPending}
            validation={{
              validate: {
                required: value => Boolean(value) || formatMessage({ id: 'inputError.required' }),
              },
            }}
          >
            <InputErrorMessage errorMessage={errors.birth_date ? formatMessage({ id: 'inputError.required' }) : ''} />
          </DateInput>

          <br />
          <Button dataCy="cta__verify_email" className={styles.button} type="submit" size="big" isLoading={isPending}>
            {formatMessage({ id: 'button.verify' })}
          </Button>
        </form>
      </AuthPageWrapper>
    </>
  );
};

export default EmailVerificationPage;
