import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Box, DataPresenter, EN_DASH, Header } from 'sb-ui-components';
import { Customer } from 'src/modules/customer/models/customer';
import { Entity } from 'src/types';

export interface CustomerDetailsProps {
  customer: Entity<Customer>;
}

export const CustomerTaxInformation: FunctionComponent<CustomerDetailsProps> = props => {
  const { customer } = props;
  const { formatMessage } = useIntl();

  const error = customer?.error;
  const attributes = customer?.data?.attributes;
  const { tax_identifications } = attributes || {};
  return (
    <>
      {error ? (
        <Box>
          {error.status} {EN_DASH} {error.message}
        </Box>
      ) : (
        <>
          <Header>
            <h2>{formatMessage({ id: 'title.taxInformation' })}</h2>
          </Header>
          {tax_identifications?.map(taxIdentification => (
            <DataPresenter
              dataCy="customerTaxInformationData"
              key={taxIdentification.number}
              isLoading={customer?.loading}
              data={[
                {
                  label: formatMessage({ id: 'label.taxCountry' }),
                  value: taxIdentification.country,
                },
                {
                  label: formatMessage({ id: 'label.taxId' }),
                  value: taxIdentification.number,
                },
              ]}
            />
          ))}
        </>
      )}
    </>
  );
};
