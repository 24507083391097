import { RouterHookParams } from '../../types';

export const ON_ENTER_ROUTE_ACTION = 'router/ON_ENTER_ROUTE_ACTION';

export interface OnEnterRouteAction {
  readonly type: typeof ON_ENTER_ROUTE_ACTION;
  readonly payload: RouterHookParams;
}

export const onEnterRouteAction = (payload: RouterHookParams): OnEnterRouteAction => ({
  type: ON_ENTER_ROUTE_ACTION,
  payload,
});
