import { Entity } from '../../types';
import {
  FETCH_ENTITY_ERROR_ACTION,
  FETCH_ENTITY_INIT_ACTION,
  FETCH_ENTITY_SUCCESS_ACTION,
  FetchEntityErrorAction,
  FetchEntityInitAction,
  FetchEntitySuccessAction,
  RESET_ENTITY_STATE_ACTION,
  ResetEntityStateAction,
} from './entity-actions';

export interface EntityState {
  [entityName: string]: Entity<any> | undefined;
}

export const DEFAULT_ENTITY_STATE: EntityState = {};

export const entity = (
  state: EntityState = DEFAULT_ENTITY_STATE,
  action: FetchEntityInitAction | FetchEntitySuccessAction | FetchEntityErrorAction | ResetEntityStateAction,
): EntityState => {
  switch (action.type) {
    case FETCH_ENTITY_INIT_ACTION: {
      return {
        ...state,
        [action.payload.entityName]: { loading: true },
      };
    }
    case FETCH_ENTITY_SUCCESS_ACTION: {
      return {
        ...state,
        [action.payload.entityName]: {
          loading: false,
          data: { ...action.payload.data },
        },
      };
    }
    case FETCH_ENTITY_ERROR_ACTION: {
      return {
        ...state,
        [action.payload.entityName]: {
          loading: false,
          error: action.payload.error,
        },
      };
    }
    case RESET_ENTITY_STATE_ACTION: {
      return action.payload?.entityName ? { ...state, [action.payload.entityName]: undefined } : DEFAULT_ENTITY_STATE;
    }
    default: {
      return state;
    }
  }
};
