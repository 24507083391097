import { SagaIterator } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiRequestParams } from 'src/lib/api';
import { filterFormToFilterQueryParams } from 'src/lib/converter';
import { clearListAction } from 'src/redux/list/list-actions';
import { FilterListAndUidSearchAction, LIST_FILTER_AND_UID_SEARCH_ACTION } from 'src/redux/resources/resources-actions';
import { AppState } from 'src/types';

export function* listenToFilterAndUidSearchAction() {
  yield takeLatest(LIST_FILTER_AND_UID_SEARCH_ACTION, filterListAndUidSearch);
}

export function* filterListAndUidSearch(action: FilterListAndUidSearchAction): SagaIterator {
  const { payload } = action;
  let payloadValues = (payload && payload.values) || {};
  const listFromState = yield select((state: AppState) => state.list[payload!.resourceName]);
  const pathParams = payload!.pathParams || (listFromState && listFromState.pathParams) || {};
  const queryParams = (listFromState && listFromState.queryParams) || {};
  const apiRequestParamsByName: ApiRequestParams = {
    queryParams: {
      ...payload.queryParams,
      ...filterFormToFilterQueryParams(payloadValues),
      ...(queryParams.sort && { sort: queryParams.sort }),
      ...(queryParams['page[size]'] && { 'page[size]': queryParams['page[size]'] }),
    },
    pathParams,
  };
  yield put(clearListAction({ resourceName: payload!.resourceName as string }));
  yield call(payload!.filteringSaga, apiRequestParamsByName);
}
