import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { DataPresenter, formatBalance, Header, PageSection } from 'sb-ui-components';
import { SeizureProtection } from '../models/account';

export interface AccountSeizureInformationProps {
  seizureProtection: SeizureProtection;
}

export const AccountSeizureInformation: FunctionComponent<AccountSeizureInformationProps> = props => {
  const { seizureProtection } = props;
  const { formatMessage } = useIntl();

  return (
    <PageSection>
      <Header>
        <h2>{formatMessage({ id: 'title.seizureInformation' })}</h2>
      </Header>
      <DataPresenter
        dataCy="seizure_information_box__account"
        data={[
          {
            label: formatMessage({ id: 'label.seizedAmount' }),
            value: formatBalance(seizureProtection?.current_blocked_amount),
          },
          {
            label: formatMessage({ id: 'label.protectedAmount' }),
            value: formatBalance(seizureProtection?.protected_amount),
          },
        ]}
      />
    </PageSection>
  );
};
