import { SimpleAction } from 'src/types';

export const BOOT_INITIAL_LOAD_ACTION = 'boot/INITIAL_LOAD';
export const BOOT_FINISHED_ACTION = 'boot/INITIAL_LOAD_FINISHED';
export const INITIAL_PAGE_ENTER_FINISHED = 'boot/INITIAL_PAGE_ENTER_FINISHED';

export type BootAction = SimpleAction<typeof BOOT_INITIAL_LOAD_ACTION>;

export function boot(): BootAction {
  return {
    type: BOOT_INITIAL_LOAD_ACTION,
  };
}

export type BootFinishedAction = SimpleAction<typeof BOOT_FINISHED_ACTION>;

export function bootFinished(): BootFinishedAction {
  return {
    type: BOOT_FINISHED_ACTION,
  };
}

export type InitialPageEnterDoneAction = SimpleAction<typeof INITIAL_PAGE_ENTER_FINISHED>;

export function initialPageEnterDone(): InitialPageEnterDoneAction {
  return {
    type: INITIAL_PAGE_ENTER_FINISHED,
  };
}
