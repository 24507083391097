import { FunctionComponent } from 'react';
import { PageWrapper } from 'src/components/page-wrapper';
import styles from './simple-loading-page.module.scss';

export interface SimpleLoadingPageProps {
  title: string;
}

export const SimpleLoadingPage: FunctionComponent<SimpleLoadingPageProps> = props => {
  const { title } = props;

  return (
    <PageWrapper title={title} className={styles.base}>
      <h1>{title}</h1>
    </PageWrapper>
  );
};
