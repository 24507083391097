import { Saga } from 'redux-saga';
import { fork } from 'redux-saga/effects';
import { listenToRouteHooks } from '../router/sagas/router';
import { logoutWhileIdling } from './automatic-logout';
import { boot } from './boot';
import { listenToFilterAndUidSearchAction, listenToSkipPageAction } from './resource-requests';

export default function* root(childSagas?: Saga[]) {
  if (childSagas) {
    for (const key in childSagas) {
      if (childSagas[key]) {
        yield fork(childSagas[key]);
      }
    }
  }

  // Automatic logout is reset on every action, but it triggers also the "logout action"
  // Putting the logoutWhileIdling AFTER logoutFlow ensures, that the "logout action"
  // will be consumed by the logoutFlow
  yield fork(logoutWhileIdling);
  yield fork(boot);
  yield fork(listenToRouteHooks);
  yield fork(listenToSkipPageAction);
  yield fork(listenToFilterAndUidSearchAction);
}
