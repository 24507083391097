import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState, RouterHookParams } from '../../types';
import Router from '../components/router';
import { onEnterRouteAction } from '../redux/router-actions';

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onEnterRoute: (params: RouterHookParams) => {
      dispatch(onEnterRouteAction(params));
    },
  };
};

export const mapStateToProps = (state: AppState) => {
  return {
    loggedIn: state.auth.loggedIn,
    bootFinished: state.boot.done,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
