import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { ApiRequestParams } from '../../../lib/api';
import { resourceIndexAndStoreToState } from '../../../sagas/resource-requests';
import { accountReservationResourceConfig } from '../models/account-reservation';

export function* indexAccountReservationList(params: ApiRequestParams): SagaIterator<void> {
  yield call(resourceIndexAndStoreToState, {
    resourceConfig: accountReservationResourceConfig,
    params: {
      ...params,
      queryParams: { 'filter[status]': 'OPEN', ...params.queryParams },
    },
  });
}
