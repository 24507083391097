import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import {
  Badge,
  Box,
  ButtonLink,
  formatBalance,
  formatIban,
  InlineText,
  ItemGroup,
  SvgIcon,
  TextWithCopyButton,
} from 'sb-ui-components';
import { createPathByRouteName } from 'src/lib/route-config-helper';
import { Account, accountBlockStateColorMap, accountStatusColorMap } from '../../models/account';
import styles from './account-list-item.module.scss';

interface AccountListItemProps {
  account: Account | undefined;
  displayActions?: boolean;
}

export const AccountListItem: FunctionComponent<AccountListItemProps> = props => {
  const { formatMessage } = useIntl();
  const { account, displayActions = true } = props;

  if (!account) return null;

  const { id, attributes } = account;
  const isActive = attributes.status === 'ACTIVE';
  const isAnyBlock = isActive && attributes.block_state !== 'NO_BLOCK';
  const isTransferEnabled = isActive && !['BLOCK', 'DEBIT_BLOCK'].includes(attributes.block_state);

  return (
    <Box key={id} dataCy="accountItem">
      <div className={styles.contentWithIcon}>
        <SvgIcon type="BuildingColumnsLight" className={styles.icon} />
        <div>
          <div className={styles.content}>
            <h3 className={styles.title}>
              {formatMessage({ id: `accountType.${attributes.account_type}`, defaultMessage: attributes.account_type })}
            </h3>
            <InlineText type="em" nowrap size="medium">
              {formatBalance(attributes.balance)}
            </InlineText>
          </div>
          {attributes.iban && (
            <div className={styles.iban}>
              <TextWithCopyButton
                textToCopy={attributes.iban}
                contentToRender={
                  <InlineText color="soft" size="small">
                    {formatIban(attributes.iban)}
                  </InlineText>
                }
              />
            </div>
          )}
        </div>
      </div>
      {displayActions && (
        <div className={styles.content}>
          <div>
            <ItemGroup className={styles.actions}>
              {isTransferEnabled && (
                <ButtonLink
                  href={createPathByRouteName('sepaCreditTransfer.create', { pathParams: { id } })}
                  dataCy="cta__create_transfer"
                >
                  {formatMessage({ id: 'button.transfer' })}
                </ButtonLink>
              )}
              <ButtonLink appearance="stroked" href={createPathByRouteName('account.show', { pathParams: { id } })}>
                {formatMessage({ id: 'button.details' })}
              </ButtonLink>
            </ItemGroup>
          </div>
          <div>
            {!isActive && (
              <div>
                <Badge
                  message={formatMessage({
                    id: `status.${attributes.status}`,
                    defaultMessage: attributes.status,
                  })}
                  size="small"
                  color={accountStatusColorMap[attributes.status]}
                />
              </div>
            )}
            {/* If the account is active and blocked, display the account block state badge */}
            {isAnyBlock && (
              <div>
                <Badge
                  message={formatMessage({
                    id: `accountBlockState.${attributes.block_state}`,
                    defaultMessage: attributes.block_state,
                  })}
                  size="small"
                  color={accountBlockStateColorMap[attributes.block_state]}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Box>
  );
};
