import { PayloadAction } from 'src/types';

export const SUBMIT_REQUEST_SUPPORT_TICKET = 'request-support/SUBMIT';

export interface SubmitRequestSupportTicketPayload {
  subject: string;
  comment: string;
}

export type SubmitRequestSupportTicketAction = PayloadAction<
  typeof SUBMIT_REQUEST_SUPPORT_TICKET,
  SubmitRequestSupportTicketPayload
>;

export function submitRequestSupportTicket(
  payload: SubmitRequestSupportTicketPayload,
): SubmitRequestSupportTicketAction {
  return {
    type: SUBMIT_REQUEST_SUPPORT_TICKET,
    payload,
  };
}
